import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

import './ConfirmModal.css'

export interface ConfirmModalProps {
  show: boolean
  onHide: () => void
  onConfirm: () => void
  title: string
  text: string
  confirmButtonText?: string
  cancelButtonText?: string
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark'
}

const ConfirmModal = ({
  show,
  onHide,
  onConfirm,
  title,
  text,
  confirmButtonText,
  cancelButtonText = 'Cancel',
  variant,
}: ConfirmModalProps) => {
  confirmButtonText = confirmButtonText || 'Bekräfta'
  variant = variant || 'primary'
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-modal-bg" // Custom class for dark styling
    >
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="secondary" onClick={onHide}>
          {cancelButtonText}
        </Button>
        <Button className={'ms-4'} variant={variant} onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
