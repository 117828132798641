import AboutPage from './AboutPage'

export const HomeLoggedOut = () => {
  return (
    <div>
      <AboutPage />
    </div>
  )
}
export default HomeLoggedOut
