import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { convertToDate, formatDateTo_yyyyMMdd } from '../utils/converters'
import CalenderComponent from './CalenderComponent'
export interface AddEventToCalendarProps {
  eventTitle: string
  description?: string
  endDate: string | null
  showAddEventToCalendarButton: boolean
  handleCloseAddTaskToCal: () => void
}
const AddEventToCalendar = ({
  eventTitle,
  description,
  endDate,
  showAddEventToCalendarButton,
  handleCloseAddTaskToCal,
}: AddEventToCalendarProps) => {
  const { t } = useTranslation('AddEventToCalendar')

  const [reminderDate, setReminderDate] = useState<Date | null>(
    endDate ? convertToDate(endDate) : null,
  )

  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  return (
    <>
      <Modal
        show={showAddEventToCalendarButton}
        onHide={handleCloseAddTaskToCal}
        size="lg"
        className={'custom-modal-bg'}
      >
        <Modal.Header
          closeButton
          className="custom-modal-header-bg custom-modal-color"
        >
          <Modal.Title>{t('title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body-bg custom-modal-color">
          <>
            <div className="container mt-4">
              <Card>
                <Card.Body>
                  <Card.Title>{eventTitle}</Card.Title>
                  {endDate && (
                    <Card.Subtitle className="mb-2 text-muted">
                      {t('endDate', { endDate: endDate })}
                    </Card.Subtitle>
                  )}
                  <Card.Text>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: description ? description : '',
                      }}
                    />
                  </Card.Text>
                  <hr></hr>
                  <div style={{ position: 'relative', zIndex: 2 }}>
                    <div className={'fw-bold mx-1'}>
                      {t('reminderDateLabel')}
                    </div>
                    <div className={'mx-1 mt-1'}>
                      <CalenderComponent
                        inputPlaceholder={t('reminderDatePlaceholder')}
                        selectedDate={reminderDate}
                        onChange={setReminderDate}
                      />
                    </div>
                  </div>

                  {reminderDate && (
                    <div
                      style={{ position: 'relative', zIndex: 1, marginTop: 15 }}
                    >
                      <AddToCalendarButton
                        label={t('addEventToCalendarButton')}
                        lightMode="light"
                        name={eventTitle}
                        description={description}
                        startDate={formatDateTo_yyyyMMdd(reminderDate)}
                        startTime="10:00"
                        endTime="11:00"
                        // recurrence="weekly"
                        options={[
                          'Apple',
                          'Google',
                          'Outlook.com',
                          'Microsoft365',
                          'MicrosoftTeams',
                          'iCal',
                        ]}
                        timeZone={getTimeZone()}
                        language="en"
                      ></AddToCalendarButton>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer-bg custom-modal-color">
          <Button variant="secondary" onClick={handleCloseAddTaskToCal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddEventToCalendar
