import { useNavigate } from 'react-router-dom'
import {
  TaskDataType,
  TypeOfRelation,
  UserTask,
  UserTaskStatus,
} from '../../components/model/model'
import { convertToDate } from '../../utils/converters'
import './MyUserTaskInfoBox.css'
import { getDifferenceInDays } from '../../utils/timeAndDateUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faScaleUnbalancedFlip,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

export interface MyUserTaskInfoBoxProps {
  userTask: UserTask
  link?: string
}
const MyUserTaskInfoBox = ({ userTask, link }: MyUserTaskInfoBoxProps) => {
  const { t } = useTranslation('MyUserTaskInfoBox')
  const navigate = useNavigate()

  const clickable = link !== undefined ? 'cursorPointer' : ''

  const goToLink = () => {
    if (link) {
      navigate(link)
    }
  }

  const isDone = userTask.status === UserTaskStatus.Done

  const getDaysStatusText = () => {
    if (isDone) {
      return '' // 'Bra jobbat!'  ?
    }
    if (!userTask.endDate) {
      return '' //Inget slutdatum ?
    }
    const diffInDays = getDifferenceInDays(
      convertToDate(userTask.endDate),
      new Date(),
    )

    if (diffInDays === 0) {
      return t('taskDoneToday')
    } else if (diffInDays < 0) {
      return t('daysLate', { diffInDays: Math.abs(diffInDays) })
    } else {
      return t('daysLeft', { diffInDays: diffInDays })
    }
  }

  const getStatus = () => {
    return userTask.status.toLowerCase()
  }

  const isMeasurable = userTask.dataType !== TaskDataType.OneTimeTaskDataType

  const isSharedTask = userTask.typeOfRelation === TypeOfRelation.Shared

  const hasCategory = () => {
    return userTask.taskCategory !== 'none'
  }

  return (
    <>
      <div className={`goachy-info-item ${clickable}`} onClick={goToLink}>
        <span className="goachy-info-item_link">
          <div className={`goachy-info-item_bg bg-color-${getStatus()}`}></div>
          <div>
            {!isMeasurable && (
              <FontAwesomeIcon
                icon={faThumbtack}
                size={'lg'}
                className={'mb-2'}
              />
            )}

            {isMeasurable && (
              <FontAwesomeIcon
                icon={faScaleUnbalancedFlip}
                size={'lg'}
                className={'mb-2'}
              />
            )}
          </div>
          <div className={'items-left-and-right'}>
            <div className="goachy-info-item_title">{userTask.taskName}</div>
            <div></div>
          </div>
          {hasCategory() && (
            <div className={'mt-2'}>
              {t('category')}: {userTask.taskCategory}
            </div>
          )}
          {userTask.endDate && (
            <div>
              {t('endDate')}: {userTask.endDate}
            </div>
          )}
          <div>{getDaysStatusText()}</div>
          {isSharedTask && <div>{t('sharedTask')}</div>}
          {/* {!isOwner && (
            <>
              <div>
                {t('to')}: {userTask.assignedTo}
              </div>
              <div>
                {t('from')}: {userTask.assignedBy}
              </div>
            </>
          )} */}
        </span>
      </div>
    </>
  )
}

export default MyUserTaskInfoBox
