import {
  faDiceD6,
  faHome,
  faInfoCircle,
  faMedal,
  faScrewdriverWrench,
  faUser,
  faUserCheck,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useState } from 'react'
import { GoachyRole } from '../Roles'
import { updateTheme } from '../Themes'
import { useUserCredentials } from '../context/UserCredentialsContext'
import useToggleMenu from '../hooks/useToggleMenuHook'
import { hasRole, isLoggedIn } from '../utils/UserDetailsHelper'
import { getBootstrapVariantForMenu } from '../utils/themeHelpers'
import DarkModeLightModeSwitch from './DarkModeLightModeSwitch'
import LogoComponent from './LogoComponent'

const NavigationBar = () => {
  const { t } = useTranslation('NavigationBar')

  const { userCredentialsInContext, updateUserCredentialsContext } =
    useUserCredentials()
  const organizationName =
    userCredentialsInContext?.userInfo?.organizationName ?? ''

  const [isOpen, toggleMenu] = useToggleMenu()
  const [currentTheme, setCurrentMode] = useState('light')

  const navigator = useNavigate()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const organizationUserPath = `/admin/organizations/${userCredentialsInContext?.userInfo?.organizationId}/users`

  const loginPath = `/login/user/${Date.now()}`

  const getIconSize = () => {
    return isMobile ? 'fa-2x' : 'fa-lg'
  }

  const getIconTextFontSize = () => {
    return isMobile ? '18px' : '17px'
  }

  const switchTheme = () => {
    const nextTheme = currentTheme === 'light' ? 'dark' : 'light'
    setCurrentMode(nextTheme)

    if (!isLoggedIn(userCredentialsInContext)) {
      updateTheme('goachy', nextTheme)
      return
    }

    updateUserCredentialsContext({
      isSignedIn: userCredentialsInContext!.isSignedIn,
      userInfo: { ...userCredentialsInContext!.userInfo!, theme: nextTheme },
    })
  }

  const showPublicTasksToggle = false

  return (
    <>
      <Navbar
        variant={getBootstrapVariantForMenu(organizationName, currentTheme)}
        expand="lg"
        fixed="top"
        expanded={isMobile ? isOpen : undefined}
      >
        <Container>
          <Navbar.Brand
            className="cursorPointer"
            onClick={() => navigator('/')}
          >
            <LogoComponent organizationName={organizationName} />
          </Navbar.Brand>

          {isMobile && userCredentialsInContext?.isSignedIn && (
            <>
              <FontAwesomeIcon
                icon={faMedal}
                onClick={() => navigator('/goals/my')}
                className={getIconSize()}
              />
              <FontAwesomeIcon
                icon={faUserCheck}
                onClick={() => navigator('/usertasks/list')}
                className={getIconSize()}
              />
            </>
          )}

          {/* Public tasks */}

          {isMobile && showPublicTasksToggle && (
            <FontAwesomeIcon
              icon={faDiceD6}
              onClick={() => navigator('/public/tasks')}
              className={getIconSize()}
            />
          )}

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={toggleMenu}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            {isMobile && <div className={'mt-3'} />}
            <Nav className="me-auto">
              {userCredentialsInContext?.isSignedIn && (
                <>
                  {/* Home */}
                  <LinkContainer to="/">
                    <Nav.Link onClick={toggleMenu}>
                      <FontAwesomeIcon
                        icon={faHome}
                        className={getIconSize()}
                      />
                      <span
                        style={{ fontSize: getIconTextFontSize() }}
                        className={'m-2'}
                      >
                        {t('home')}
                      </span>
                    </Nav.Link>
                  </LinkContainer>

                  {/* My goals */}
                  <LinkContainer to="/goals/my">
                    <Nav.Link onClick={toggleMenu}>
                      <FontAwesomeIcon
                        icon={faMedal}
                        className={getIconSize()}
                      />
                      <span
                        style={{ fontSize: getIconTextFontSize() }}
                        className={'m-2'}
                      >
                        {t('myGoals')}
                      </span>
                    </Nav.Link>
                  </LinkContainer>

                  {/* My tasks */}
                  <LinkContainer to="/usertasks/list">
                    <Nav.Link onClick={toggleMenu}>
                      <FontAwesomeIcon
                        icon={faUserCheck}
                        className={getIconSize()}
                      />
                      <span
                        style={{ fontSize: getIconTextFontSize() }}
                        className={'m-2'}
                      >
                        {t('myTasks')}
                      </span>
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}

              {/* Public tasks */}
              {showPublicTasksToggle && (
                <LinkContainer to="/public/tasks">
                  <Nav.Link onClick={toggleMenu}>
                    <FontAwesomeIcon
                      icon={faDiceD6}
                      className={getIconSize()}
                    />
                    <span
                      style={{ fontSize: getIconTextFontSize() }}
                      className={'m-2'}
                    >
                      {t('publicTasks')}
                    </span>
                  </Nav.Link>
                </LinkContainer>
              )}

              {/* Admin */}

              {hasRole(GoachyRole.ORG_ADMIN, userCredentialsInContext!) && (
                <>
                  <LinkContainer to={organizationUserPath}>
                    <Nav.Link onClick={toggleMenu}>
                      <FontAwesomeIcon
                        icon={faUsers}
                        className={getIconSize()}
                      />{' '}
                      <span
                        style={{ fontSize: getIconTextFontSize() }}
                        className={'m-2'}
                      >
                        {t('users')}
                      </span>
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}

              {hasRole(GoachyRole.SUPER_ADMIN, userCredentialsInContext!) && (
                <>
                  <LinkContainer to="/superadmin">
                    <Nav.Link onClick={toggleMenu}>
                      <FontAwesomeIcon
                        icon={faScrewdriverWrench}
                        className={getIconSize()}
                      />{' '}
                      <span
                        style={{ fontSize: getIconTextFontSize() }}
                        className={'m-2'}
                      >
                        {t('admin')}
                      </span>
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}

              <LinkContainer to="/about">
                <Nav.Link onClick={toggleMenu}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className={getIconSize()}
                  />{' '}
                  <span
                    style={{ fontSize: getIconTextFontSize() }}
                    className={'m-2'}
                  >
                    {t('about')}
                  </span>
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              {!userCredentialsInContext?.isSignedIn && (
                <LinkContainer to={loginPath}>
                  <Nav.Link onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faUser} className={getIconSize()} />{' '}
                    <span
                      style={{ fontSize: getIconTextFontSize() }}
                      className={'m-2'}
                    >
                      {t('logIn')}
                    </span>
                  </Nav.Link>
                </LinkContainer>
              )}

              {userCredentialsInContext?.isSignedIn && (
                <LinkContainer to="/logout">
                  <Nav.Link onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faUser} className={getIconSize()} />
                    <span
                      style={{ fontSize: getIconTextFontSize() }}
                      className={'m-2'}
                    >
                      {t('profile')}
                    </span>
                  </Nav.Link>
                </LinkContainer>
              )}

              <DarkModeLightModeSwitch
                currentTheme={
                  userCredentialsInContext?.userInfo?.theme ?? currentTheme
                }
                switchThemeCb={switchTheme}
              />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
export default NavigationBar
