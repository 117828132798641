import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const PublicTasksInfo = () => {
  const { t } = useTranslation('PublicTasksInfo')
  return (
    <div>
      <Container className={'mb-2'}>
        <Row className={'justify-content-center'}>
          <Col md={12}>
            <>
              <p>{t('generalText')}</p>
              <h5 className={'mt-4 mb-3'}>{t('loggedInTitle')}</h5>
              <p>{t('loggedInDescription')}</p>

              <h5 className={'mt-4 mb-3'}>{t('notLoggedInTitle')}</h5>
              <p>{t('notLoggedInDescription')}</p>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PublicTasksInfo
