import { ReactNode, createContext, useContext, useState } from 'react'

interface SelectedCategoryContextType {
  selectedCategoryInContext: string | null
  updateSelectedCategoryContext: (category: string | null) => void
}

/**
 * This context is used to store the selected category in the dropdown menu. 
 * For example when navigating away from task list into a task details page, and then back to the task list, now the selected category is remembered
 * and can be used to filter the tasks by category and populate the dromdown menu with the selected category.
 */
const SelectedCategoryContext = createContext<
  SelectedCategoryContextType | undefined
>(undefined)

// Create a custom hook to use the context
export const useSelectedCategory = (): SelectedCategoryContextType => {
  const context = useContext(SelectedCategoryContext)
  if (context === undefined) {
    throw new Error(
      'useSelectedCategoryContext must be used within a TaskProvider',
    )
  }
  return context
}

interface SelectedCategoryProviderProps {
  children: ReactNode
}

export const SelectedCategoryProvider = ({
  children,
}: SelectedCategoryProviderProps) => {
  const [category, setCategory] = useState<string | null>(null)

  const updateCategoryContext = (category: string | null) => {
    setCategory(category)
  }

  return (
    <SelectedCategoryContext.Provider
      value={{
        selectedCategoryInContext: category,
        updateSelectedCategoryContext: updateCategoryContext,
      }}
    >
      {children}
    </SelectedCategoryContext.Provider>
  )
}
