import { Col, Container, Row } from 'react-bootstrap'

interface StarRatingProps {
  points: number
  maxPoints: number
  removeNumberOfLightningStars?: number
}

const StarRating = ({
  points,
  maxPoints,
  removeNumberOfLightningStars,
}: StarRatingProps) => {
  const percentageCorrect = (points / maxPoints) * 100

  const totalStars = 5
  const filledStars =
    Math.round((percentageCorrect / 100) * totalStars) -
    (removeNumberOfLightningStars || 0)

  return (
    <Container>
      <Row>
        <Col>
          {[...Array(totalStars)].map((_, index) => (
            <i
              key={index}
              className={`star ${index < filledStars ? 'filled' : ''}`}
              style={{ color: index < filledStars ? 'gold' : 'grey' }}
            >
              &#9733;
            </i>
          ))}
        </Col>
      </Row>
    </Container>
  )
}

export default StarRating
