// src/components/SharedLayout.js or SharedLayout.tsx
import { Outlet } from 'react-router-dom'
import MainContainer from './MainContainer'
import NavigationBar from './NavigationBar'

const SharedLayout = () => {
  return (
    <>
      <NavigationBar />

      <MainContainer>
        <Outlet />
      </MainContainer>
    </>
  )
}

export default SharedLayout
