import { useNavigate } from 'react-router-dom'
import './GoachyInfoItem.css'

export interface GoachyInfoItemProps {
  title: string
  text: string
  link?: string
}
const GoachyInfoItem = ({ title, text, link }: GoachyInfoItemProps) => {
  const navigate = useNavigate()

  const clickable = link !== undefined ? 'cursorPointer' : ''

  const goToLink = () => {
    if (link) {
      navigate(link)
    }
  }
  return (
    <>
      <div className={`goachy-info-item ${clickable}`} onClick={goToLink}>
        <span className="goachy-info-item_link">
          <div className="goachy-info-item_bg"></div>

          <div className="goachy-info-item_title mt-4">{title}</div>

          <div className="goachy-info-item_date-box">
            <span className="goachy-info-item_date">{text}</span>
          </div>
        </span>
      </div>
    </>
  )
}

export default GoachyInfoItem
