
export const getBootstrapVariantForMenu = (
  company: string,
  currentTheme: string,
): string => {
  //"dark" gives white buttons in menu, "light" gives black buttons in menu
  if (currentTheme === 'dark') {
    return 'dark'
  } else if (currentTheme === 'light') {
    return 'dark'
  }

  switch (company.toLowerCase()) {
    case 'studybuddy':
      return 'light'
    case 'admentum':
      return 'light'
    default:
      return 'dark'
  }
}
