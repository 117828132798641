import { UserCredentials } from '../App'
import { GoachyRole } from '../Roles'

export const getUserEmail = (userCredentials: UserCredentials): string => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.email
  ) {
    return userCredentials.userInfo.email
  }
  throw new Error(
    `User email is not available in user credentials=${userCredentials}`,
  )
}

export const getUserId = (userCredentials: UserCredentials): string => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.userId
  ) {
    return userCredentials.userInfo.userId
  }
  throw new Error(
    `UserId is not available in user credentials=${userCredentials}`,
  )
}

export const isLoggedIn = (
  userCredentials: UserCredentials | null,
): boolean => {
  if (userCredentials && userCredentials.isSignedIn) {
    return userCredentials.isSignedIn
  }
  return false
}

export const getUserOrganizationId = (
  userCredentials: UserCredentials,
): string => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.organizationId
  ) {
    return userCredentials.userInfo.organizationId
  }
  throw new Error(
    `User organization is not available in user credentials=${userCredentials}`,
  )
}

export const hasRole = (
  role: GoachyRole,
  userCredentials: UserCredentials,
): boolean => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.roles
  ) {
    return userCredentials.userInfo.roles.includes(role)
  }
  return false
}

export const hasRoleToCreateTasks = (
  userCredentials: UserCredentials,
): boolean => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.roles
  ) {
    return (
      userCredentials.userInfo.roles.includes(GoachyRole.CREATE_TASK_ROLE) ||
      userCredentials.userInfo.roles.includes(
        GoachyRole.ORG_ADMIN ||
          userCredentials.userInfo.roles.includes(GoachyRole.SUPER_ADMIN),
      )
    )
  }
  return false
}

export const hasRoleToCreateGoals = (
  userCredentials: UserCredentials,
): boolean => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.roles
  ) {
    return (
      userCredentials.userInfo.roles.includes(GoachyRole.CREATE_GOAL_ROLE) ||
      userCredentials.userInfo.roles.includes(
        GoachyRole.ORG_ADMIN ||
          userCredentials.userInfo.roles.includes(GoachyRole.SUPER_ADMIN),
      )
    )
  }
  return false
}

export const hasRoleToDelegateTask = (
  userCredentials: UserCredentials,
): boolean => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.roles
  ) {
    return (
      userCredentials.userInfo.roles.includes(GoachyRole.ASSIGN_TASK_ROLE) ||
      userCredentials.userInfo.roles.includes(
        GoachyRole.ORG_ADMIN ||
          userCredentials.userInfo.roles.includes(GoachyRole.SUPER_ADMIN),
      )
    )
  }
  return false
}

export const hasRoleOrganizationRoleOrHigher = (
  userCredentials: UserCredentials,
): boolean => {
  if (
    userCredentials &&
    userCredentials.userInfo &&
    userCredentials.userInfo.roles
  ) {
    return (
      userCredentials.userInfo.roles.includes(GoachyRole.ORG_ADMIN) ||
      userCredentials.userInfo.roles.includes(GoachyRole.SUPER_ADMIN)
    )
  }
  return false
}

//TODO: add username to user credentials
// export const getUsername = (userCredentials: UserCredentials): string => {
//     if (
//       userCredentials &&
//       userCredentials.userInfo &&
//       userCredentials.userInfo.username
//     ) {
//       return userCredentials.userInfo.username
//     }
//     throw new Error(
//       `User name is not available in user credentials=${userCredentials}`,
//     )
//   }
