import LeftButtonTitleRightButton from './LeftButtonTitleRightButton'
import ProgressSpinner from './ProgressSpinner'
import StyledContainer from './StyledContainer'

export interface ProgressBarHeaderProps {
  title: string
}

const ProgressBarHeader = ({ title }: ProgressBarHeaderProps) => {
  return (
    <>
      <div className={'mt-3'} />
      <div className={'mx-3'}>
        <StyledContainer>
          <LeftButtonTitleRightButton title={title} />
          <div style={{ height: 55 }}></div>
        </StyledContainer>

        <div style={{ flexGrow: 1 }}>
          <ProgressSpinner />
        </div>
      </div>
    </>
  )
}
export default ProgressBarHeader
