import { faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Col, Dropdown, Form, Row, SplitButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useTextToSpeechService } from '../service/ServicesHooks'
import { TextToSpeechRequest } from '../service/TextToSpeechService'
import StyledContainer from './StyledContainer'
import pollyLanguages from './TextToSpeechLanguages.json'

export interface TextToSpeechComponentProps {
  textToSpeak: string
  showText?: boolean
  label?: string
  buttonWidth?: string
}

const supportedLanguages = [
  'Swedish',
  'English, British',
  'English, US',
  'Spanish',
  'German',
  'French',
]

const TextToSpeechComponent = ({
  textToSpeak,
  showText,
  label,
  buttonWidth = '200px',
}: TextToSpeechComponentProps) => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  let initialLanguage = 'Swedish'
  let initialVoice = 'Astrid'
  switch (currentLanguage) {
    case 'sv':
      initialLanguage = 'Swedish'
      initialVoice = 'Astrid'
      break
    case 'en':
      initialLanguage = 'English, British'
      initialVoice = 'Kendra'
      break
  }

  const textToSpeechService = useTextToSpeechService()
  const [audioData, setAudioData] = useState<{
    text: string
    voiceId: string
    audioUrl: string
  } | null>(null)

  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage)
  const [selectedVoice, setSelectedVoice] = useState(initialVoice)

  // useEffect(() => {
  //   console.log('textToSpeech useEffect')
  //   setSelectedLanguage(selectedLanguage)
  // }, [])

  const playText = async () => {
    try {
      if (
        audioData &&
        audioData.text === textToSpeak &&
        audioData.voiceId === selectedVoice
      ) {
        const audio = new Audio(audioData.audioUrl)
        audio.play()
        return
      }

      const request: TextToSpeechRequest = {
        text: textToSpeak,
        voiceId: selectedVoice,
      }
      const response = await textToSpeechService.textToSpeech(request)

      if (response.status === 'FAILURE') {
        console.log('Failed to fetch audio')
        return
      }

      setAudioData({
        text: textToSpeak,
        voiceId: selectedVoice,
        audioUrl: response.audioUrl!,
      })

      const audio = new Audio(response.audioUrl)
      audio.play()
    } catch (err) {
      console.log(err)
    }
  }

  const getSupportedVoiceItems = () => {
    const items = pollyLanguages.Voices.filter((item) => {
      return supportedLanguages.includes(item.Language)
    })
    return items
  }

  return (
    <div className={'mb-4'}>
      <StyledContainer>
        <Row>
          <Col xs={12}>
            {label && (
              <div>
                <Form.Label className="text-mid-size">{label}</Form.Label>
              </div>
            )}
            {showText && <div>{textToSpeak}</div>}
            <SplitButton
              id="dropdown-split-variants-Primary"
              variant="primary"
              onClick={playText}
              style={{ width: buttonWidth }}
              title={
                <div style={{ textAlign: 'left' }}>
                  <FontAwesomeIcon
                    icon={faVolumeHigh}
                    size="lg"
                    className={'cursorPointer'}
                  />{' '}
                  {selectedLanguage}
                </div>
              }
              onSelect={(eventKey: string | null) => {
                const selectedVoiceItem =
                  getSupportedVoiceItems()[parseInt(eventKey || '0', 10)]
                if (selectedVoiceItem) {
                  setSelectedLanguage(selectedVoiceItem.Language)
                  setSelectedVoice(selectedVoiceItem.Voices[0])
                }
              }}
            >
              {getSupportedVoiceItems().map((voiceItem, index) => (
                <Dropdown.Item key={index} eventKey={index.toString()}>
                  {voiceItem.Language}
                </Dropdown.Item>
              ))}
            </SplitButton>
          </Col>
        </Row>
      </StyledContainer>
    </div>
  )
}

export default TextToSpeechComponent
