import {
  Goal,
  GoalTask,
  GoalType,
  OrganizationId,
  Task,
  UserId,
  UserTask,
} from '../components/model/model'
import { getEnvApiUrl } from '../envConfig'
import { AuthService } from './AuthService'

export type CreateGoalRequest = {
  name: string
  description: string
  author: UserId
  organizationId: OrganizationId
  goalType: GoalType
  targetTaskCountPerWeek?: number
  targetResult?: number
  category?: string
  endDate?: string
}

export type CreateGoalResponse = {
  goalId?: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type UpdateGoalRequest = {
  goalId: string
  name?: string
  description?: string
  targetResult?: number
  targetTaskCountPerWeek?: number
  goalType?: GoalType
  category?: string
  endDate?: string
}

export type UpdateGoalResponse = {
  goalId?: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type GetGoalsByOrganizationIdRequest = {
  organizationId: OrganizationId
}
export type GetGoalsByOrganizationIdResponse = {
  status: 'SUCCESS' | 'FAILURE' | 'NOT_FOUND'
  goals?: Goal[]
  message?: string
}

export type AddTaskToGoalRequest = {
  goalId: string
  taskId: string
}

export type AddTaskToGoalResponse = {
  taskId?: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type GetTasksByGoalIdRequest = {
  goalId: string
}

export type GetTasksByGoalIdResponse = {
  status: 'SUCCESS' | 'FAILURE' | 'NOT_FOUND'
  tasks?: Task[]
  message?: string
}
export type GetGoalsByUserIdRequest = {
  userId: UserId
}

export type GetGoalsByUserIdResponse = {
  status: 'SUCCESS' | 'FAILURE' | 'NOT_FOUND'
  goals?: Goal[]
  message?: string
}

export type GetUserTasksByGoalIdRequest = {
  goalId: string
  userId: string
}

export type GetUserTasksByGoalIdResponse = {
  status: 'SUCCESS' | 'FAILURE' | 'NOT_FOUND'
  userTasks?: UserTask[]
  message?: string
}

export type RemoveTaskFromGoalRequest = {
  goalId: string
  taskId: string
}

export type RemoveTaskFromGoalResponse = {
  taskId?: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type DeleteGoalRequest = {
  goalId: string
}

export type DeleteGoalResponse = {
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

const goachyApiUrl = getEnvApiUrl()

export class GoalService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  public async addGoal(
    createGoalRequest: CreateGoalRequest,
  ): Promise<CreateGoalResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      //Add goal
      const goalResponse = await fetch(`${goachyApiUrl}goals`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(createGoalRequest),
      })
      const createGoalResponse = (await goalResponse.json()) as {
        statusCode: string
        id: string
      }

      if (goalResponse.status !== 200) {
        console.error('Failed to create goal, error:', createGoalResponse)
        return {
          status: 'FAILURE',
          message: `Failed to create goal, error: ${createGoalResponse}`,
        }
      }
      //Add user goal for author
      const goalId = createGoalResponse.id
      const userId = createGoalRequest.author

      const userGoalResponse = await fetch(
        `${goachyApiUrl}users/${userId}/usergoals`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
          body: JSON.stringify({ goalId: goalId, userId: userId }),
        },
      )

      if (userGoalResponse.status !== 200) {
        console.error('Failed to create user goal, error:', userGoalResponse)
        return {
          status: 'FAILURE',
          message: `Failed to create user goal, error: ${userGoalResponse}`,
        }
      }

      return Promise.resolve({
        status: 'SUCCESS',
        goalId: createGoalResponse.id,
      })
    } catch (error) {
      console.error('Failed to create goal, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to create goal, error: ${error}`,
      }
    }
  }

  public async updateGoal(
    updateGoalRequest: UpdateGoalRequest,
  ): Promise<UpdateGoalResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(
        `${goachyApiUrl}goals/${updateGoalRequest.goalId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
          body: JSON.stringify(updateGoalRequest),
        },
      )
      const updateGoalResponse = (await response.json()) as {
        statusCode: string
        id: string
      }

      console.log(`Successfully updated goal,`, updateGoalResponse)

      return Promise.resolve({
        status: 'SUCCESS',
        goalId: updateGoalResponse.id,
      })
    } catch (error) {
      console.error('Failed to create goal, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to create goal, error: ${error}`,
      }
    }
  }

  async getGoalsByOrganizationId(
    request: GetGoalsByOrganizationIdRequest,
  ): Promise<GetGoalsByOrganizationIdResponse> {
    const organizationId = request.organizationId

    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(
        `${goachyApiUrl}organizations/${organizationId}/goals`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      if (response.status === 404) {
        return {
          goals: [],
          status: 'NOT_FOUND',
        }
      }

      const goals = (await response.json()) as Goal[]

      return {
        goals: goals,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to get goals, error:', error)
      return {
        goals: [],
        status: 'FAILURE',
        message: 'Failed to get goals',
      }
    }
  }

  async getGoalsByUserId(
    request: GetGoalsByUserIdRequest,
  ): Promise<GetGoalsByUserIdResponse> {
    const userId = request.userId

    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const myGoalsResponse = await fetch(
        `${goachyApiUrl}users/${userId}/usergoals`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      if (myGoalsResponse.status === 404) {
        return {
          goals: [],
          status: 'NOT_FOUND',
        }
      }

      if (myGoalsResponse.status !== 200) {
        return {
          goals: [],
          status: 'FAILURE',
          message: 'Failed to get my goals',
        }
      }

      const goals = await myGoalsResponse.json()

      return {
        goals: goals,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to get goals, error:', error)
      return {
        goals: [],
        status: 'FAILURE',
        message: 'Failed to get goals',
      }
    }
  }

  async addTaskToGoal(
    request: AddTaskToGoalRequest,
  ): Promise<AddTaskToGoalResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(
        `${goachyApiUrl}goals/${request.goalId}/tasks`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
          body: JSON.stringify(request),
        },
      )
      const addTaskToGoalResponse = (await response.json()) as {
        statusCode: string
        id: string
      }

      return Promise.resolve({
        status: 'SUCCESS',
        taskId: addTaskToGoalResponse.id,
      })
    } catch (error) {
      console.error('Failed to add task to goal, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to add task to goal, error: ${error}`,
      }
    }
  }

  async getTasksByGoalId(
    request: GetTasksByGoalIdRequest,
  ): Promise<GetTasksByGoalIdResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const goalId = request.goalId

      console.log('Get tasks by goal id:', goalId)

      const response = await fetch(`${goachyApiUrl}goals/${goalId}/tasks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        return {
          tasks: [],
          status: 'NOT_FOUND',
        }
      }

      const goalTasks = (await response.json()) as GoalTask[]

      const tasks: Task[] = []

      for (const goalTask of goalTasks) {
        const taskId = goalTask.taskId
        const task = await fetch(`${goachyApiUrl}tasks/${taskId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        })
        tasks.push((await task.json()) as Task)
      }

      console.log('Got tasks:', tasks)

      return {
        tasks: tasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to get tasks, error:', error)
      return {
        tasks: [],
        status: 'FAILURE',
        message: 'Failed to get tasks',
      }
    }
  }

  async getUserTasksByUserIdAndGoalId(
    request: GetUserTasksByGoalIdRequest,
  ): Promise<GetUserTasksByGoalIdResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const goalId = request.goalId
      const userId = request.userId

      const response = await fetch(
        `${goachyApiUrl}users/${userId}/usergoals/${goalId}/usertasks`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      if (response.status === 404) {
        return {
          userTasks: [],
          status: 'NOT_FOUND',
        }
      }

      const userTasks = (await response.json()) as UserTask[]

      return {
        userTasks: userTasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to get user tasks, error:', error)
      return {
        userTasks: [],
        status: 'FAILURE',
        message: 'Failed to get user tasks',
      }
    }
  }

  async removeTaskFromGoal(
    request: RemoveTaskFromGoalRequest,
  ): Promise<RemoveTaskFromGoalResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(
        `${goachyApiUrl}goals/${request.goalId}/tasks/${request.taskId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )
      const removeTaskFromGoalResponse = (await response.json()) as {
        statusCode: string
        id: string
      }

      console.log(
        `Successfully removed task from goal,`,
        removeTaskFromGoalResponse,
      )

      return Promise.resolve({
        status: 'SUCCESS',
        taskId: removeTaskFromGoalResponse.id,
      })
    } catch (error) {
      console.error('Failed to remove task from goal, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to remove task from goal, error: ${error}`,
      }
    }
  }

  async deleteGoal({ goalId }: DeleteGoalRequest): Promise<DeleteGoalResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(`${goachyApiUrl}goals/${goalId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status !== 200) {
        console.error(
          `Failed to delete goal, goalId=${goalId}, status:`,
          response.status,
        )
        return {
          status: 'FAILURE',
          message: `Failed to delete goal, goalId=${goalId}, status: ${response.status}`,
        }
      }
      return {
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to delete goal, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to delete goal, error: ${error}`,
      }
    }
  }
}
