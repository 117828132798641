import React, { useEffect, useRef, useState } from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'
import StyledContainer from '../StyledContainer'
import { useUserCredentials } from '../../context/UserCredentialsContext'

const QRCodeGenerator: React.FC = () => {
  const [url, setUrl] = useState<string>('https://goachy.com')
  const [downloadUrl, setDownloadUrl] = useState<string>('')
  const [size, setSize] = useState<number>(290)
  const [fgColor, setFgColor] = useState<string>('#FFFFFF') // Default foreground color
  const [bgColor, setBgColor] = useState<string>('#000000') // Default background color
  const [imageSrc, setImageSrc] = useState<string>('') // Path to the logo image
  const [imageHeight, setImageHeight] = useState<number>(25) // Default image height
  const [imageWidth, setImageWidth] = useState<number>(120) // Default image width
  const [includeImage, setIncludeImage] = useState<boolean>(false) // State to manage image inclusion
  const [isValidImage, setIsValidImage] = useState<boolean>(true)

  const qrCodeRef = useRef(null)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const { userCredentialsInContext } = useUserCredentials()

  const subHeaderClass = isMobile ? 'main-small-subheader' : 'main-subheader'

  useEffect(() => {
    try {
      console.log('run useEffect')
      if (qrCodeRef.current) {
        const canvas = (qrCodeRef.current as HTMLElement).querySelector(
          'canvas',
        ) as HTMLCanvasElement
        console.log(canvas)
        if (canvas) {
          const url = canvas.toDataURL('image/png')
          setDownloadUrl(url)
          setIsValidImage(true)
        }
      }
    } catch (error) {
      console.log('error:', error)
      setIsValidImage(false)
    }
  }, [url, size, fgColor, bgColor, imageSrc, imageHeight, imageWidth])

  //   const handleGenerate = () => {
  //     const canvas = document.getElementById('qrCodeEl') as HTMLCanvasElement
  //     const pngUrl = canvas
  //       .toDataURL('image/png')
  //       .replace('image/png', 'image/octet-stream')
  //     setDownloadUrl(pngUrl)
  //   }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()
      reader.onload = function (e) {
        setImageSrc(e.target!.result as string)
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  const handleToggleIncludeImage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeImage(event.target.checked)
    if (!event.target.checked) {
      setImageSrc('') // Reset image source if checkbox is unchecked
    } else {
      setImageSrc('https://goachy.com/goachy-logo-trans.png') // Default image source
    }
  }

  return (
    <div className={'my-3'}>
      <StyledContainer>
        <div className={`${subHeaderClass} text-center my-2`}>
          <span>Goachy QR Generator</span>
        </div>
        <p className={'text-center'}>
          Welcome to generate your QR code image here. Log in to download the
          image you created. It is for free.
        </p>
      </StyledContainer>

      <InputGroup className="mb-3 mt-4">
        <Form.Control
          type="text"
          placeholder="Enter URL here..."
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button variant="primary">Generate QR Code</Button>
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text>QR Size</InputGroup.Text>
        <Form.Control
          type="number"
          placeholder="Enter size (px)"
          value={size}
          onChange={(e) => setSize(Number(e.target.value))}
          min={100} // Minimum size
          max={1000} // Maximum size
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Text>Foreground</InputGroup.Text>
        <Form.Control
          type="color"
          value={fgColor}
          onChange={(e) => setFgColor(e.target.value)}
        />
        <InputGroup.Text>Background</InputGroup.Text>
        <Form.Control
          type="color"
          value={bgColor}
          onChange={(e) => setBgColor(e.target.value)}
        />
      </InputGroup>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Include an image in the QR Code"
          checked={includeImage}
          onChange={handleToggleIncludeImage}
        />
      </Form.Group>
      {includeImage && (
        <>
          <p>
            Optionally add an image to the QR Code (will appear in the middle of
            the QR Code):
          </p>
          <InputGroup className="mb-3">
            {/* <Form.Label>Upload Image</Form.Label> */}
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text>Image Height</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Enter image height (px)"
              value={imageHeight}
              onChange={(e) => setImageHeight(Number(e.target.value))}
              min={10} // Minimum image height
              max={100} // Maximum image height
            />
            <InputGroup.Text>Image Width</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Enter image width (px)"
              value={imageWidth}
              onChange={(e) => setImageWidth(Number(e.target.value))}
              min={10} // Minimum image width
              max={100} // Maximum image width
            />
          </InputGroup>
        </>
      )}

      {url && (
        <Container className={'mt-4'}>
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={4}>
              <div className="text-center" ref={qrCodeRef}>
                <QRCodeCanvas
                  id="qrCodeEl"
                  value={url}
                  size={size}
                  level={'H'}
                  includeMargin={true}
                  fgColor={fgColor}
                  bgColor={bgColor}
                  imageSettings={{
                    src: imageSrc,
                    height: imageHeight,
                    width: imageWidth,
                    excavate: true,
                  }}
                />

                <br />

                {!isValidImage && (
                  <>
                    <Button
                      className={'my-4'}
                      disabled={true}
                      variant="success"
                    >
                      Download QR Code
                    </Button>
                    <p className={'text-muted'}>
                      Image is not valid. Please check the image settings above.
                    </p>
                  </>
                )}

                {userCredentialsInContext?.isSignedIn && isValidImage && (
                  <a href={downloadUrl} download="qrcode.png">
                    <Button className={'my-4'} variant="success">
                      Download QR Code
                    </Button>
                  </a>
                )}
                {!userCredentialsInContext?.isSignedIn && (
                  <>
                    <Button
                      className={'my-4'}
                      disabled={true}
                      variant="success"
                    >
                      Download QR Code
                    </Button>
                    <p className={'text-muted'}>
                      You need to be sign in to download the QR code you
                      created.
                    </p>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default QRCodeGenerator
