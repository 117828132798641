import { GoalType } from '../../components/model/model'
import { GoalInput } from './AddGoalPage'

export type GoalNumericValidationRequest = {
  goalInput: GoalInput
  selectedGoalType: GoalType
  errorMessage: string
}

export type ValidationResponse = {
  success: boolean
  message: string
}

export const validateNumericInput = ({
  goalInput,
  selectedGoalType,
  errorMessage,
}: GoalNumericValidationRequest): ValidationResponse => {
  if (
    selectedGoalType === GoalType.MeasurableGoal &&
    (goalInput.targetResult === '' || goalInput.targetTaskCountPerWeek === '')
  ) {
    return {
      success: false,
      message: errorMessage,
    }
  }
  return {
    success: true,
    message: '',
  }
}
