import { Button } from 'react-bootstrap'
import './ActionButton.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

export interface ActionButtonProps {
  handleOnClick?: () => void
  text: string
  variant?: string
  size?: string
  isSubmitButton?: boolean
}

const ActionButton = ({
  handleOnClick,
  text,
  variant,
  isSubmitButton,
}: ActionButtonProps) => {
  const getVariant = () => {
    return variant ? variant : 'secondary'
  }
  return (
    <Button
      onClick={handleOnClick}
      variant={getVariant()}
      className="btn-responsive m-2"
      type={isSubmitButton ? 'submit' : 'button'}
    >
      <FontAwesomeIcon icon={faPlusCircle} size="1x" className={'me-1'} />
      {text}
    </Button>
  )
}
export default ActionButton
