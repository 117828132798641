import { Col, Container, Row } from 'react-bootstrap'

interface MainContainerProps {
  children: React.ReactNode
}

const MainContainer = ({ children }: MainContainerProps) => {
  return (
    <Container fluid className={'mt-2 mt-md-5 mt-lg-7'}>
      <Row className="justify-content-center dynamicHeightWithMinHeight">
        <Col xs={12} md={8} lg={6} className="goachy-main-container">
          {/* This will center the column for medium to large devices 
              and use full width for extra-small to small devices */}
          <div className="pt-2">
            {/* Padding top: more on medium and up */}
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default MainContainer
