import React, { createContext, useContext, useState, ReactNode } from 'react'
import { Task } from '../components/model/model'

// Define the shape of the context
interface TaskContextType {
  taskInContext: Task | null
  updateTaskContext: (newTask: Task | null) => void
}

// Create a Context with an initial undefined value
const TaskContext = createContext<TaskContextType | undefined>(undefined)

// Create a custom hook to use the context
export const useTask = (): TaskContextType => {
  const context = useContext(TaskContext)
  if (context === undefined) {
    throw new Error('useTask must be used within a TaskProvider')
  }
  return context
}

// Create a Provider component with props type
interface TaskProviderProps {
  children: ReactNode
}

export const TaskProvider: React.FC<TaskProviderProps> = ({ children }) => {
  const [task, setTask] = useState<Task | null>(null)

  const updateTaskContext = (newTask: Task | null) => {
    setTask(newTask)
  }

  return (
    <TaskContext.Provider
      value={{ taskInContext: task, updateTaskContext: updateTaskContext }}
    >
      {children}
    </TaskContext.Provider>
  )
}
