import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const MyUserTasksInfo = () => {
  const { t } = useTranslation('MyUserTasksInfo')
  return (
    <div>
      <Container className={'mb-2'}>
        <Row className={'justify-content-center'}>
          <Col md={12}>
            <>
              <p>{t('')}</p>
              <div className={'mb-2'}>
                <strong>{t('subtitle1')}:</strong>
              </div>
              <li>{t('p1')}</li>
              <li>{t('p2')}</li>
              <li>{t('p3')}</li>
              <li>
                <b style={{ color: '#4B49EA' }}>{t('newTask')}</b>
              </li>
              <li>
                <b style={{ color: '#F8B234' }}>{t('ongoingTask')}</b>
              </li>

              <li>
                <strong style={{ color: '#3DCD5E' }}>{t('Done')}</strong>
              </li>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MyUserTasksInfo
