import { UserId } from '../components/model/model'
import { AuthService } from './AuthService'
import { GoalService } from './GoalService'
import { TaskService } from './TaskService'
import { UserService } from './UserService'

export type DeleteUserAccountRequest = {
  userId: UserId
}

export type DeleteUserAccountResponse = {
  userId?: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export class AccountService {
  private authService: AuthService
  private userService: UserService
  private goalService: GoalService
  private taskService: TaskService

  constructor(
    authService: AuthService,
    userService: UserService,
    goalService: GoalService,
    taskService: TaskService,
  ) {
    this.authService = authService
    this.userService = userService
    this.goalService = goalService
    this.taskService = taskService
  }

  public async deleteUserAccount(
    deleteUserAccountRequest: DeleteUserAccountRequest,
  ): Promise<DeleteUserAccountResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      //DELETE ALL TASKS AND THEIR RELATIONS
      const allUserTasksResponse = await this.userService.getUserTasks({
        userId: deleteUserAccountRequest.userId,
      })

      const allUserTasks = allUserTasksResponse.userTasks
        ? allUserTasksResponse.userTasks
        : []

      for (const userTask of allUserTasks) {
        const taskId = userTask.taskId

        const deleteTaskResponse = await this.taskService.deleteTask(taskId)

        if (deleteTaskResponse.status === 'FAILURE') {
          console.error('Failed to deleted task and all relations, id:', taskId)
        }
        if (deleteTaskResponse.status === 'SUCCESS') {
          console.log(
            'Successfully deleted task and all relations, id:',
            taskId,
          )
        }
      }

      //DELETE ALL GOALS AND THEIR RELATIONS
      const allUserGoalsResponse = await this.goalService.getGoalsByUserId({
        userId: deleteUserAccountRequest.userId,
      })

      const allUserGoals = allUserGoalsResponse.goals
        ? allUserGoalsResponse.goals
        : []

      for (const userGoal of allUserGoals) {
        const goalId = userGoal.id
        await this.goalService.deleteGoal({ goalId: goalId })
      }

      //DELETE USER, BOTH IN DB AND COGNITO
      this.authService.deleteUser()
      this.userService.deleteUser(deleteUserAccountRequest.userId)

      //DELETE USER FROM LOCAL STORAGE
      localStorage.removeItem('goachyUser')

      return {
        userId: deleteUserAccountRequest.userId,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to delete user, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to delete user, error: ${error}`,
      }
    }
  }
}
