import { ReactNode } from 'react'
import Container from 'react-bootstrap/Container'
import './StyledContainer.css'

export interface StyledContainerProps {
  children: ReactNode
  useTaskCardBg?: boolean
}
const StyledContainer = ({ children, useTaskCardBg }: StyledContainerProps) => {
  const customeContainerClass = useTaskCardBg
    ? 'custom-container-task-card-bg'
    : 'custom-container'

  return <Container className={customeContainerClass}>{children}</Container>
}

export default StyledContainer
