import { ReactNode, createContext, useContext, useState } from 'react'
import { UserCredentials } from '../App'
import { updateTheme } from '../Themes'

interface UserCredentialsContextType {
  userCredentialsInContext: UserCredentials | null
  updateUserCredentialsContext: (
    userCredentials: UserCredentials | null,
  ) => void
}

const UserCredentialsContext = createContext<
  UserCredentialsContextType | undefined
>(undefined)

// Create a custom hook to use the context
export const useUserCredentials = (): UserCredentialsContextType => {
  const context = useContext(UserCredentialsContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
interface UserProviderProps {
  children: ReactNode
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [userCredentials, setUserCredentials] =
    useState<UserCredentials | null>(null)

  const updateUserCredentialsContext = (
    userCredentials: UserCredentials | null,
  ) => {
    setUserCredentials(userCredentials)

    const currentTheme = userCredentials?.userInfo?.theme ?? 'light'

    if (userCredentials?.userInfo?.organizationName) {
      updateTheme(userCredentials.userInfo.organizationName, currentTheme)
    }
  }

  return (
    <UserCredentialsContext.Provider
      value={{
        userCredentialsInContext: userCredentials,
        updateUserCredentialsContext: updateUserCredentialsContext,
      }}
    >
      {children}
    </UserCredentialsContext.Provider>
  )
}
