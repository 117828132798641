// PreviousLocationContext.tsx

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'

interface PreviousLocationContextType {
  prevLocation: string
  setPrevLocation: React.Dispatch<React.SetStateAction<string>>
}

const defaultValue: PreviousLocationContextType = {
  prevLocation: '',
  setPrevLocation: () => {},
}

const PreviousLocationContext =
  createContext<PreviousLocationContextType>(defaultValue)

export const usePreviousLocation = () => useContext(PreviousLocationContext)

interface Props {
  children: ReactNode
}

export const PreviousLocationProvider: React.FC<Props> = ({ children }) => {
  const [prevLocation, setPrevLocation] = useState<string>('')
  const location = useLocation()

  useEffect(() => {
    const handleLocationChange = () => {
      setPrevLocation(location.pathname)
    }

    // Triggered on location change
    handleLocationChange()

    // Return cleanup function that React will run on component unmount
    return () => {}
  }, [location])

  return (
    <PreviousLocationContext.Provider value={{ prevLocation, setPrevLocation }}>
      {children}
    </PreviousLocationContext.Provider>
  )
}
