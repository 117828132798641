import { useTranslation } from 'react-i18next'
import {
  TaskDataType,
  TypeOfRelation,
  UserTaskStatus,
} from '../../components/model/model'
import {
  convertToDate,
  getTranslationFromTaskEventStatus,
} from '../../utils/converters'
import { getDifferenceInDays } from '../../utils/timeAndDateUtils'
import './SecondUserTaskInfoBox.css'

/**
 * This component is used to display information about a newly created task or when a task is listed to be attached to a goal.
 */
export interface SecondUserTaskInfoBoxProps {
  name: string
  category: string
  status: UserTaskStatus
  endDate?: string
  timesDone?: number
  htmlDescription?: string
  assignedTo?: string
  assignedBy?: string
  typeOfRelation?: TypeOfRelation
  taskDataType?: TaskDataType
}

const SecondUserTaskInfoBox = ({
  name,
  endDate,
  category,
  status,
  timesDone,
  htmlDescription,
  assignedTo,
  assignedBy,
  typeOfRelation,
  taskDataType,
}: SecondUserTaskInfoBoxProps) => {
  const { t } = useTranslation('SecondUserTaskInfoBox')
  const today = new Date()

  const isDone = status === UserTaskStatus.Done

  const getDaysStatusText = () => {
    if (isDone) {
      return t('wellDone')
    }
    if (!endDate) {
      return t('noEndDate')
    }
    const diffInDays = getDifferenceInDays(convertToDate(endDate), today)
    const daysText =
      diffInDays < 0
        ? t('daysLate', { diffInDays: Math.abs(diffInDays) })
        : t('daysLeft', { diffInDays: diffInDays })
    return daysText
  }

  const isAssignedOrUnassigned = () => {
    return (
      (typeOfRelation && typeOfRelation === TypeOfRelation.Assigned) ||
      (typeOfRelation && typeOfRelation === TypeOfRelation.Unassigned)
    )
  }

  const isOwner = () => {
    return typeOfRelation === TypeOfRelation.Owner
  }

  const getTaskDataTypeText = () => {
    if (!taskDataType) return ''

    switch (taskDataType) {
      case TaskDataType.QuestionAndAnswerDataType:
        return t('questionAndAnswer')
      case TaskDataType.OneTimeTaskDataType:
        return t('oneTimeTask')
      case TaskDataType.RecurringTaskDataType:
        return t('recurringTask')
      default:
        return ''
    }
  }

  return (
    <div className={`task-info-box`}>
      {typeOfRelation && typeOfRelation === TypeOfRelation.Unassigned && (
        <div className={'task-overdue'}>{t('noLongerAssigned')}</div>
      )}
      <div className="items-left-and-right">
        <span className="task-name">
          {name} {isDone && <>🌟</>}
        </span>
        <span className={`task-category`}>{getDaysStatusText()}</span>
      </div>
      <div className="task-due-date">
        {t('typeOfTask')}: {getTaskDataTypeText()}
      </div>

      <div className="items-left-and-right">
        {endDate ? (
          <span className="task-due-date">
            {t('endDate')}: {endDate}
          </span>
        ) : (
          <span className="task-due-date">{t('noEndDate')}</span>
        )}

        <span className={`task-status`}>
          {getTranslationFromTaskEventStatus(status, t)}
        </span>
      </div>

      <div className="task-due-date">
        {t('category')}: {category}
      </div>
      {timesDone !== 0 && (
        <div className="task-due-date">
          {t('amountEvents', { timesDone: timesDone })}
        </div>
      )}

      {isOwner() && (
        <div className="task-due-date">{t('taskCreatedByYou')}</div>
      )}

      {isAssignedOrUnassigned() && !isOwner() && (
        <>
          <div className="task-due-date">
            {assignedTo && assignedTo !== '' && (
              <>
                {t('to')}: {assignedTo}
              </>
            )}
          </div>
          <div className="task-due-date">
            {assignedBy && assignedBy !== '' && (
              <>
                {t('from')}: {assignedBy}
              </>
            )}
          </div>
        </>
      )}
      {typeOfRelation && typeOfRelation === TypeOfRelation.Owner && (
        <div className="task-due-date">
          {assignedTo && assignedTo !== '' && (
            <>
              {t('owner')}: {assignedTo}
            </>
          )}
        </div>
      )}

      {htmlDescription && (
        <>
          <hr></hr>
          <div className={'task-name'}>{t('task')}</div>
          <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
        </>
      )}
    </div>
  )
}

export default SecondUserTaskInfoBox
