import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ToolsInfo = () => {
  const { t } = useTranslation('ToolsInfo')
  return (
    <div>
      <Container className={'mb-2'}>
        <Row className={'justify-content-center'}>
          <Col md={12}>
            <>
              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('title')}
              </h6>
              <p>{t('description')}</p>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ToolsInfo
