import {
  faListCheck,
  faScaleUnbalancedFlip,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const MyGoalsInfo = () => {
  const { t } = useTranslation('MyGoalsInfo')
  return (
    <div>
      <Container className={'mb-2'}>
        <Row className={'justify-content-center'}>
          <Col md={12}>
            <>
              <h5 className={'mt-4 mb-3'}>{t('goalsPage.title')}</h5>
              <strong>
                <u>{t('goalsPage.createNewGoal.title')}</u>
              </strong>
              <p>{t('goalsPage.createNewGoal.description')}</p>

              <strong>
                <u>{t('goalsPage.openGoal.title')}</u>
              </strong>
              <p>{t('goalsPage.openGoal.description')}</p>
              <p>
                {t('goalsPage.openGoal.subHeader')}
                <li>{t('goalsPage.openGoal.list.editGoal')}</li>
                <li>{t('goalsPage.openGoal.list.viewStatistics')}</li>
                <li>{t('goalsPage.openGoal.list.viewTasks')}</li>
                <li>{t('goalsPage.openGoal.list.modifyTasks')}</li>
              </p>

              <strong>
                <u>{t('goalsPage.goalTypes.title')}</u>
              </strong>
              <p className={'mt-3'}>
                <FontAwesomeIcon
                  icon={faScaleUnbalancedFlip}
                  size={'lg'}
                  className={'me-2'}
                />

                <strong>{t('goalsPage.goalTypes.measurableGoal.title')}</strong>
                <div>
                  {' '}
                  {t('goalsPage.goalTypes.measurableGoal.description')}
                </div>
              </p>
              <p className={'mt-3'}>
                <FontAwesomeIcon
                  icon={faListCheck}
                  size={'lg'}
                  className={'me-2'}
                />
                <strong>
                  {' '}
                  {t('goalsPage.goalTypes.completeAllTasks.title')}
                </strong>{' '}
                <div>
                  {' '}
                  {t('goalsPage.goalTypes.completeAllTasks.description')}
                </div>
              </p>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MyGoalsInfo
