export function updateTheme(organizationName: string, mode: string = 'light') {
  const organizationNameLowerCase = organizationName.toLowerCase()

  const themeName = organizationNameLowerCase + '-' + mode

  console.log('update theme', themeName)

  const root = document.documentElement

  switch (themeName) {
    case 'christinaskolan':
      root.style.setProperty('--main-menu-bg-goachy', '#772a2a')
      root.style.setProperty('--main-container-bg-goachy', '#F8E1DB')
      root.style.setProperty('--secondary-container-bg-goachy', '#1e1e2e')
      root.style.setProperty('--secondary-container-bg-goachy-hover', '#1e1e10')
      root.style.setProperty('--my-custom-button-bg', '#131212')
      root.style.setProperty('--my-custom-button-bg-hover', '#4a4747')

      break
    case 'studybuddy-light':
      root.getElementsByTagName('body')[0].style.backgroundImage = 'none'
      root.getElementsByTagName('body')[0].style.backgroundColor = '#F5F5F5'
      root.style.setProperty('--main-menu-bg-goachy', '#F8E1DB')
      root.style.setProperty('--main-menu-font-color', '#000')

      root.style.setProperty('--main-container-bg-goachy', '#F8E1DB')
      root.style.setProperty('--main-container-color-goachy', '#000')

      root.style.setProperty('--styled-container-bg', '#F08080')

      root.style.setProperty('--task-goal-card-bg', '#d3d3d3')
      root.style.setProperty('--task-goal-card-font-color', '#0c3d60')
      root.style.setProperty('--task-goal-card-second-font-color', '#0c3d60')

      root.style.setProperty('--status-buttons-bg', '#fff')
      root.style.setProperty('--status-buttons-text-color', '#000')

      root.style.setProperty('--my-custom-button-bg', '#131212')
      root.style.setProperty('--my-custom-button-bg-hover', '#4a4747')

      root.style.setProperty('--main-subheader-color', '#017599')
      root.style.setProperty('--main-small-subheader-color', '#017599')

      root.style.setProperty('--text-mid-size-color', '#017599')
      root.style.setProperty('--custom-icon-color', '#017599')
      root.style.setProperty('--custom-icon-color', '#000')

      root.style.setProperty('--graph-actual-fill-color', '#a3d8ad')
      root.style.setProperty('--graph-target-fill-color', '#a7c7e7')
      root.style.setProperty('--graph-tooltip-bg', '#0000FF')
      root.style.setProperty('--graph-tooltip-cursor-fill', '#808080')

      break
    case 'admentum-light':
      root.getElementsByTagName('body')[0].style.backgroundImage = 'none'
      root.getElementsByTagName('body')[0].style.backgroundColor = '#6BA5FF'
      root.style.setProperty('--main-menu-bg-goachy', '#FFF')
      root.style.setProperty('--main-menu-font-color', '#000')

      root.style.setProperty('--main-container-bg-goachy', '#D6D6D6')
      root.style.setProperty('--main-container-color-goachy', '#000')

      root.style.setProperty('--styled-container-bg', '#fff')

      root.style.setProperty('--task-goal-card-bg', '#6BA5FF')
      root.style.setProperty('--task-goal-card-font-color', '#0c3d60')
      root.style.setProperty('--task-goal-card-second-font-color', '#fff')

      root.style.setProperty('--status-buttons-bg', '#fff')
      root.style.setProperty('--status-buttons-text-color', '#000')

      root.style.setProperty('--my-custom-button-bg', '#131212')
      root.style.setProperty('--my-custom-button-bg-hover', '#4a4747')

      root.style.setProperty('--main-subheader-color', '#017599')
      root.style.setProperty('--main-small-subheader-color', '#017599')

      root.style.setProperty('--text-mid-size-color', '#017599')
      root.style.setProperty('--custom-icon-color', '#017599')
      root.style.setProperty('--custom-icon-color', '#000')

      root.style.setProperty('--graph-actual-fill-color', '#a3d8ad')
      root.style.setProperty('--graph-target-fill-color', '#a7c7e7')
      root.style.setProperty('--graph-tooltip-bg', '#0000FF')
      root.style.setProperty('--graph-tooltip-cursor-fill', '#808080')
      break
    case 'goachy-dark':
      root.getElementsByTagName('body')[0].style.backgroundColor = 'none'
      root.getElementsByTagName('body')[0].style.backgroundColor = '#202020'
      root.style.setProperty('--main-menu-bg-goachy', '#121212')

      root.style.setProperty('--main-container-bg-goachy', '#30353E')
      root.style.setProperty('--main-container-color-goachy', '#FFF')

      root.style.setProperty('--styled-container-bg', '#202020')

      root.style.setProperty('--task-goal-card-bg', '#121212')
      root.style.setProperty('--task-goal-card-font-color', '#bcc6cd')
      root.style.setProperty('--task-goal-card-second-font-color', '##bcc6cd')

      root.style.setProperty('--status-buttons-bg', '#333333')
      root.style.setProperty('--status-buttons-text-color', '#bcc6cd')

      root.style.setProperty('--my-custom-button-bg', '#007bff')
      root.style.setProperty('--my-custom-button-bg-hover', '#1e90ff')

      root.style.setProperty('--main-subheader-color', '#bdc5cb')
      root.style.setProperty('--main-small-subheader-color', '#bdc5cb')

      root.style.setProperty('--text-mid-size-color', '#fff')
      root.style.setProperty('--custom-icon-color', '#fff')

      root.style.setProperty('--custom-modal-header-bg', '#232832')
      root.style.setProperty('--custom-modal-body-bg', '#232832')
      root.style.setProperty('--custom-modal-footer-bg', '#232832')
      root.style.setProperty('--custom-modal-color', '#fff')
      root.style.setProperty('--custom-modal-secondary-bg', '#202020')
      root.style.setProperty(
        '--override-custom-modal-bg-color',
        'rgba(0, 0, 0, 0.7)',
      )

      root.style.setProperty('--graph-actual-fill-color', '#a3d8ad')
      root.style.setProperty('--graph-target-fill-color', '#a7c7e7')
      root.style.setProperty('--graph-tooltip-bg', '#0000FF')
      root.style.setProperty('--graph-tooltip-cursor-fill', '#808080')
      break
    case 'goachy-light':
      root.getElementsByTagName('body')[0].style.backgroundColor = 'none'
      root.getElementsByTagName('body')[0].style.backgroundColor = '#f9f9f9'
      root.style.setProperty('--main-menu-bg-goachy', '#007BFF')
      root.style.setProperty('--main-menu-font-color', '#000')

      root.style.setProperty('--main-container-bg-goachy', '#fff')
      root.style.setProperty('--main-container-color-goachy', '#000')

      root.style.setProperty('--styled-container-bg', '#A6D8FF')

      root.style.setProperty('--task-goal-card-bg', '#FDEBF1')
      root.style.setProperty('--task-goal-card-font-color', '#0c3d60')
      root.style.setProperty('--task-goal-card-second-font-color', '#0c3d60')

      root.style.setProperty('--status-buttons-bg', '#fff')
      root.style.setProperty('--status-buttons-text-color', '#000')

      root.style.setProperty('--my-custom-button-bg', '#131212')
      root.style.setProperty('--my-custom-button-bg-hover', '#4a4747')

      // root.style.setProperty('--main-subheader-color', '#017599')
      // root.style.setProperty('--main-small-subheader-color', '#017599')

      root.style.setProperty('--main-subheader-color', '#0c3d60')
      root.style.setProperty('--main-small-subheader-color', '#0c3d60')

      root.style.setProperty('--text-mid-size-color', '#017599')
      root.style.setProperty('--custom-icon-color', '#017599')

      root.style.setProperty('--custom-modal-header-bg', '#fff')
      root.style.setProperty('--custom-modal-body-bg', '#FDEBF1')
      root.style.setProperty('--custom-modal-footer-bg', '#fff')
      root.style.setProperty('--custom-modal-color', '#000')
      root.style.setProperty('--custom-modal-secondary-bg', '#E0E0E0')

      root.style.setProperty('--override-custom-modal-bg-color', 'none')

      root.style.setProperty('--graph-actual-fill-color', '#007BFF')
      root.style.setProperty('--graph-target-fill-color', '#CC0066')
      root.style.setProperty('--graph-tooltip-bg', '#EAB7C1')
      root.style.setProperty('--graph-tooltip-cursor-fill', '#D3D3D3')

      break
    default:
      // root.getElementsByTagName('body')[0].style.backgroundImage = 'none'
      // root.getElementsByTagName('body')[0].style.backgroundColor = '#F5F5F5'

      // root.style.setProperty('--main-menu-bg-goachy', '#1e1e2e')
      // root.style.setProperty('--main-container-bg-goachy', '#f0f0f0')

      // root.style.setProperty('--my-custom-button-bg', '#007bff')
      // root.style.setProperty('--my-custom-button-bg-hover', '#4a4747')
      break
  }
}

/**
 * Good background colors when the background colors light:
 * #f0f0f0
 *
 */
