import { SyntheticEvent, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomModal } from '../components/CustomModal'
import ProgressSpinner from '../components/ProgressSpinner'
import StyledContainer from '../components/StyledContainer'
import {
  useAuthService,
  useGoalService,
  useUserAccountService,
  useUserService,
} from '../service/ServicesHooks'

export interface MyAccountPageProps {
  userId: string
}

const MyAccountPage = () => {
  const { t } = useTranslation('MyAccountPage')
  const [showModal, setShowModal] = useState<boolean>(false)

  const authService = useAuthService()
  const userService = useUserService()
  const goalService = useGoalService()
  const userAccountService = useUserAccountService()

  const { userId } = useParams()
  const navigate = useNavigate()

  if (!userId) {
    throw new Error('No userId')
  }

  if (!authService.getJwtToken()) {
    console.log('No token')
    navigate('/')
  }

  const { data: userTasks, isLoading: isLoadingUserTasks } = useQuery(
    ['userTasks', { userId }],
    () => userService.getUserTasks({ userId: userId! }),
  )

  const { data: userGoals, isLoading: isLoadingUserGoals } = useQuery(
    ['userGoals', { userId }],
    () => goalService.getGoalsByUserId({ userId: userId! }),
  )
  const { data: userResponse, isLoading: isLoadingUser } = useQuery(
    ['user', { userId }],
    () => userService.getUser(userId),
  )

  if (isLoadingUser || isLoadingUserGoals || isLoadingUserTasks) {
    return <ProgressSpinner />
  }

  const user = userResponse?.users?.[0]

  const handleDeleteAccount = async (event: SyntheticEvent) => {
    event.preventDefault()
    setShowModal(true)
  }

  const handleConfirmRemove = async () => {
    try {
      await userAccountService.deleteUserAccount({ userId: userId })
      setShowModal(false)
      navigate('/logout')
    } catch (error) {
      console.error('Error deleting user', error)
      throw new Error('Error deleting user')
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <div className={'mt-3'} />

      <div className={'mx-3'}>
        <StyledContainer>
          <h3 className="text-center mb-4">{t('title')}</h3>

          <p>{t('userInfo', { email: user?.email })}</p>
          <p>
            {t('taskCountInfo', { taskCount: userTasks?.userTasks?.length })}
          </p>
          <p>{t('goalCountInfo', { goalCount: userGoals?.goals?.length })}</p>

          <hr />

          <p style={{ color: 'red', fontSize: '16px' }} className={'mt-4'}>
            {t('deleteInfoText')}
          </p>
          <div className="d-flex justify-content-center">
            <Button
              onClick={handleDeleteAccount}
              variant="danger"
              className="mb-2 mt-2 w-50"
            >
              {t('deleteButton')}
            </Button>
          </div>

          <CustomModal
            show={showModal}
            title={t('verifyTitle')}
            message={t('verifyMessage')}
            handleConfirm={handleConfirmRemove}
            handleClose={handleClose}
            type="VERIFY"
          />
        </StyledContainer>
        <div className={'mb-4'}></div>
      </div>
    </>
  )
}
export default MyAccountPage
