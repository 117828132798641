import { TFunction } from 'i18next'
import moment from 'moment'
import {
  GoalType,
  TaskEventType,
  UserTaskStatus,
} from '../components/model/model'

export const formatDateTo_yyyyMMdd = (date: Date): string => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // Add 1 because months are 0-indexed
  const day = date.getDate()

  // Pad the month and day with a leading zero if they are less than 10
  const formattedMonth = month < 10 ? `0${month}` : month.toString()
  const formattedDay = day < 10 ? `0${day}` : day.toString()

  return `${year}-${formattedMonth}-${formattedDay}`
}

export const formatDateToShortDateTime = (dateStr: string): string => {
  if (dateStr.endsWith('UTC')) {
    dateStr = dateStr.replace('UTC', '')
    dateStr = dateStr.slice(0, dateStr.lastIndexOf(':'))
  }

  const formattedDateString = moment.utc(dateStr).local().toDate()

  const date = new Date(formattedDateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // Add 1 because months are 0-indexed
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()

  // Pad the month and day with a leading zero if they are less than 10
  const formattedMonth = month < 10 ? `0${month}` : month.toString()
  const formattedDay = day < 10 ? `0${day}` : day.toString()
  const formattedHours = hours < 10 ? `0${hours}` : hours.toString()
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString()

  return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`
}

export const convertToDate = (dateString: string): Date => {
  const date = moment(dateString, 'YYYY-MM-DD HH:mm:ss:SSS UTC')
  return date.toDate()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertStringToEnum(value: string, enumType: any): any | null {
  if (Object.values(enumType).includes(value)) {
    return enumType[value as keyof typeof enumType]
  } else {
    return null
  }
}

export const getTranslationFromTaskEventStatus = (
  status: string,
  t: TFunction,
) => {
  switch (status) {
    case UserTaskStatus.New:
      return t('notStarted')
    case UserTaskStatus.Ongoing:
      return t('ongoing')
    case UserTaskStatus.Done:
      return t('done')
    default:
      return ''
  }
}

export const resolveEventType = (status: UserTaskStatus): TaskEventType => {
  switch (status) {
    case UserTaskStatus.Ongoing:
      return TaskEventType.Ongoing
    case UserTaskStatus.Done:
      return TaskEventType.Done
    case UserTaskStatus.New:
      return TaskEventType.New
    default:
      throw new Error(`Unknown status: ${status}`)
  }
}

export const mapGoalTypeToFriendlyText = (goalType: string, t: TFunction) => {
  switch (goalType) {
    case GoalType.FinishAllTasks:
      return t('finishAllTasksLabel')
    case GoalType.MeasurableGoal:
      return t('measurableGoalLabel')
    default:
      throw new Error('Unknown goal type')
  }
}
