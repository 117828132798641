import { useTranslation } from 'react-i18next'
import StarRating from '../../../components/StarRatingComponent'
import StyledContainer from '../../../components/StyledContainer'
import { WrittenAnswerResult } from './WrittenAnswerInput'

export interface WrittenAnswerResultsViewProps {
  writtenAnswerResult: WrittenAnswerResult
  withPoints: boolean
}

const WrittenAnswerResultsView = ({
  withPoints,
  writtenAnswerResult,
}: WrittenAnswerResultsViewProps) => {
  const { t } = useTranslation('WrittenAnswerResultsView')

  const getColorForMisspellingCorrections = (
    points: number,
    corrections: number,
  ) => {
    if (!withPoints) {
      return 'green'
    }
    if (points === 0) {
      return 'red'
    }
    if (corrections === 0) {
      return 'green'
    }
    if (corrections > 0) {
      return 'orange'
    }
    return 'red'
  }

  const getTextColor = (points: number) => {
    if (!withPoints) {
      return 'black'
    }
    return points > 0 ? 'green' : 'red'
  }

  const getPercentageCorrect = () => {
    const percentageCorrect =
      (writtenAnswerResult.totalPoints /
        writtenAnswerResult.writtenAnswerItems.size) *
      100
    return percentageCorrect.toFixed(0)
  }
  return (
    <>
      <div>
        {withPoints && (
          <StyledContainer>
            <div className={'text-center'}>
              <h2 className="main-small-subheader">{t('yourResultTitle')}</h2>
              <StarRating
                points={writtenAnswerResult.totalPoints}
                maxPoints={writtenAnswerResult.writtenAnswerItems.size}
                removeNumberOfLightningStars={
                  writtenAnswerResult.totalMisspellingCorrections > 0 ? 1 : 0
                }
              />

              <div className={'text-center'}>
                {t('correctAnswerPercentage', {
                  correctPercentage: getPercentageCorrect(),
                })}
              </div>
              <div className={'text-center'}>
                {t('numOfMisspellingCorrections', {
                  numOfMisspellings:
                    writtenAnswerResult.totalMisspellingCorrections || 0,
                })}
              </div>
            </div>
          </StyledContainer>
        )}
        <div className={'mb-4'}></div>

        {Array.from(writtenAnswerResult.writtenAnswerItems.values()).map(
          (resultItem, index) => (
            <div key={index} className={'mb-3'}>
              <StyledContainer useTaskCardBg={true}>
                <p>
                  <strong>
                    {t('questionIndexAndText', {
                      questionIndex: index + 1,
                      questionText: resultItem.question,
                    })}
                  </strong>
                </p>
                <p
                  style={{
                    color: getTextColor(resultItem.points),
                  }}
                >
                  <strong>{t('yourAnswer')}</strong>{' '}
                  <span style={{ marginLeft: '1.8em' }}>
                    {resultItem.answer}
                  </span>
                </p>
                <p
                  style={{
                    color: 'green',
                  }}
                >
                  <strong>{t('correctAnswer')}</strong>{' '}
                  <span style={{ marginLeft: '0.5em' }}>
                    {resultItem.correctAnswer}
                  </span>
                </p>
                <hr />

                <div key={resultItem.id} style={{ position: 'relative' }}>
                  {/* SHOW POINTS AND MISSPELLINGS RESULT */}
                  {withPoints && (
                    <>
                      <p className={'mb-3'}>{t('answerPoints')}</p>
                      <div className={'mb-4'}></div>
                      <div
                        className="circle-number"
                        style={{
                          backgroundColor:
                            resultItem.points > 0 ? 'green' : 'red',
                        }}
                      >
                        {resultItem.points}
                      </div>

                      <>
                        <p style={{ marginLeft: '0' }}>
                          {t('answerMisspellingCorrections')}
                        </p>
                        <div className={'mb-4'}></div>
                        <div
                          className="circle-number mt-5"
                          style={{
                            backgroundColor: getColorForMisspellingCorrections(
                              resultItem.points,
                              resultItem.misspellingCorrections,
                            ),
                          }}
                        >
                          {resultItem.misspellingCorrections}
                        </div>
                      </>

                      <hr />
                    </>
                  )}
                </div>
              </StyledContainer>
            </div>
          ),
        )}
      </div>
    </>
  )
}
export default WrittenAnswerResultsView
