import { PropsWithChildren, useEffect } from 'react'
import { useCheckAndUpdateUserCredentials } from '../../hooks/useCheckUser'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { useNavigate } from 'react-router-dom'

type ProtectedRouteProps = PropsWithChildren

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { userCredentialsInContext } = useUserCredentials()

  const navigate = useNavigate()

  const { checkUser } = useCheckAndUpdateUserCredentials()

  useEffect(() => {
    if (!userCredentialsInContext?.isSignedIn) {
      checkUser()
    }

    if (!userCredentialsInContext?.isSignedIn) {
      const loginPath = `/login/user/${Date.now()}`
      navigate(loginPath)
    }
  }, [navigate, checkUser, userCredentialsInContext])
  return children
}

export default ProtectedRoute
