import { useTranslation } from 'react-i18next'
import StarRating from '../../../components/StarRatingComponent'
import StyledContainer from '../../../components/StyledContainer'
import { MultipleAnswerResult } from './MultipleAnswersInput'

export interface WrittenAnswerResultsViewProps {
  writtenAnswerResult: MultipleAnswerResult
  withPoints: boolean
}

const MultipleAnswersResultsView = ({
  withPoints,
  writtenAnswerResult,
}: WrittenAnswerResultsViewProps) => {
  const { t } = useTranslation('MultipleAnswersResultsView')

  const getTextColor = (points: number) => {
    if (!withPoints) {
      return 'black'
    }
    return points > 0 ? 'green' : 'red'
  }

  const getPercentageCorrect = () => {
    const percentageCorrect =
      (writtenAnswerResult.totalPoints /
        writtenAnswerResult.multipleAnswersItems.size) *
      100
    return percentageCorrect.toFixed(0)
  }
  return (
    <>
      <div>
        {withPoints && (
          <StyledContainer>
            <div className={'text-center'}>
              <h2 className="main-small-subheader">{t('yourResultTitle')}</h2>
              <StarRating
                points={writtenAnswerResult.totalPoints}
                maxPoints={writtenAnswerResult.multipleAnswersItems.size}
              />

              <div className={'text-center'}>
                {t('correctAnswerPercentage', {
                  correctPercentage: getPercentageCorrect(),
                })}
              </div>
            </div>
          </StyledContainer>
        )}
        <div className={'mb-4'}></div>

        {Array.from(writtenAnswerResult.multipleAnswersItems.values()).map(
          (resultItem, index) => (
            <div key={index} className={'mb-3'}>
              <StyledContainer useTaskCardBg={true}>
                <p>
                  <strong>
                    {t('questionIndexAndText', {
                      questionIndex: index + 1,
                      questionText: resultItem.question,
                    })}
                  </strong>
                </p>
                <p
                  style={{
                    color: getTextColor(resultItem.points),
                  }}
                >
                  <strong>{t('yourAnswer')}</strong>{' '}
                  <span style={{ marginLeft: '1.8em' }}>
                    {resultItem.answer}
                  </span>
                </p>
                <p
                  style={{
                    color: 'green',
                  }}
                >
                  <strong>{t('correctAnswer')}</strong>{' '}
                  <span style={{ marginLeft: '0.5em' }}>
                    {resultItem.correctAnswer}
                  </span>
                </p>
                <hr />

                <div key={resultItem.id} style={{ position: 'relative' }}>
                  {/* SHOW POINTS */}
                  {withPoints && (
                    <>
                      <p className={'mb-3'}>{t('answerPoints')}</p>
                      <div className={'mb-4'}></div>
                      <div
                        className="circle-number"
                        style={{
                          backgroundColor:
                            resultItem.points > 0 ? 'green' : 'red',
                        }}
                      >
                        {resultItem.points}
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              </StyledContainer>
            </div>
          ),
        )}
      </div>
    </>
  )
}
export default MultipleAnswersResultsView
