import {
  faCircleArrowDown,
  faCircleArrowUp,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import StyledContainer from '../../../components/StyledContainer'
import TextToSpeechComponent from '../../../components/TextToSpeechComponent'
import { QuestionAndAnswerData } from '../../../components/model/model'
import './FlipCard.css'
import WrittenAnswerResultsView from './WrittenAnswerResultsView'
import { useGoal } from '../../../context/GoalContext'
import { useNavigate } from 'react-router-dom'
import { useUserCredentials } from '../../../context/UserCredentialsContext'

export type WrittenAnswerItem = {
  id: number
  question: string
  answer: string
  correctAnswer: string
  misspellingCorrections: number
  points: 0 | 1
}

export type WrittenAnswerResult = {
  writtenAnswerItems: Map<number, WrittenAnswerItem>
  totalPoints: number
  totalMisspellingCorrections: number
}

export interface WrittenAnswerInputProps {
  item: QuestionAndAnswerData
  currentIndex: number
  lastIndexOfItems: number
  withPoints?: boolean
  withMisspellings?: boolean
  increaseCurrentItemIndex: () => void
  playAgain: (allQuestions: boolean, indexes?: number[]) => void
  saveResultInTaskEventCb: (
    withPoints: boolean,
    result: WrittenAnswerResult,
  ) => void
}

const WrittenAnswerInput = ({
  item,
  currentIndex,
  lastIndexOfItems,
  increaseCurrentItemIndex,
  withPoints = false,
  withMisspellings,
  playAgain,
  saveResultInTaskEventCb,
}: WrittenAnswerInputProps) => {
  const { t } = useTranslation('WrittenAnswerInput')
  const inputRef = useRef<HTMLInputElement>(null)
  const [showTextToSpeech, setShowTextToSpeech] = useState<boolean>(false)
  const [answerInput, setAnswerInput] = useState('')
  const [isCorrect, setIsCorrect] = useState(false)
  const [doneWithGame, setDoneWithGame] = useState(false)
  const [misspellingCorrections, setMisspellingCorrections] = useState(0)
  const [writtenAnswerResult, setWrittenAnswerResult] =
    useState<WrittenAnswerResult>({} as WrittenAnswerResult)

  const { goalInContext } = useGoal()
  const { userCredentialsInContext } = useUserCredentials()

  const navigate = useNavigate()

  useEffect(() => {
    setAnswerInput('')
    setMisspellingCorrections(0)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [item])

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputAnswer = event.target.value

    const isCorrectSoFar = item.answer
      .toLowerCase()
      .startsWith(inputAnswer.toLowerCase())

    if (!isCorrectSoFar) {
      setMisspellingCorrections(misspellingCorrections + 1)

      if (!writtenAnswerResult.totalMisspellingCorrections) {
        writtenAnswerResult.totalMisspellingCorrections = 0
      }
      writtenAnswerResult.totalMisspellingCorrections =
        writtenAnswerResult.totalMisspellingCorrections + 1
    }

    const answerSuccessfullyCompleted =
      item.answer.toLowerCase() === inputAnswer.toLowerCase()

    setAnswerInput(inputAnswer)
    setIsCorrect(isCorrectSoFar)

    if (answerSuccessfullyCompleted) {
      setAnswerInput('')

      keepWrittenAnswerResultInState(inputAnswer)

      if (currentIndex === lastIndexOfItems) {
        saveAndFinishGame()
      } else {
        increaseCurrentItemIndex()
      }
    }
  }

  const saveAndFinishGame = async () => {
    saveResultInTaskEventCb(withPoints, writtenAnswerResult)
    setDoneWithGame(true)
  }

  const handleClear = () => {
    setAnswerInput('')
    setIsCorrect(false)
  }

  const handleNextQuestion = () => {
    keepWrittenAnswerResultInState(answerInput)

    if (currentIndex === lastIndexOfItems) {
      saveResultInTaskEventCb(withPoints, writtenAnswerResult)
      setDoneWithGame(true)
    } else {
      increaseCurrentItemIndex()
    }
  }

  const isOnLastItem = () => {
    return currentIndex === lastIndexOfItems
  }

  const keepWrittenAnswerResultInState = (currentInputAnswer: string) => {
    const points =
      item.answer.toLowerCase() === currentInputAnswer.toLowerCase() ? 1 : 0

    const writtenAnswerItem: WrittenAnswerItem = {
      id: currentIndex,
      question: item.question,
      answer: currentInputAnswer,
      correctAnswer: item.answer,
      misspellingCorrections: misspellingCorrections
        ? misspellingCorrections
        : 0,
      points: points,
    }

    if (!writtenAnswerResult.totalPoints) {
      writtenAnswerResult.totalPoints = 0
    }

    writtenAnswerResult.totalPoints = writtenAnswerResult.totalPoints + points

    if (!writtenAnswerResult.writtenAnswerItems) {
      writtenAnswerResult.writtenAnswerItems = new Map<
        number,
        WrittenAnswerItem
      >()
    }

    writtenAnswerResult.writtenAnswerItems.set(currentIndex, writtenAnswerItem)

    setWrittenAnswerResult(writtenAnswerResult)
  }

  const saveLastAnswerAndShowResult = () => {
    keepWrittenAnswerResultInState(answerInput)
    setDoneWithGame(true)
    saveResultInTaskEventCb(withPoints, writtenAnswerResult)
  }

  const resolveAnswerColor = () => {
    if (answerInput === '' || !withMisspellings) {
      return 'black'
    }

    if (isCorrect) {
      return 'green'
    }

    return 'red'
  }

  const startOver = (all: boolean, errors: boolean, misspellings: boolean) => {
    if (all) {
      playAgain(true)
    } else if (errors && misspellings) {
      const incorrectAnswerIdx = Array.from(
        writtenAnswerResult.writtenAnswerItems.values(),
      )
        .filter((item) => item.points === 0 || item.misspellingCorrections > 0)
        .map((item) => item.id)

      playAgain(false, incorrectAnswerIdx)
    } else if (errors) {
      const incorrectAnswerIdx = Array.from(
        writtenAnswerResult.writtenAnswerItems.values(),
      )
        .filter((item) => item.points === 0)
        .map((item) => item.id)

      playAgain(false, incorrectAnswerIdx)
    } else if (misspellings) {
      const misspellingAnswerIdx = Array.from(
        writtenAnswerResult.writtenAnswerItems.values(),
      )
        .filter((item) => item.points === 1 && item.misspellingCorrections > 0)
        .map((item) => item.id)

      playAgain(false, misspellingAnswerIdx)
    }
    setAnswerInput('')
    setDoneWithGame(false)
    saveResultInTaskEventCb(withPoints, writtenAnswerResult)
    setMisspellingCorrections(0)
    setWrittenAnswerResult({} as WrittenAnswerResult)
  }

  const hasIncorrectAndCorrectedAnswers = () => {
    if (!withPoints) {
      return false
    }
    return hasCorrectAnswersButWithCorrections() && hasIncorrectAnswers()
  }

  const hasCorrectAnswersButWithCorrections = () => {
    if (!withPoints) {
      return false
    }
    return Array.from(writtenAnswerResult.writtenAnswerItems.values()).some(
      (item) => item.points === 1 && item.misspellingCorrections > 0,
    )
  }

  const hasIncorrectAnswers = () => {
    if (!withPoints) {
      return false
    }

    return Array.from(writtenAnswerResult.writtenAnswerItems.values()).some(
      (item) => item.points === 0,
    )
  }

  const taskDoneNavigateBack = () => {
    if (goalInContext) {
      navigate(`/goals/open`)
    } else {
      navigate('/usertasks/list')
    }
  }

  return (
    <>
      {!doneWithGame && (
        <>
          {/* TEXT TO SPEECH */}
          {userCredentialsInContext && userCredentialsInContext.isSignedIn && (
            <>
              <div className={'text-center mb-3'}>
                <Button
                  onClick={() => setShowTextToSpeech(!showTextToSpeech)}
                  variant="secondary"
                  style={{ borderRadius: 15 }}
                >
                  <FontAwesomeIcon
                    icon={
                      showTextToSpeech ? faCircleArrowUp : faCircleArrowDown
                    }
                    size={'lg'}
                  ></FontAwesomeIcon>{' '}
                  {showTextToSpeech ? t('closeSpeech') : t('showSpeech')}
                </Button>
              </div>

              <div
                className={'mx-2'}
                style={{ display: showTextToSpeech ? 'block' : 'none' }}
              >
                <TextToSpeechComponent
                  textToSpeak={item.question}
                  label={t('question')}
                />

                <TextToSpeechComponent
                  textToSpeak={item.answer}
                  label={t('answer')}
                />
              </div>
            </>
          )}
          <StyledContainer useTaskCardBg={true}>
            <div className="d-flex flex-column mx-3">
              {withPoints && (
                <div style={{ position: 'relative' }}>
                  <h5>{t('numOfCorrectAnswers')}</h5>
                  <div
                    className="circle-number"
                    style={{
                      backgroundColor: 'green',
                    }}
                  >
                    <h4 className={'pt-2'}>
                      {writtenAnswerResult.totalPoints
                        ? writtenAnswerResult.totalPoints
                        : 0}
                    </h4>
                  </div>
                </div>
              )}
              <div className={'mt-3'}></div>
              {withMisspellings && (
                <div style={{ position: 'relative' }}>
                  <h5>{t('numOfCorrectedMisspellings')}</h5>
                  <div
                    className="circle-number"
                    style={{
                      backgroundColor: 'red',
                    }}
                  >
                    <h4 className={'pt-2'}>
                      {writtenAnswerResult.totalMisspellingCorrections
                        ? writtenAnswerResult.totalMisspellingCorrections
                        : 0}
                    </h4>
                  </div>
                </div>
              )}

              <hr />
              <div className="d-flex justify-content-center">
                <h2 className="main-small-subheader">{item.question}</h2>
              </div>
            </div>

            <InputGroup size="lg" className="mt-4">
              <Form.Control
                ref={inputRef}
                type="text"
                placeholder={t('writeAnswerPlaceholder')}
                value={answerInput}
                onChange={handleInputChange}
                className={'mx-3 mb-4'}
                style={{
                  border: '3px solid #007BFF',
                  borderRadius: '15px',
                  color: resolveAnswerColor(),
                }}
              />
              {answerInput && (
                <Button
                  className={'me-3 mb-4'}
                  variant="outline-secondary"
                  onClick={handleClear}
                  style={{ border: '3px solid #007BFF', borderRadius: '15px' }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </Button>
              )}
            </InputGroup>

            {!isOnLastItem() && (
              <div className={'my-3 text-center mx-4'}>
                <Button
                  variant="primary"
                  className={'w-100'}
                  onClick={() => handleNextQuestion()}
                >
                  {t('nextButton')}
                </Button>
              </div>
            )}
            {isOnLastItem() && (
              <div className={'text-center'}>
                <Button
                  variant="success"
                  onClick={() => saveLastAnswerAndShowResult()}
                  className="mt-4"
                >
                  {t('showResultButton')}
                </Button>
              </div>
            )}
          </StyledContainer>

          {!doneWithGame && (
            <Container className="my-4">
              <Row className="justify-content-center">
                <Col xs="auto">
                  <p>
                    {currentIndex + 1} {t('of')} {lastIndexOfItems + 1}
                  </p>
                </Col>
              </Row>
            </Container>
          )}

          <div className={'my-3'}></div>
        </>
      )}

      {/* RESULT SECTION */}
      {doneWithGame && (
        <>
          <WrittenAnswerResultsView
            withPoints={withPoints ? withPoints : false}
            writtenAnswerResult={writtenAnswerResult}
          />
          <div className={'text-center'}>
            <Button
              variant="primary"
              onClick={() => startOver(true, false, false)}
              className="mt-4 w-100"
            >
              {t('playAgainAllQuestionsButton')}
            </Button>
          </div>

          {hasIncorrectAndCorrectedAnswers() && (
            <div className={'text-center'}>
              <Button
                variant="primary"
                onClick={() => startOver(false, true, true)}
                className="mt-4 w-100"
              >
                {t('playAgainAllIncorrectAndCorrectionsAnswersButton')}
              </Button>
            </div>
          )}
          {hasIncorrectAnswers() && (
            <div className={'text-center'}>
              <Button
                variant="primary"
                onClick={() => startOver(false, true, false)}
                className="mt-4 w-100"
              >
                {t('playAgainAllIncorrectAnswersButton')}
              </Button>
            </div>
          )}
          {hasCorrectAnswersButWithCorrections() && (
            <div className={'text-center'}>
              <Button
                variant="primary"
                onClick={() => startOver(false, false, true)}
                className="mt-4 w-100"
              >
                {t('playAgainAllCorrectionsAnswersButton')}
              </Button>
            </div>
          )}
          <hr />
          <div className={'text-center'}>
            <Button
              variant="secondary"
              onClick={() => taskDoneNavigateBack()}
              className="mt-4 w-100"
            >
              {t('doneButton')}
            </Button>
          </div>
        </>
      )}
    </>
  )
}

export default WrittenAnswerInput
