import { ChangeEvent, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import LeftButtonTitleRightButton from '../../components/LeftButtonTitleRightButton'
import StyledContainer from '../../components/StyledContainer'
import UserTaskStateButtons from '../../components/UserTaskStateButtons'
import ActionButton from '../../components/buttons/ActionButton'
import InformationModal from '../../components/info/InformationModal'
import MyUserTasksInfo from '../../components/info/MyUserTasksInfo'
import { TypeOfRelation, UserTask } from '../../components/model/model'
import { useGoal } from '../../context/GoalContext'
import { useTask } from '../../context/TaskContext'
import { useUserTask } from '../../context/UserTaskContext'
import { useUserService } from '../../service/ServicesHooks'
import { hasRoleToCreateTasks } from '../../utils/UserDetailsHelper'

import { useTranslation } from 'react-i18next'
import DropdownSearchComponent, {
  DropDownItem,
} from '../../components/DropdownSearchComponent'
import ProgressBarHeader from '../../components/ProgreesBarHeader'
import SearchInputComponent from '../../components/inputs/SearchInputComponent'
import { useSelectedCategory } from '../../context/SelectedCategoryContext'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { useUserTasksContext } from '../../context/UserTaskListContext'
import '../../i18n'
import { removeDuplicates } from '../../utils/helperFunctions'
import {
  filterDoneTasks,
  filterNewOrOngoingTasks,
  sortUserTasksByEndDateNewestFirst,
  sortUserTasksByEndDateOldestFirst,
} from '../../utils/sortingFunctions'
import MyUserTaskInfoBox from './MyUserTaskInfoBox'
import './MyUserTaskInfoBox.css'

const MyUserTasksPage = () => {
  const { t } = useTranslation('MyUserTasksPage')

  const [filter, setFilter] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const userUservice = useUserService()
  const userTaskService = useUserService()

  const navigate = useNavigate()

  const { updateTaskContext } = useTask()
  const { updateUserTaskContext } = useUserTask()
  const { updateGoalContext } = useGoal()
  const { userCredentialsInContext } = useUserCredentials()
  const { userTasksInContext, setUserTasks } = useUserTasksContext()
  const { selectedCategoryInContext, updateSelectedCategoryContext } =
    useSelectedCategory()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  const userInfo = userCredentialsInContext?.userInfo
  const organizationId = userInfo?.organizationId ?? undefined

  if (selectedCategoryInContext !== null && selectedCategory === null) {
    setSelectedCategory(selectedCategoryInContext)
  }

  const fetchUserTasks = async () => {
    try {
      const userId = userInfo?.userId

      if (!userId) {
        return []
      }

      let userTasks = []

      if (userTasksInContext && userTasksInContext.length > 0) {
        userTasks = userTasksInContext
      } else {
        const response = await userUservice.getUserTasks({
          userId: userId,
        })

        if (!response.userTasks) {
          return []
        }

        userTasks = response.userTasks as UserTask[]
        setUserTasks(userTasks)
      }

      const sortedNewAndOngoingTasks = sortUserTasksByEndDateOldestFirst(
        filterNewOrOngoingTasks(userTasks),
      )
      const doneTasks = sortUserTasksByEndDateNewestFirst(
        filterDoneTasks(userTasks),
      )

      return [...sortedNewAndOngoingTasks, ...doneTasks]
    } catch (e) {
      console.error('error', e)
      return []
    }
  }

  const { data: userTasks, isLoading } = useQuery({
    queryKey: ['userTasks', { organizationId }],
    queryFn: () => fetchUserTasks(),
  })

  // FILTERING
  const filterUserTasksBySearchTextAndCategory = (userTasks: UserTask[] | undefined | null) => {
    if (!userTasks) {
      return []
    }
    return userTasks
      .filter((ut) => matchTaskNameWithSearchInput(ut.taskName))
      .filter((ut) => matchTaskWithSelectedCategory(ut.taskCategory))
  }
  

  const filteredUserTasksByStatus = useMemo(() => {
    if (!filter || filter === 'All') {
      return userTasks
    }
    return userTasks?.filter((ut) => ut.status === filter)
  }, [filter, userTasks])

  if (isLoading) {
    return <ProgressBarHeader title={t('title')} />
  }

  const matchTaskNameWithSearchInput = (taskName: string) => {
    return taskName.toLowerCase().includes(searchInput.toLowerCase())
  }

  const getCategories = (): DropDownItem[] => {
    if (!userTasks) {
      return []
    }

    const categories = removeDuplicates(userTasks.map((ut) => ut.taskCategory))
      .map((c) => c.replace('none', t('noCategory')))
      .sort()

    return categories.map((c) => {
      return { value: c, label: c }
    })
  }

  const hasUserTasks = userTasks && userTasks.length > 0

  const onChangeState = (state: string) => {
    setFilter(state)
  }

  const getTitleFontSize = () => {
    return isMobile ? '1.3rem' : '2rem'
  }

  const openNewTaskPage = () => {
    updateGoalContext(null)
    updateTaskContext(null)
    navigate(`/admin/tasks/add`)
  }

  const openUserTask = async (userTask: UserTask) => {
    updateGoalContext(null)

    //If the task is shared, we need to fetch the user task from the owner of the task
    if (userTask.typeOfRelation === TypeOfRelation.Shared) {
      const sharedUserTaskResponse =
        await userTaskService.getUserTaskByUserIdAndTaskId({
          userId: userTask.taskOwner!,
          taskId: userTask.taskId,
        })

      updateUserTaskContext(sharedUserTaskResponse.userTask)
    } else {
      updateUserTaskContext(userTask)
    }
    navigate(`/usertasks/${userTask.taskId}`)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  const handleClear = () => {
    setSearchInput('')
  }

  const handleSelectedCategory = (category: string | null) => {
    setSelectedCategory(category)
    updateSelectedCategoryContext(category) //so that when coming back to this page, the selected category is still selected
  }

  function matchTaskWithSelectedCategory(taskCategory: string): boolean {
    return (
      selectedCategory === null ||
      selectedCategory === taskCategory ||
      (selectedCategory === t('noCategory') && taskCategory === 'none')
    )
  }
  return (
    <>
      <div className={'mt-3'} />

      <div className={'mx-3'}>
        <StyledContainer>
          <LeftButtonTitleRightButton
            title={t('title')}
            titleFontSize={getTitleFontSize()}
            showLeftButton={false}
            onRightButtonClick={handleShowInfoModal}
          />

          {hasRoleToCreateTasks(userCredentialsInContext!) && (
            <div className={'text-center'}>
              <ActionButton
                text={t('newTask')}
                handleOnClick={openNewTaskPage}
                variant="primary"
              />
            </div>
          )}
        </StyledContainer>
      </div>

      {!hasUserTasks && (
        <>
          <div className="goachy-header-title text-center mt-4">
            {t('noTasksYetText')} <br />
          </div>
          <div className="text-center mb-3">{t('noTaskYetText2')}</div>
        </>
      )}
      {hasUserTasks && (
        <>
          <SearchInputComponent
            setSearchInputCb={handleInputChange}
            handleClearSearchInput={handleClear}
            searchInput={searchInput}
            placeholder={t('searchTasksText')}
          />

          <div
            className={'mx-2 mb-4'}
            style={{ position: 'relative', zIndex: 4 }}
          >
            <DropdownSearchComponent
              dropdownItems={getCategories()}
              handleSelectedItemCb={handleSelectedCategory}
              preselectedItem={selectedCategoryInContext}
            />
          </div>

          <div>
            <div className={'mb-4 mx-3'}>
              <UserTaskStateButtons
                onChangeStateCb={onChangeState}
                filteredUserTasks={filterUserTasksBySearchTextAndCategory(userTasksInContext)}
              />
            </div>

            {(!filteredUserTasksByStatus ||
              filteredUserTasksByStatus.length == 0) && (
              <div className={'text-center mb-4'}>{t('noTasksFound')}</div>
            )}

            {filterUserTasksBySearchTextAndCategory(filteredUserTasksByStatus)?.map(
              (userTask, idx) => (
                <span
                  key={idx}
                  onClick={() => openUserTask(userTask)}
                  className="cursorPointer"
                >
                  <MyUserTaskInfoBox userTask={userTask} />
                </span>
              ),
            )}
          </div>
        </>
      )}
      <InformationModal
        title={t('informationAboutTask')}
        children={<MyUserTasksInfo />}
        showInfoModal={showInfoModal}
        handleCloseInfoModal={handleCloseCloseInfoModal}
      />
    </>
  )
}
export default MyUserTasksPage
