import {
  UserTask as UserTask,
  User,
  TaskEvent,
} from '../components/model/model'
import { getEnvApiUrl } from '../envConfig'
import { AuthService } from './AuthService'

const goachyApiUrl = getEnvApiUrl()

export type UserResponse = {
  users: User[]
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export type GetUserTasksRequest = {
  userId: string
}

export type GetUserTasksResponse = {
  userTasks: UserTask[]
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export type GetUserTaskByUserIdAndTaskIdRequest = {
  userId: string
  taskId: string
}

export type GetUserTaskByUserIdAndTaskIdResponse = {
  userTask: UserTask
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export type GetUserTaskEventsRequest = {
  userId: string
  taskId: string
}
export type GetUserTaskEventsResponse = {
  taskEvents: TaskEvent[]
  status: 'SUCCESS' | 'NOT_FOUND' | 'FAILURE'
  message?: string
}

export class UserService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  public async getUsers(): Promise<UserResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing taskApiUrl')
      }

      const response = await fetch(`${goachyApiUrl}users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      const users = (await response.json()) as User[]

      return {
        users,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get users, error:', error)
      return {
        users: [],
        status: 'FAILURE',
        message: 'Failed to get users',
      }
    }
  }

  public async getUser(userId: string): Promise<UserResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing taskApiUrl')
      }

      const response = await fetch(`${goachyApiUrl}users/${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      const user = (await response.json()) as User

      return {
        users: [user],
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get user, error:', error)
      return {
        users: [],
        status: 'FAILURE',
        message: 'Failed to get user',
      }
    }
  }

  public async getUserByEmail(email: string): Promise<UserResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing taskApiUrl')
      }

      const requestUrl = `${goachyApiUrl}users?email=${email}`

      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        return {
          users: [],
          status: 'NOT_FOUND',
        }
      }

      const user = (await response.json()) as User

      return {
        users: [user],
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get user, error:', error)
      return {
        users: [],
        status: 'FAILURE',
        message: 'Failed to get user',
      }
    }
  }

  public async getUsersByOrganizationId(orgId: string): Promise<UserResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing taskApiUrl')
      }

      const requestUrl = `${goachyApiUrl}users?organizationId=${orgId}`

      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        return {
          users: [],
          status: 'NOT_FOUND',
        }
      }

      const users = (await response.json()) as User[]

      return {
        users,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get users, error:', error)
      return {
        users: [],
        status: 'FAILURE',
        message: 'Failed to get users',
      }
    }
  }

  public async getUserTasks({
    userId,
  }: GetUserTasksRequest): Promise<GetUserTasksResponse> {
    try {
      const jwtToken = await this.getToken()

      if (!goachyApiUrl) {
        throw new Error('Missing goachyApiUrl')
      }

      const requestUrl = `${goachyApiUrl}users/${userId}/usertasks`

      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        return {
          userTasks: [],
          status: 'NOT_FOUND',
        }
      }

      const userTasks = (await response.json()) as UserTask[]

      return {
        userTasks: userTasks,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.log('Failed to get user tasks, error:', error)
      return {
        userTasks: [],
        status: 'FAILURE',
        message: 'Failed to get user tasks',
      }
    }
  }

  public async getUserTaskByUserIdAndTaskId({
    userId,
    taskId,
  }: GetUserTaskByUserIdAndTaskIdRequest): Promise<GetUserTaskByUserIdAndTaskIdResponse> {
    try {
      const jwtToken = await this.getToken()

      const response = await fetch(
        `${goachyApiUrl}users/${userId}/usertasks/${taskId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      if (response.status === 404) {
        return {
          userTask: {} as UserTask,
          status: 'NOT_FOUND',
        }
      }

      const responseJson = await response.json()

      const userTask = responseJson as UserTask

      return {
        userTask,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to fetch user task, error:', error)
      return {
        userTask: {} as UserTask,
        status: 'FAILURE',
        message: 'Failed to fetch user task',
      }
    }
  }

  public async getTaskEventsByUserIdAndTaskId({
    taskId,
    userId,
  }: GetUserTaskEventsRequest): Promise<GetUserTaskEventsResponse> {
    try {
      const jwtToken = await this.getToken()

      const response = await fetch(
        `${goachyApiUrl}users/${userId}/usertasks/${taskId}/events`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      if (response.status === 404) {
        return {
          taskEvents: [],
          status: 'NOT_FOUND',
        }
      }

      const responseJson = await response.json()

      const taskEvents = responseJson as TaskEvent[]

      return {
        taskEvents,
        status: 'SUCCESS',
      }
    } catch (error) {
      console.error('Failed to fetch user task events, error:', error)
      return {
        taskEvents: [],
        status: 'FAILURE',
        message: 'Failed to fetch user task events',
      }
    }
  }

  public async updateUser(user: User): Promise<UserResponse> {
    try {
      const jwtToken = await this.getToken()

      await fetch(`${goachyApiUrl}users/${user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(user),
      })
      return {
        users: [],
        status: 'SUCCESS',
        message: 'Organization updated',
      }
    } catch (error) {
      console.error('Failed to update user, error:', error)
      return {
        users: [],
        status: 'FAILURE',
        message: 'Failed to update user',
      }
    }
  }

  public async deleteUser(userId: string): Promise<UserResponse> {
    try {
      const jwtToken = await this.getToken()

      await fetch(`${goachyApiUrl}users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })
      return {
        users: [],
        status: 'SUCCESS',
        message: 'User deleted',
      }
    } catch (error) {
      console.error('Failed to delete user, error:', error)
      return {
        users: [],
        status: 'FAILURE',
        message: 'Failed to delete user',
      }
    }
  }

  private async getToken() {
    const jwtToken = await this.authService.getJwtToken()

    if (!jwtToken) {
      throw new Error('Missing jwtToken')
    }
    return jwtToken
  }
}
