import { DropDownItem } from '../components/DropdownSearchComponent'
import {
  Goal,
  GoalType,
  Task,
  TaskDataType,
  TaskStatus,
  UserTask,
} from '../components/model/model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any): value is number => {
  return typeof value === 'number'
}

export const isNumbericString = (value: string): boolean => {
  return !isNaN(Number(value))
}

export const notNumericOrEmpty = (value: string): boolean => {
  return !isNumbericString(value) || value === ''
}

export const getNumberOrZero = (value: string): number => {
  return notNumericOrEmpty(value) ? 0 : parseInt(value)
}
export const getDropDownItem = (
  stringValue: string | null,
): DropDownItem | null => {
  if (!stringValue) {
    return null
  }

  return { value: stringValue, label: stringValue }
}

export const emptyTask = (): Task => {
  return {
    id: '',
    name: '',
    category: '',
    dataType: TaskDataType.OneTimeTaskDataType,
    organizationId: '',
    userId: '',
    htmlDescription: '',
    data: '',
    status: TaskStatus.Private,
  }
}

export const emptyGoal = (): Goal => {
  return {
    id: '',
    name: '',
    description: '',
    author: '',
    targetResult: 0,
    targetTaskCountPerWeek: 0,
    goalType: GoalType.FinishAllTasks,
  }
}

export const removeDuplicates = (items: string[]): string[] => {
  const uniqueStrings = new Set(items)
  return Array.from(uniqueStrings)
}

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)) // Random index from 0 to i
    ;[array[i], array[j]] = [array[j], array[i]] // Swap elements
  }
  return array
}

export function getLocalPartOfEmail(email: string): string {
  const atIndex = email.indexOf('@')
  if (atIndex === -1) {
    throw new Error('Invalid email address')
  }
  return email.substring(0, atIndex)
}

export function getUserTaskOwnerUsername(userTask: UserTask): string {
  if (userTask.assignedBy === userTask.assignedTo) {
    return userTask.assignedBy ? userTask.assignedBy.split('@')[0] : ''
  }
  return 'unknown'
}

export function maskEmail(email: string): string {
  const [localPart, domain] = email.split('@')
  if (!localPart || !domain) {
    throw new Error('Invalid email address')
  }

  const maskedLocalPart =
    localPart.length > 2
      ? `${localPart[0]}***${localPart[localPart.length - 1]}`
      : `${localPart[0]}*`

  return `${maskedLocalPart}@${domain}`
}
