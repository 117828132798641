import {
  UserTaskStatus,
  TaskEvent,
  TaskEventType,
} from '../components/model/model'
import { getEnvApiUrl } from '../envConfig'
import { AuthService } from './AuthService'

export interface CreateTaskEventRequest {
  userId: string
  authorId: string
  taskId: string
  eventType: TaskEventType
  organizationId: string
  status: UserTaskStatus
  comment?: string
  result?: number
  timeInSec?: string
  targetResult?: number
  targetTaskCountPerWeek?: number
}

export type CreateTaskEventResponse = {
  taskId: string
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type DeleteUserTaskEventRequest = {
  userId: string
  taskId: string
  taskEventId: string
}

export type DeleteUserTaskEventResponse = {
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}
export type UpdateTaskEventResponse = {
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

const goachyApiUrl = getEnvApiUrl()

export class TaskEventService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  async postTaskEvent(
    request: CreateTaskEventRequest,
  ): Promise<CreateTaskEventResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(`${goachyApiUrl}task-events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(request),
      })
      const taskEvent = (await response.json()) as TaskEvent

      return {
        taskId: taskEvent.id,
        status: 'SUCCESS',
        message: `Task event added, id: ${taskEvent.id}`,
      }
    } catch (error) {
      console.error('Failed to add task event, error:', error)
      return {
        taskId: '',
        status: 'FAILURE',
        message: `Failed to add task event, error: ${error}`,
      }
    }
  }

  async getTaskEventsByUserId(userId: string): Promise<TaskEvent[]> {
    return await this.getTaskEventsByQueryUrl(
      `${goachyApiUrl}task-events?userId=${userId}`,
    )
  }

  async deleteTaskEvent(request: DeleteUserTaskEventRequest): Promise<void> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      console.log('delete task event:', request)

      await fetch(
        `${goachyApiUrl}users/${request.userId}/usertasks/${request.taskId}/events/${request.taskEventId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )
    } catch (error) {
      console.error('Failed to delete task event, error:', error)
    }
  }

  async updateTaskEvent(
    taskEvent: TaskEvent,
  ): Promise<UpdateTaskEventResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(
        `${goachyApiUrl}task-events`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
          body: JSON.stringify(taskEvent),
        },
      )
      console.log('update task event response:', response)

      if (response.status !== 200) {
        console.error('Failed to update task event', taskEvent)
        throw new Error('Failed to update task event')
      }
      return {
        status: 'SUCCESS',
        message: `Task event updated, user id: ${taskEvent.id}, event task id: ${taskEvent.eventTaskId}`,
      }
    } catch (error) {
      console.error('Failed to update task event, error:', error)
      throw new Error('Failed to update task event')
    }
  }

  private async getTaskEventsByQueryUrl(
    queryUrl: string,
  ): Promise<TaskEvent[]> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(queryUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })
      const taskEvents = (await response.json()) as TaskEvent[]

      return taskEvents
    } catch (error) {
      console.error('Failed to get task events, error:', error)
      return []
    }
  }
}
