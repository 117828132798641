import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, Suspense, lazy, useRef, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ConfirmModal from '../../components/ConfirmModal'
import LeftButtonTitleRightButton from '../../components/LeftButtonTitleRightButton'
import StyledContainer from '../../components/StyledContainer'
import ActionButton from '../../components/buttons/ActionButton'
import { Goal, GoalType } from '../../components/model/model'
import { useGoal } from '../../context/GoalContext'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { UpdateGoalRequest } from '../../service/GoalService'
import { useGoalService } from '../../service/ServicesHooks'
import {
  convertToDate,
  formatDateTo_yyyyMMdd,
  mapGoalTypeToFriendlyText,
} from '../../utils/converters'
import {
  getDropDownItem,
  getNumberOrZero,
  notNumericOrEmpty,
  removeDuplicates,
} from '../../utils/helperFunctions'
import { GoalInput } from './AddGoalPage'
import { validateNumericInput } from './GoalInputValidator'
import CalenderComponent from '../../components/CalenderComponent'
import CreatableSelect from 'react-select/creatable'
import { useQuery } from 'react-query'
import {
  DropDownItem,
  mapStringsToDropDownItems,
} from '../../components/DropdownSearchComponent'

const TipTapEditor = lazy(() => import('../../components/TipTapEditor'))

export const EditGoalPage = () => {
  const { t } = useTranslation('EditGoalPage')
  const [categories, setCategories] = useState<string[]>([])

  const { goalInContext, updateGoalContext } = useGoal()
  const { userCredentialsInContext } = useUserCredentials()

  const initialCategory =
    goalInContext && goalInContext.category ? goalInContext.category : null

  const [selectedCategory, setSelectedCategory] = useState<DropDownItem | null>(
    getDropDownItem(initialCategory),
  )

  const goalService = useGoalService()

  const userId = userCredentialsInContext?.userInfo?.userId

  const { data: getUserGoalsResponse } = useQuery(
    ['userGoals', { userId }],
    () => goalService.getGoalsByUserId({ userId: userId! }),
  )

  const getExistingUserTaskCategories = (): string[] => {
    if (!getUserGoalsResponse || !getUserGoalsResponse.goals) {
      return []
    }

    const categories = removeDuplicates(
      getUserGoalsResponse.goals
        .filter((g) => g.category !== undefined)
        .map((g) => g.category as string),
    )
    return categories
  }

  const initialGoalEndDate =
    goalInContext?.endDate && goalInContext.endDate !== ''
      ? goalInContext.endDate
      : null

  const goalToEdit = goalInContext

  if (goalToEdit === null) {
    console.error('No goal in context')
    throw new Error('No goal in context')
  }

  const navigate = useNavigate()

  const [goalInput, setGoalInput] = useState<GoalInput>({
    name: goalToEdit.name,
    description: goalToEdit.description,
    targetResult: goalToEdit.targetResult
      ? goalToEdit.targetResult.toString()
      : '',
    targetTaskCountPerWeek: goalToEdit.targetTaskCountPerWeek
      ? goalToEdit.targetTaskCountPerWeek.toString()
      : '',
    category: goalToEdit.category || '',
    endDate: goalToEdit.endDate || '',
  })

  const [editorContent, setEditorContent] = useState(
    goalToEdit.description || '',
  )

  const [endDate, setEndDate] = useState<Date | null>(
    initialGoalEndDate ? convertToDate(initialGoalEndDate) : null,
  )

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false)

  const [message, setMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  const initialGoalType = useRef(goalToEdit.goalType)

  const [selectedGoalType, setSelectedGoalType] = useState<string>(
    goalToEdit.goalType,
  )

  const showAlertWithMessage = (message: string) => {
    setMessage(message)
    setShowAlert(true)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (
      userCredentialsInContext?.userInfo?.userId === undefined ||
      userCredentialsInContext?.userInfo?.organizationId === undefined
    ) {
      console.log('Cannot edit goal, no user credentials')
      return
    }

    const goalType = GoalType[selectedGoalType as keyof typeof GoalType]

    const validationResponse = validateNumericInput({
      goalInput: goalInput,
      selectedGoalType: goalType,
      errorMessage: t('invalidNumberInputErrorMessage'),
    })

    if (!validationResponse.success) {
      showAlertWithMessage(validationResponse.message)
      return
    }

    const newEndDate = endDate === null ? '' : formatDateTo_yyyyMMdd(endDate)
    const newCategory = selectedCategory?.value ? selectedCategory.value : ''

    const updateGoalRequest: UpdateGoalRequest = {
      goalId: goalToEdit.id,
      name: goalInput.name,
      description: editorContent,
      targetResult: getNumberOrZero(goalInput.targetResult),
      targetTaskCountPerWeek: getNumberOrZero(goalInput.targetTaskCountPerWeek),
      goalType: GoalType[selectedGoalType as keyof typeof GoalType],
      category: newCategory,
      endDate: newEndDate,
    }

    await goalService.updateGoal(updateGoalRequest)

    const goal: Goal = {
      id: goalToEdit.id,
      name: goalInput.name,
      description: editorContent,
      goalType: GoalType[selectedGoalType as keyof typeof GoalType],
      author: goalToEdit.author,
      targetResult: parseInt(goalInput.targetResult),
      targetTaskCountPerWeek: parseInt(goalInput.targetTaskCountPerWeek),
      category: newCategory,
      endDate: newEndDate,
    }

    updateGoalContext(goal)
    navigate('/goals/open', { state: { goal: goal } })
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setShowAlert(false)

    if (
      event.target.name === 'targetTaskCountPerWeek' ||
      event.target.name === 'targetResult'
    ) {
      const value = event.target.value
      const updatedNumericValue = notNumericOrEmpty(value)
        ? ''
        : parseInt(value)

      setGoalInput({
        ...goalInput,
        [event.target.name]: updatedNumericValue,
      })
      return
    } else {
      setGoalInput({
        ...goalInput,
        [event.target.name]: event.target.value,
      })
    }
  }

  const getAllowedGoalTypesForThisGoal = () => {
    const initType = initialGoalType.current

    switch (initType) {
      case GoalType.MeasurableGoal:
        return [GoalType.MeasurableGoal]
      case GoalType.FinishAllTasks:
        return [GoalType.FinishAllTasks]
      default:
        throw Error('Unknown goal type')
    }
  }

  const handleSelectedGoalType = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedGoalType(event.target.value)
  }

  const handleCancel = (): void => {
    navigate(-1)
  }

  const handleDeleteConfirmation = async () => {
    const response = await goalService.deleteGoal({ goalId: goalToEdit.id })

    setShowConfirmDeleteModal(false)

    if (response.status === 'SUCCESS') {
      navigate(`/goals/my`)
    } else {
      console.error('Failed to delete goal, error:', response.message)
      navigate(`/goals/my`)
    }
  }

  const handleCreateNewCategory = (item: string) => {
    const newCategory = { value: item, label: item }
    setCategories([...categories, newCategory.value])
    setSelectedCategory(newCategory)
  }

  const isMeasurableGoal = goalToEdit.goalType === GoalType.MeasurableGoal

  return (
    <>
      <div className={'mt-4'}></div>

      <StyledContainer useTaskCardBg={true}>
        <LeftButtonTitleRightButton
          title={t('changeGoalTitle')}
          leftButtonTitle={t('backButton')}
          rightButtonTitle={t('deleteButton')}
          rightButtonVariant={'danger'}
          rightButtonIcon={<FontAwesomeIcon icon={faTrashCan} size={'1x'} />}
          onLeftButtonClick={() => navigate(-1)}
          onRightButtonClick={() => setShowConfirmDeleteModal(true)}
        />

        <div className={'text-center'}>
          <ActionButton
            text={t('tasksToGoalButton')}
            handleOnClick={() =>
              navigate('/goals/addtasks', { state: { goal: goalToEdit } })
            }
            variant="primary"
          />
        </div>

        <hr />

        {/* <CreateOrEditGoalInfo title={'Ändra mål'} /> */}
        <Container>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mt-4">
                  <Form.Label className="fw-bold">
                    {t('selectGoalTypeLabel')}
                  </Form.Label>
                  {/* {Object.keys(GoalType).map((item, idx) => ( */}
                  {getAllowedGoalTypesForThisGoal().map((goalType, idx) => (
                    <Form.Check
                      key={idx}
                      className="mt-2 custom-checkbox"
                      label={mapGoalTypeToFriendlyText(goalType, t)}
                      name="tasksTypeRadios"
                      id={goalType}
                      value={goalType}
                      checked={selectedGoalType === goalType}
                      onChange={handleSelectedGoalType}
                    />
                  ))}
                </Form.Group>
                <Form.Group as={Col} md="12" className="mt-4">
                  <Form.Label className="fw-bold">{t('nameLabel')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    required
                    value={goalInput.name}
                    placeholder="Skriv namn"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="text-mid-size mt-3">
                    {t('categoryLabel')}
                  </Form.Label>
                  <CreatableSelect
                    isClearable
                    options={mapStringsToDropDownItems(
                      getExistingUserTaskCategories(),
                    )}
                    onCreateOption={handleCreateNewCategory}
                    onChange={setSelectedCategory}
                    value={selectedCategory}
                    placeholder={t('categoryPlaceholder')}
                  ></CreatableSelect>
                </Form.Group>

                <Form.Group as={Col} md="12" className="mt-4">
                  <Form.Label className="fw-bold">
                    {t('describeGoalLabel')}
                  </Form.Label>
                  <Suspense fallback={<div>Loading editor...</div>}>
                    <TipTapEditor
                      content={editorContent}
                      onChange={setEditorContent}
                    />
                  </Suspense>
                </Form.Group>

                <Form.Group as={Col} md="12" className="mt-4">
                  <Form.Label className="fw-bold">
                    {t('endDateLabel')}
                  </Form.Label>

                  <div>
                    <CalenderComponent
                      selectedDate={endDate}
                      onChange={setEndDate}
                    />
                  </div>
                </Form.Group>

                {isMeasurableGoal && (
                  <>
                    <Form.Group as={Col} md="12">
                      <Form.Label className="fw-bold mt-2">
                        {t('desiredResultLabel')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="targetResult"
                        value={goalInput.targetResult}
                        placeholder={t('desiredResultPlaceholder')}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <Form.Label className="fw-bold mt-2">
                        {t('timesPerWeekLabel')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="targetTaskCountPerWeek"
                        value={goalInput.targetTaskCountPerWeek}
                        placeholder={t('timesPerWeekLabel')}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </>
                )}

                <Form.Group as={Col} md="12">
                  <Button
                    className={'mt-4 w-100'}
                    variant="success"
                    type="submit"
                  >
                    {t('saveButton')}
                  </Button>
                </Form.Group>
                <Alert show={showAlert} variant="danger" className={'mt-4'}>
                  {message}
                </Alert>

                <div className="loginSignUpSeparator ">
                  <span className="taskGoalCardBg">{t('orSeparator')}</span>
                </div>

                <Form.Group as={Col} md="12" className="mb-4">
                  <Button
                    onClick={handleCancel}
                    variant="secondary"
                    className={'mt-4 w-100'}
                  >
                    {t('cancelAndGoBackButton')}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Container>
      </StyledContainer>
      <div className={'mb-4'}></div>
      <ConfirmModal
        show={showConfirmDeleteModal}
        onHide={() => setShowConfirmDeleteModal(false)}
        onConfirm={() => handleDeleteConfirmation()}
        title={t('deleteGoalConfirmModalTitle')}
        text={t('deleteGoalConfirmModalText')}
        confirmButtonText={t('deleteGoalConfirmButtonText')}
        cancelButtonText={t('cancelGoalConfirmButtonText')}
        variant="danger"
      />
    </>
  )
}
