import { ReactNode, createContext, useContext, useReducer } from 'react'
import { UserTask } from '../components/model/model'

type Action =
  | { type: 'SET_USER_TASKS'; userTasks: UserTask[] }
  | { type: 'ADD_USER_TASK'; userTask: UserTask }
  | { type: 'UPDATE_USER_TASK'; userTask: UserTask }
  | { type: 'REMOVE_USER_TASK'; id: string }
  | { type: 'CLEAR_USER_TASKS' }

interface UserTaskListContextType {
  userTasksInContext: UserTask[] | null
  setUserTasks: (userTasks: UserTask[]) => void
  addUserTask: (userTask: UserTask) => void
  updateUserTask: (userTask: UserTask) => void
  removeUserTask: (id: string) => void
  clearUserTasks: () => void
}

const UserTaskListContext = createContext<UserTaskListContextType | undefined>(
  undefined,
)

const userTaskReducer = (state: UserTask[], action: Action) => {
  switch (action.type) {
    case 'SET_USER_TASKS':
      return [...action.userTasks]
    case 'ADD_USER_TASK':
      return [...state, action.userTask]
    case 'UPDATE_USER_TASK': {
      const userTasks = state.map((userTask) =>
        userTask.taskId === action.userTask.taskId ? action.userTask : userTask,
      )
      return userTasks
    }
    case 'REMOVE_USER_TASK':
      return state.filter((userTask) => userTask.id !== action.id)
    case 'CLEAR_USER_TASKS':
      return []
    default:
      return state
  }
}

//custom hook to use the context
export const useUserTasksContext = (): UserTaskListContextType => {
  const context = useContext(UserTaskListContext)
  if (context === undefined) {
    throw new Error('useUserTasks must be used within a TaskProvider')
  }
  return context
}

interface UserTaskListProviderProps {
  children: ReactNode
}

export const UserTaskListProvider = ({
  children,
}: UserTaskListProviderProps) => {
  const [userTasksInContext, dispatch] = useReducer(userTaskReducer, [])

  const setUserTasks = (userTasks: UserTask[]) =>
    dispatch({ type: 'SET_USER_TASKS', userTasks })

  const addUserTask = (userTask: UserTask) =>
    dispatch({ type: 'ADD_USER_TASK', userTask })

  const updateUserTask = (userTask: UserTask) =>
    dispatch({ type: 'UPDATE_USER_TASK', userTask })

  const removeUserTask = (id: string) =>
    dispatch({ type: 'REMOVE_USER_TASK', id })

  const clearUserTasks = () => dispatch({ type: 'CLEAR_USER_TASKS' })

  return (
    <UserTaskListContext.Provider
      value={{
        userTasksInContext,
        setUserTasks,
        addUserTask,
        updateUserTask,
        removeUserTask,
        clearUserTasks,
      }}
    >
      {children}
    </UserTaskListContext.Provider>
  )
}
