import {
  faCalendarPlus,
  faLeftLong,
  faListCheck,
  faScaleUnbalancedFlip,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, Fragment, Suspense, lazy, useState } from 'react'
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import CreatableSelect from 'react-select/creatable'
import AddEventToCalendar from '../../components/AddEventToCalendar'
import CalenderComponent from '../../components/CalenderComponent'
import {
  DropDownItem,
  mapStringsToDropDownItems,
} from '../../components/DropdownSearchComponent'
import LeftButtonTitleRightButton from '../../components/LeftButtonTitleRightButton'
import ProgressSpinner from '../../components/ProgressSpinner'
import StyledContainer from '../../components/StyledContainer'
import AddGoalInfo from '../../components/info/AddGoalInfo'
import InformationModal from '../../components/info/InformationModal'
import { Goal, GoalType } from '../../components/model/model'
import { useGoal } from '../../context/GoalContext'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { CreateGoalRequest } from '../../service/GoalService'
import { useGoalService } from '../../service/ServicesHooks'
import {
  formatDateTo_yyyyMMdd,
  mapGoalTypeToFriendlyText,
} from '../../utils/converters'
import {
  getNumberOrZero,
  notNumericOrEmpty as isNonNumericOrEmpty,
  removeDuplicates,
} from '../../utils/helperFunctions'
import { validateNumericInput } from './GoalInputValidator'

const TipTapEditor = lazy(() => import('../../components/TipTapEditor'))

export type GoalInput = {
  name: string
  description: string
  targetResult: string
  targetTaskCountPerWeek: string
  category?: string
  endDate?: string
}

export const AddGoalPage = () => {
  const { t } = useTranslation('AddGoalPage')
  const { updateGoalContext } = useGoal()
  const [message, setMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showAddToCalendar, setShowAddToCalendar] = useState(false)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [goalInput, setGoalInput] = useState<GoalInput>({
    name: '',
    description: '',
    targetResult: '',
    targetTaskCountPerWeek: '',
  })

  const [editorContent, setEditorContent] = useState('')

  const [selectedGoalType, setSelectedGoalType] = useState<string>(
    {} as GoalType,
  )
  const [workInProgress, setWorkInProgress] = useState(false)
  const [initialGoalTypeInputDone, setInitialGoalTypeInputDone] =
    useState(false)
  const [categories, setCategories] = useState<string[]>([])
  const [selectedCategory, setSelectedCategory] = useState<DropDownItem | null>(
    null,
  )

  const { userCredentialsInContext } = useUserCredentials()

  const goalService = useGoalService()

  const navigate = useNavigate()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const userId = userCredentialsInContext?.userInfo?.userId

  const { data: getUserGoalsResponse } = useQuery(
    ['userGoals', { userId }],
    () => goalService.getGoalsByUserId({ userId: userId! }),
  )

  const getExistingUserTaskCategories = (): string[] => {
    if (!getUserGoalsResponse || !getUserGoalsResponse.goals) {
      return []
    }

    const categories = removeDuplicates(
      getUserGoalsResponse.goals
        .filter((g) => g.category !== undefined)
        .map((g) => g.category as string),
    )
    return categories
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setWorkInProgress(true)

    if (
      userCredentialsInContext?.userInfo?.userId === undefined ||
      userCredentialsInContext?.userInfo?.organizationId === undefined
    ) {
      console.log('Cannot save goal, no user credentials')
      return
    }

    const goalType = GoalType[selectedGoalType as keyof typeof GoalType]

    const validationResponse = validateNumericInput({
      goalInput: goalInput,
      selectedGoalType: goalType,
      errorMessage: t('invalidNumberInputErrorMessage'),
    })

    if (!validationResponse.success) {
      setWorkInProgress(false)
      showAlertWithMessage(validationResponse.message)
      return
    }

    const targetTaskCountPerWeek = getNumberOrZero(
      goalInput.targetTaskCountPerWeek,
    )

    if (editorContent) {
      goalInput.description = editorContent
    }

    const targetResult = getNumberOrZero(goalInput.targetResult)

    const createGoalRequest: CreateGoalRequest = {
      name: goalInput.name,
      description: goalInput.description,
      author: userCredentialsInContext.userInfo?.userId,
      organizationId: userCredentialsInContext.userInfo?.organizationId,
      goalType: goalType,
      targetTaskCountPerWeek: targetTaskCountPerWeek,
      targetResult: targetResult,
      category: goalInput.category ? goalInput.category : undefined,
      endDate: endDate ? formatDateTo_yyyyMMdd(endDate) : undefined,
    }

    const response = await goalService.addGoal(createGoalRequest)

    const goal: Goal = {
      ...createGoalRequest,
      id: response.goalId!,
      goalType: goalType,
    }

    updateGoalContext(goal)
    navigate('/goals/open')
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()

    setShowAlert(false)

    if (
      event.target.name === 'targetTaskCountPerWeek' ||
      event.target.name === 'targetResult'
    ) {
      const value = event.target.value
      const updatedNumericValue: string | number = isNonNumericOrEmpty(value)
        ? ''
        : parseInt(value)
      setGoalInput({
        ...goalInput,
        [event.target.name]: updatedNumericValue,
      })
    } else {
      setGoalInput({
        ...goalInput,
        [event.target.name]: event.target.value,
      })
    }
  }

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  const handleCancel = (): void => {
    navigate(-1)
  }

  const handleSelectedGoalType = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedGoalType(event.target.value)
    setInitialGoalTypeInputDone(true)
  }

  const showAlertWithMessage = (message: string) => {
    setMessage(message)
    setShowAlert(true)
  }

  const showMeasurableGoalFields = selectedGoalType === GoalType.MeasurableGoal

  const getMarginForThisPage = () => {
    return isMobile ? 'mx-0' : 'mx-3'
  }

  const handleCloseAddToCalendar = () =>
    setShowAddToCalendar(!showAddToCalendar)

  const handleCreateNewCategory = (item: string) => {
    const newCategory = { value: item, label: item }
    setCategories([...categories, newCategory.value])
    setSelectedCategory(newCategory)
  }

  if (workInProgress) {
    return <ProgressSpinner />
  }

  return (
    <>
      <div className={'mt-3'}></div>
      <StyledContainer useTaskCardBg={true}>
        <div className={getMarginForThisPage()}>
          <LeftButtonTitleRightButton
            title={t('newGoalTitle')}
            onLeftButtonClick={() => navigate(-1)}
            leftButtonTitle={t('backButton')}
            leftButtonIcon={<FontAwesomeIcon icon={faLeftLong} />}
            onRightButtonClick={handleShowInfoModal}
          />

          <hr />
          <Row className="justify-content-md-center mx-2">
            <Col md={12}>
              <Form onSubmit={handleSubmit}>
                <Form.Group className={'mt-4'}>
                  <Form.Label className="text-mid-size">
                    {t('selectGoalType')}
                  </Form.Label>

                  {Object.keys(GoalType).map((item, idx) => (
                    <Fragment key={idx}>
                      <Container>
                        <Row>
                          <Col xs="auto">
                            <Form.Check
                              key={idx}
                              className="my-2 custom-checkbox"
                              label={
                                <span>
                                  {item === GoalType.FinishAllTasks ? (
                                    <FontAwesomeIcon
                                      icon={faListCheck}
                                      size={'lg'}
                                      className={'mx-2'}
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faScaleUnbalancedFlip}
                                      size={'lg'}
                                      className={'mx-2'}
                                    />
                                  )}{' '}
                                  {mapGoalTypeToFriendlyText(item, t)}
                                </span>
                              }
                              name="tasksTypeRadios"
                              id={item}
                              value={item}
                              checked={selectedGoalType === item}
                              onChange={handleSelectedGoalType}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Fragment>
                  ))}
                </Form.Group>

                {initialGoalTypeInputDone && (
                  <>
                    <Form.Group as={Col} md="12" className="mt-4">
                      <Form.Label className="text-mid-size">
                        {t('nameLabel')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        required
                        value={goalInput.name}
                        placeholder={t('writeName')}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="text-mid-size mt-3">
                        {t('categoryLabel')}
                      </Form.Label>
                      <CreatableSelect
                        isClearable
                        options={mapStringsToDropDownItems(
                          getExistingUserTaskCategories(),
                        )}
                        onCreateOption={handleCreateNewCategory}
                        onChange={setSelectedCategory}
                        value={selectedCategory}
                        placeholder={t('categoryPlaceholder')}
                      ></CreatableSelect>
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <Form.Label className="text-mid-size mt-3">
                        {t('describeGoal')}
                      </Form.Label>

                      <Suspense fallback={<div>Loading editor...</div>}>
                        <TipTapEditor
                          content={editorContent}
                          onChange={setEditorContent}
                        />
                      </Suspense>
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                      <Form.Label className="text-mid-size mt-2">
                        {t('endDateLabel')}
                      </Form.Label>
                      <div>
                        <CalenderComponent
                          selectedDate={endDate}
                          onChange={setEndDate}
                        />
                      </div>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="12">
                <Form.Label className="fw-bold mt-2">Slutdatum</Form.Label>
                <div>
                  <CalenderComponent
                    selectedDate={getSelectedDate()}
                    onChange={handleEndDateChange}
                  />
                </div>
              </Form.Group> */}
                    <hr />
                    {showMeasurableGoalFields && (
                      <>
                        <Form.Group as={Col} md="12" className="mt-4">
                          <Form.Label className="text-mid-size">
                            {t('timesPerWeekLabel')}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="targetTaskCountPerWeek"
                            value={goalInput.targetTaskCountPerWeek}
                            placeholder={t('minimumTimesPerWeekPlaceholder')}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mt-4">
                          <Form.Label className="text-mid-size">
                            {t('desiredResultLabel')}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="targetResult"
                            value={goalInput.targetResult}
                            placeholder={t('desiredResultLabel')}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </>
                    )}
                    {!isMobile && (
                      <Form.Group as={Col} md="12">
                        <Button
                          onClick={() =>
                            setShowAddToCalendar(!showAddToCalendar)
                          }
                          variant={'secondary'}
                          className={'mt-4 w-100'}
                          // className="mb-3 me-md-4 me-4 w-30"
                          style={{ maxHeight: '40px' }}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faCalendarPlus}
                              size={'1x'}
                            />
                          </span>
                          <span className={'d-none d-md-inline-block ms-2'}>
                            {' '}
                            {t('addReminderToCalendar')}
                          </span>
                        </Button>
                      </Form.Group>
                    )}
                    <Form.Group as={Col} md="12">
                      <Button
                        className={'mt-4 w-100'}
                        variant="success"
                        type="submit"
                      >
                        {t('submitButton')}
                      </Button>
                    </Form.Group>
                    <Alert show={showAlert} variant="danger" className={'mt-4'}>
                      {message}
                    </Alert>
                    <div className="loginSignUpSeparator ">
                      <span className="taskGoalCardBg">{t('orSeparator')}</span>
                    </div>
                  </>
                )}
                <Form.Group as={Col} md="12" className="mb-4">
                  <Button
                    onClick={handleCancel}
                    variant="secondary"
                    className={'mt-4 w-100'}
                  >
                    {t('cancelButton')}
                  </Button>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </div>
      </StyledContainer>

      {showAddToCalendar && (
        <AddEventToCalendar
          eventTitle={goalInput.name}
          description={goalInput.description ? goalInput.description : ''}
          endDate={null}
          showAddEventToCalendarButton={showAddToCalendar}
          handleCloseAddTaskToCal={handleCloseAddToCalendar}
        />
      )}

      <InformationModal
        title={t('createNewGoalModalTitle')}
        children={<AddGoalInfo />}
        showInfoModal={showInfoModal}
        handleCloseInfoModal={handleCloseCloseInfoModal}
      />
      <div className={'mb-4'}></div>
    </>
  )
}
