import { Button, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import ChangeLanguageComponent from '../components/ChangeLanguageComponent'
import StyledContainer from '../components/StyledContainer'
import { useUserCredentials } from '../context/UserCredentialsContext'
import GoachyInfoItem from './GoachyInfoItem'


const AboutPage = () => {
  const { t } = useTranslation('AboutPage')

  const { userCredentialsInContext } = useUserCredentials()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const subHeaderClass = isMobile ? 'main-small-subheader' : 'main-subheader'

  const navigate = useNavigate()

  return (
    <>
      <div className={'mt-3'} />

      <div>
        <StyledContainer>
          <div className={`${subHeaderClass} text-center my-4`}>
            <span className="trademark">®</span>GOACHY{' '}
            {t('titleMeasureableGoals')}
          </div>

          <ChangeLanguageComponent />

          {!userCredentialsInContext?.isSignedIn && (
            <div className={'text-center'}>
              <Button onClick={() => navigate('/login/user/123')}>
                {t('getStartedButton')}
              </Button>
            </div>
          )}
        </StyledContainer>

        {/* Youtube video about Goachy - it is slow to load so consider to move it to another page */}
        {/* <div
          className="youtube-container my-4"
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: 0,
            overflow: 'hidden',
            maxWidth: '100%',
          }}
        >
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src="https://www.youtube.com/embed/tDlXOVenveA"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div> */}

        <hr />


        <Container>
          <Row className="justify-content-md-center">
            <Col md={10}>
              <GoachyInfoItem
                title={t('goalOrientedLearning')}
                text={t('setGoalsForYourEducation')}
              />
              <GoachyInfoItem
                title={t('goalDrivenDevelopment')}
                text={t('letGoalsDriveDevelopment')}
              />
              <GoachyInfoItem
                title={t('individualGoals')}
                text={t('setUpIndividualGoalsForYourDevelopment')}
              />
              <GoachyInfoItem
                title={t('myTasksTitle')}
                text={t('myTasksText')}
              />
              <GoachyInfoItem
                title={t('ownResponsibility')}
                text={t('takeResponsibilityForYourTasks')}
              />
            </Col>
          </Row>

          <hr />

          <div className={'mt-3'} />
          <Container className="mb-4">
          <Row className="justify-content-md-center">
            <Col md={10}>
              <StyledContainer>
                <p className={'px-4'}>{t('innovativeAppDescription')}</p>
                <p className={'px-4'}>{t('appCapabilities')}</p>
                <p className={'px-4'}>{t('detailedTaskCreation')}</p>
              </StyledContainer>
            </Col>
          </Row>
        </Container>
          <Container>
            <Row className="justify-content-md-center">
              <Col md={10}>
                <StyledContainer>
                  <p className={'px-4'}>{t('coreFeatureDescription')}</p>
                </StyledContainer>
              </Col>
            </Row>
          </Container>

          <div className={'mt-3'} />
          <Container>
            <Row className="justify-content-md-center">
              <Col md={10}>
                <StyledContainer>
                  <p className={'px-4'}>{t('engagementToolsDescription')}</p>
                </StyledContainer>
              </Col>
            </Row>
          </Container>
        </Container>

        <hr />

        <footer className="text-center">
          <p>&copy; 2024 GOACHY®. All rights reserved.</p>
          <p>
            For contact or support, email us at:{' '}
            <a href="mailto:info@goachy.com">info@goachy.com</a>
          </p>
          <p>
            <a href="https://www.app-privacy-policy.com/live.php?token=GhTMFqZdL6dv92Gho2qP4S5u6o44szaI">
              Goachy Privacy policy
            </a>
          </p>
          <p>
            <a href="/terms">Goachy Terms and conditions</a>
          </p>
        </footer>
      </div>
    </>
  )
}
export default AboutPage
