import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import LeftButtonTitleRightButton from '../../components/LeftButtonTitleRightButton'
import ProgressBarHeader from '../../components/ProgreesBarHeader'
import StyledContainer from '../../components/StyledContainer'
import ActionButton from '../../components/buttons/ActionButton'
import InformationModal from '../../components/info/InformationModal'
import MyGoalsInfo from '../../components/info/MyGoalsInfo'
import { Goal } from '../../components/model/model'
import { useGoal } from '../../context/GoalContext'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { useGoalService } from '../../service/ServicesHooks'
import { hasRoleToCreateGoals } from '../../utils/UserDetailsHelper'
import { sortGoalsByEndDateOldestFirst } from '../../utils/sortingFunctions'
import MyGoalInfoBox from './MyGoalInfoBox'
import DropdownSearchComponent, {
  DropDownItem,
} from '../../components/DropdownSearchComponent'
import { removeDuplicates } from '@tiptap/react'

const MyGoalsPage = () => {
  const { t } = useTranslation('MyGoalsPage')
  const { updateGoalContext } = useGoal()
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const { userCredentialsInContext } = useUserCredentials()
  const [showInfoModal, setShowInfoModal] = useState(false)
  const navigate = useNavigate()

  const userId = userCredentialsInContext!.userInfo?.userId

  const goalService = useGoalService()

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  const { data: getUserGoalsResponse, isLoading: isLoadingUserGoals } =
    useQuery(['userGoals', { userId }], () =>
      goalService.getGoalsByUserId({ userId: userId! }),
    )

  if (isLoadingUserGoals) {
    return <ProgressBarHeader title={t('myGoalsTitle')} />
  }

  const myGoals = getUserGoalsResponse?.goals

  const openGoal = (goal: Goal) => {
    updateGoalContext(goal)
    navigate('/goals/open')
  }

  const handleSelectedCategory = (category: string | null) => {
    setSelectedCategory(category)
  }

  function matchGoalWithSelectedCategory(
    goalCategory: string | undefined,
  ): boolean {
    return selectedCategory === null || selectedCategory === goalCategory
  }

  const getCategories = (): DropDownItem[] => {
    if (!myGoals) {
      return []
    }

    const categories = removeDuplicates(
      myGoals
        .filter((g) => g.category !== undefined)
        .map((g) => g.category as string),
    )

    return categories.map((c) => {
      return { value: c, label: c }
    })
  }

  return (
    <>
      <div className={'mt-3'} />

      <div className={'mx-3'}>
        <StyledContainer>
          <LeftButtonTitleRightButton
            title={t('myGoalsTitle')}
            showLeftButton={false}
            leftButtonIcon={<FontAwesomeIcon icon={faLeftLong} />}
            onRightButtonClick={handleShowInfoModal}
          />

          {hasRoleToCreateGoals(userCredentialsInContext!) && (
            <div className={'text-center'}>
              <ActionButton
                text={t('newGoalButton')}
                handleOnClick={() => navigate(`/goals/add`)}
                variant="primary"
              />
            </div>
          )}
        </StyledContainer>
      </div>

      <div className={'mb-3'} />

      <>
        {!myGoals ||
          (myGoals.length === 0 && (
            <>
              <div className="goachy-header-title text-center mt-4">
                {t('noGoalsMessage')}
              </div>
              <div className="text-center mb-3">{t('addFirstGoalPrompt')}</div>
            </>
          ))}

        {myGoals && myGoals.length > 0 && (
          <div
            className={'mx-2 mb-4'}
            style={{ position: 'relative', zIndex: 4 }}
          >
            <DropdownSearchComponent
              dropdownItems={getCategories()}
              handleSelectedItemCb={handleSelectedCategory}
            />
          </div>
        )}

        {sortGoalsByEndDateOldestFirst(myGoals)
          ?.filter((g) => matchGoalWithSelectedCategory(g.category))
          .map((goal, idx) => (
            <div
              key={idx}
              className={'cursorPointer'}
              onClick={() => openGoal(goal)}
            >
              <MyGoalInfoBox goal={goal} />
            </div>
          ))}
      </>

      <InformationModal
        title={t('myGoalsTitle')}
        children={<MyGoalsInfo />}
        showInfoModal={showInfoModal}
        handleCloseInfoModal={handleCloseCloseInfoModal}
      />
    </>
  )
}

export default MyGoalsPage
