import {
  SignInInput,
  SignInOutput,
  resetPassword,
  confirmResetPassword,
  signIn,
  signUp,
  confirmSignUp,
  resendSignUpCode,
  signOut,
  fetchAuthSession,
  deleteUser,
} from 'aws-amplify/auth'
import { Amplify } from 'aws-amplify'
import { getEnvUserPoolClientId, getEnvUserPoolId } from '../envConfig'

export interface AuthConfirmSignUpResponse {
  isSignUpComplete: boolean
  isUserNotFound?: boolean
  isWrongConfirmationCode?: boolean
}
export interface AuthConfirmResetPasswordResponse {
  isPasswordResetConfirmed: boolean
  isInvalidEmail?: boolean
}

interface AmplifyAuthError extends Error {
  code?: string
}

const userPoolId = getEnvUserPoolId()
const userPoolClientId = getEnvUserPoolClientId()


Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: userPoolId,
      userPoolClientId: userPoolClientId,
    },
  },
})

export class AuthService {
  async login(email: string, password: string): Promise<SignInOutput> {
    const input: SignInInput = {
      username: email,
      password: password,
    }
    const signInOutput = await signIn(input)
    return signInOutput
  }

  async signOut() {
    try {
      console.log('Sign out authenticated user')
      const result = await signOut()
      return result
    } catch (error) {
      console.log('Failed to sign out user', error)
      throw error
    }
  }
  async getJwtToken(): Promise<string | undefined> {
    const authSession = await fetchAuthSession()
    return Promise.resolve(authSession.tokens?.idToken?.toString())
  }

  async getCurrentSession() {
    try {
      const session = await fetchAuthSession()
      return session
    } catch (error) {
      console.error('Error getting current session:', error)
      return null
    }
  }

  async signUpRequest(email: string, password: string) {
    try {
      return await signUp({
        username: email,
        password: password,
      })
    } catch (error) {
      console.log('Failed to sign up user', error)
      throw error
    }
  }

  async confirmSignUp(
    email: string,
    code: string,
  ): Promise<AuthConfirmSignUpResponse> {
    try {
      const response = await confirmSignUp({
        username: email,
        confirmationCode: code,
      })
      return Promise.resolve({
        isSignUpComplete: response.isSignUpComplete,
      })
    } catch (error) {
      const authError = error as AmplifyAuthError

      if (authError.name) {
        if (authError.name === 'UserNotFoundException') {
          return Promise.resolve({
            isSignUpComplete: false,
            isUserNotFound: true,
          })
        }
      }
      if (authError.name === 'CodeMismatchException') {
        console.log('Wrong confirmation code')
        return Promise.resolve({
          isSignUpComplete: false,
          isWrongConfirmationCode: true,
        })
      }
      throw error
    }
  }

  async resendSignUpCode(email: string) {
    await resendSignUpCode({
      username: email,
    })
  }

  async resetUserPassword(email: string) {
    console.log(`Reset user password, for user: ${email}`)
    try {
      const result = await resetPassword({ username: email })
      return result
    } catch (error) {
      console.log('Failed to reset user password', error)
      throw error
    }
  }

  async confirmResetPassword(
    email: string,
    code: string,
    newPassword: string,
  ): Promise<AuthConfirmResetPasswordResponse> {
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: newPassword,
      })
      return Promise.resolve({
        isPasswordResetConfirmed: true,
      })
    } catch (error) {
      const authError = error as AmplifyAuthError

      if (authError.name) {
        if (authError.name === 'InvalidPasswordException') {
          console.log('Password does not meet the requirements')
          return Promise.resolve({
            isPasswordResetConfirmed: false,
            isInvalidEmail: true,
          })
        }
      }
      console.log('Failed to confirm password reset', error)
      throw error
    }
  }

  async deleteUser() {
    try {
      console.log('Delete authenticated user')
      await deleteUser()
    } catch (error) {
      console.log('Failed to delete user', error)
      throw error
    }
  }
}
