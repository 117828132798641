import { useState } from 'react'

// Custom hook for toggling hamburger menu
const useToggleMenu = (): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }
  return [isOpen, toggleMenu]
}

export default useToggleMenu
