import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import LeftButtonTitleRightButton from '../../../components/LeftButtonTitleRightButton'
import StyledContainer from '../../../components/StyledContainer'
import {
  QuestionAndAnswerData,
  Task,
  TaskEventType,
  UserTaskStatus,
} from '../../../components/model/model'
import { useUserCredentials } from '../../../context/UserCredentialsContext'
import { useTaskEventService } from '../../../service/ServicesHooks'
import WrittenAnswerInput, { WrittenAnswerResult } from './WrittenAnswerInput'

const WrittenAnswerStartPage = () => {
  const { t } = useTranslation('WrittenAnswerPage')
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0)
  const [taskItems, setTaskItems] = useState<QuestionAndAnswerData[]>([])
  const [originalTaskItems, setOriginalTaskItems] = useState<
    QuestionAndAnswerData[]
  >([])

  const location = useLocation()
  const task = location.state.task as Task
  const withPoints = location.state.withPoints as boolean
  const withMisspellings = location.state.withMisspellings as boolean
  // const taskItems = JSON.parse(task.data) as QuestionAndAnswerData[]

  const navigate = useNavigate()

  const { taskId } = useParams()
  const taskEventService = useTaskEventService()

  const { userCredentialsInContext } = useUserCredentials()

  useEffect(() => {
    if (task.data) {
      setTaskItems(JSON.parse(task.data) as QuestionAndAnswerData[])
      setOriginalTaskItems(JSON.parse(task.data) as QuestionAndAnswerData[])
    }
  }, [task.data])

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => goToNextItem(),
    onSwipedRight: () => goToPreviousItem(),
    // Configure to your needs:
    trackMouse: true,
    preventScrollOnSwipe: true,
  })

  if (!taskId) {
    throw new Error('taskId is undefined')
  }

  const goToNextItem = () => {
    if (currentItemIndex < taskItems!.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1)
    }
  }

  const goToPreviousItem = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1)
    }
  }

  const createCommentFromResult = (
    withPoints: boolean,
    result: WrittenAnswerResult,
  ) => {
    const questionAndAnswerListHtml = Array.from(
      result.writtenAnswerItems.values(),
    )
      .map((item, index) =>
        t('resultTextWithoutScore', {
          questionNumber: index + 1,
          questionText: item.question,
          answerText: item.answer,
          correctAnswerText: item.correctAnswer,
        }),
      )
      .join('')

    if (withPoints) {
      return t('scoreResultText', {
        totalPoints: result.totalPoints,
        maxPoints: result.writtenAnswerItems.size,
        totalMisspellingCorrections: result.totalMisspellingCorrections || 0,
      })
    } else {
      return `${questionAndAnswerListHtml}`
    }
  }

  const saveResultInTaskEvent = async (
    withPoints: boolean,
    result: WrittenAnswerResult,
  ) => {
    if (
      !userCredentialsInContext?.userInfo?.userId ||
      !userCredentialsInContext?.userInfo?.organizationId
    ) {
      console.error('No user credentials, cannot save result')
      throw new Error('No user credentials, cannot save result')
    }

    const totalPoints = withPoints ? result.totalPoints : undefined

    await taskEventService.postTaskEvent({
      taskId: taskId,
      authorId: userCredentialsInContext.userInfo?.userId,
      userId: task.userId,
      organizationId: userCredentialsInContext.userInfo?.organizationId,
      comment: createCommentFromResult(withPoints, result),
      status: UserTaskStatus.Ongoing,
      eventType: TaskEventType.TaskWorkCompleted,
      result: totalPoints,
      //TODO: add time spent
    })
  }

  const isLastItem = currentItemIndex === taskItems!.length - 1

  const increaseCurrentItemIndex = () => {
    if (!isLastItem) {
      setCurrentItemIndex(currentItemIndex + 1)
    }
  }

  const playAgain = (allQuestions: boolean, indexes?: number[]) => {
    if (allQuestions) {
      setTaskItems(originalTaskItems)
      setCurrentItemIndex(0)
      return
    }
    // Filter allTasks based on indexes
    const newTasks = taskItems.filter((_, index) => indexes!.includes(index))

    // Set the new tasks
    setTaskItems(newTasks)
    setCurrentItemIndex(0)
  }

  return (
    <>
      <div className="my-3"></div>
      <StyledContainer>
        <LeftButtonTitleRightButton
          title={task.name}
          showRightButton={false}
          onLeftButtonClick={() => navigate(-1)}
          leftButtonTitle={t('back')}
          leftButtonIcon={<FontAwesomeIcon icon={faLeftLong} />}
        />
        <div className="text-center">
          {task.htmlDescription && (
            <>
              <hr></hr>
              <div dangerouslySetInnerHTML={{ __html: task.htmlDescription }} />
            </>
          )}
        </div>
      </StyledContainer>

      <div className={'mt-4'}></div>
      <div {...swipeHandlers}>
        {taskItems && taskItems[currentItemIndex] && (
          <WrittenAnswerInput
            item={taskItems![currentItemIndex]}
            currentIndex={currentItemIndex}
            lastIndexOfItems={taskItems!.length - 1}
            increaseCurrentItemIndex={increaseCurrentItemIndex}
            withPoints={withPoints}
            withMisspellings={withMisspellings}
            playAgain={playAgain}
            saveResultInTaskEventCb={saveResultInTaskEvent}
          />
        )}
      </div>

      <div className={'mb-4'}></div>
    </>
  )
}

export default WrittenAnswerStartPage
