import { useMemo } from 'react'
import { TaskService } from './TaskService'
import { AuthService } from './AuthService'
import { OrganizationService } from './OrganizationService'
import { UserService } from './UserService'
import { TaskEventService } from './TaskEventService'
import { UserTaskService } from './UserTaskService'
import { GoalService } from './GoalService'
import { AccountService as UserAccountService } from './AccountService'
import { TextToSpeechService } from './TextToSpeechService'
import { OrganizationTaskService } from './OrganizationTaskService'

const authService = new AuthService()

export const useTaskService = () => {
  const service = useMemo(() => {
    return new TaskService(authService)
  }, [])
  return service
}

export const useAuthService = () => {
  const service = useMemo(() => {
    return authService
  }, [])
  return service
}

export const useOrganizationService = () => {
  const service = useMemo(() => {
    return new OrganizationService(authService)
  }, [])
  return service
}

export const useOrganizationTaskService = () => {
  const service = useMemo(() => {
    return new OrganizationTaskService()
  }, [])
  return service
}

export const useUserService = () => {
  const service = useMemo(() => {
    return new UserService(authService)
  }, [])
  return service
}

export const useTaskEventService = () => {
  const service = useMemo(() => {
    return new TaskEventService(authService)
  }, [])
  return service
}

export const useUserTaskService = () => {
  const service = useMemo(() => {
    return new UserTaskService(authService)
  }, [])
  return service
}

export const useGoalService = () => {
  const service = useMemo(() => {
    return new GoalService(authService)
  }, [])
  return service
}

export const useTextToSpeechService = () => {
  const service = useMemo(() => {
    return new TextToSpeechService(authService)
  }, [])
  return service
}

export const useUserAccountService = () => {
  const userService = useUserService()
  const goalService = useGoalService()
  const taskService = useTaskService()

  const service = useMemo(() => {
    return new UserAccountService(
      authService,
      userService,
      goalService,
      taskService,
    )
  }, [userService, goalService, taskService])
  return service
}
