import { faInfoCircle, faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

export interface LeftButtonTitleRightButtonProps {
  title: string
  titleFontSize?: string
  onLeftButtonClick?: () => void
  leftButtonTitle?: string
  leftButtonIcon?: React.ReactNode
  leftButtonVariant?: string
  onRightButtonClick?: () => void
  rightButtonTitle?: string
  rightButtonIcon?: React.ReactNode
  rightButtonVariant?: string
  showLeftButton?: boolean
  showRightButton?: boolean
}

const LeftButtonTitleRightButton = ({
  title,
  titleFontSize,
  onLeftButtonClick,
  leftButtonTitle,
  leftButtonIcon,
  leftButtonVariant,
  onRightButtonClick,
  rightButtonTitle,
  rightButtonIcon,
  rightButtonVariant,
  showLeftButton = true,
  showRightButton = true,
}: LeftButtonTitleRightButtonProps) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const subHeaderClass = isMobile ? 'main-small-subheader' : 'main-subheader'

  const getLeftButtonTitle = () => {
    return leftButtonTitle ? leftButtonTitle : 'Tillbaka'
  }

  const getLeftButtonIcon = () => {
    return leftButtonIcon ? (
      leftButtonIcon
    ) : (
      <FontAwesomeIcon icon={faLeftLong} size={'1x'} className={''} />
    )
  }

  const getRightButtonTitle = () => {
    return rightButtonTitle ? rightButtonTitle : 'Info'
  }

  const getRightButtonIcon = () => {
    return rightButtonIcon ? (
      rightButtonIcon
    ) : (
      <FontAwesomeIcon icon={faInfoCircle} size={'1x'} />
    )
  }

  const getLeftButtonVariant = () => {
    return leftButtonVariant ? leftButtonVariant : 'secondary'
  }

  const getRightButtonVariant = () => {
    return rightButtonVariant ? rightButtonVariant : 'secondary'
  }

  return (
    <>
      <Container>
        <Row>
          <Col xs={3}>
            {showLeftButton && (
              <Button
                onClick={onLeftButtonClick}
                variant={getLeftButtonVariant()}
                className="mb-3 w-30"
                style={{ maxHeight: '40px' }}
              >
                <span>{getLeftButtonIcon()}</span>
                <span className={'d-none d-md-inline-block ms-2'}>
                  {getLeftButtonTitle()}
                </span>
              </Button>
            )}
          </Col>
          <Col xs={6}>
            {titleFontSize ? (
              <div
                className={`mb-2 text-center fw-bold ${subHeaderClass}`}
                style={{ fontSize: titleFontSize }}
              >
                {title}
              </div>
            ) : (
              <div className={`mb-2 text-center fw-bold ${subHeaderClass}`}>
                {title}
              </div>
            )}
          </Col>
          <Col xs={3} className={'d-flex justify-content-end'}>
            {showRightButton && (
              <Button
                onClick={onRightButtonClick}
                variant={getRightButtonVariant()}
                className="mb-3 w-30 flex-align-right"
                style={{ maxHeight: '40px' }}
              >
                <span>{getRightButtonIcon()}</span>
                <span className={'d-none d-md-inline-block ms-2'}>
                  {' '}
                  {getRightButtonTitle()}
                </span>
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LeftButtonTitleRightButton
