import { useState } from 'react'
import { updateTheme } from '../Themes'
import { useUserCredentials } from '../context/UserCredentialsContext'
import { fetchAuthSession, getCurrentUser } from '@aws-amplify/auth'
import {
  useOrganizationService,
  useUserService,
} from '../service/ServicesHooks'

export const useCheckAndUpdateUserCredentials = () => {
  const [loading, setLoading] = useState(true)

  const userService = useUserService()
  const organizationService = useOrganizationService()

  const { userCredentialsInContext, updateUserCredentialsContext } =
    useUserCredentials()

  const checkAndUpdateUserCredentials = async () => {
    try {
      const { signInDetails } = await getCurrentUser()
      const authSession = await fetchAuthSession()

      const idToken = authSession.tokens?.idToken?.toString()

      if (!signInDetails && !idToken) {
        updateUserCredentialsContext(null)
        return
      }

      if (idToken && userCredentialsInContext?.userInfo?.userId) {
        if (userCredentialsInContext.userInfo?.organizationName) {
          updateTheme(userCredentialsInContext.userInfo.organizationName)
        }
        return
      }

      if (idToken && !userCredentialsInContext?.userInfo?.userId) {
        //Update user credentials

        const localStorageUser = localStorage.getItem('goachyUser')

        if (localStorageUser) {
          const parsedUser = JSON.parse(localStorageUser)

          updateUserCredentialsContext({
            isSignedIn: true,
            userInfo: parsedUser,
          })
          updateTheme(parsedUser.organizationName)
          return
        }

        if (!localStorageUser && authSession.tokens?.idToken?.payload.email) {
          const response = await userService.getUserByEmail(
            authSession.tokens?.idToken?.payload.email.toString(),
          )
          const currentUser = response.users[0]

          const getOrganizationResponse =
            await organizationService.getOrganization(
              currentUser.organizationId!,
            )

          const organization = getOrganizationResponse.organizations[0]

          updateTheme(organization.name)

          const userInfo = {
            userId: currentUser.id!,
            email: currentUser.email,
            organizationId: currentUser.organizationId,
            organizationName: organization.name,
            roles: currentUser.roles,
          }

          updateUserCredentialsContext({
            isSignedIn: true,
            userInfo: userInfo,
          })

          localStorage.setItem('goachyUser', JSON.stringify(userInfo))
        }
      }
    } catch (error) {
      console.error(error)
      updateTheme('goachy', 'light')
      updateUserCredentialsContext(null)
    } finally {
      setLoading(false)
    }
  }

  return { checkUser: checkAndUpdateUserCredentials, loading }
}
