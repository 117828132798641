import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export interface InformationModalProps {
  showInfoModal: boolean
  title: string
  children?: React.ReactNode
  handleCloseInfoModal: () => void
}

const InformationModal = ({
  showInfoModal,
  title,
  children,
  handleCloseInfoModal,
}: InformationModalProps) => {
  const { t } = useTranslation('InformationModal')
  return (
    <Modal
      show={showInfoModal}
      onHide={handleCloseInfoModal}
      className={'custom-modal-bg'}
    >
      <Modal.Header
        closeButton
        className="custom-modal-header-bg custom-modal-color"
      >
        <Modal.Title>
          <FontAwesomeIcon
            icon={faInfoCircle}
            size={'1x'}
            style={{ marginRight: '8px' }}
          ></FontAwesomeIcon>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body-bg custom-modal-color">
        {children ? children : 'Det finns ingen information att visa.'}
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer-bg custom-modal-color">
        <Button variant="secondary" onClick={handleCloseInfoModal}>
          {t('closeButton')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default InformationModal
