import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Goal, GoalType } from '../../components/model/model'
import './MyGoalInfoBox.css'
import {
  faListCheck,
  faScaleUnbalancedFlip,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { getDifferenceInDays } from '../../utils/timeAndDateUtils'
import { convertToDate } from '../../utils/converters'

/**
 * This component is used to display a goal
 */
export interface MyGoalInfoBoxProps {
  goal: Goal
  isOwner?: boolean
  bgColor?: string
}

const MyGoalInfoBox = ({ goal, bgColor }: MyGoalInfoBoxProps) => {
  const { t } = useTranslation('MyGoalInfoBox')

  const getDaysStatusText = () => {
    if (!goal.endDate) {
      return ''
    }

    const diffInDays = getDifferenceInDays(
      convertToDate(goal.endDate),
      new Date(),
    )

    if (diffInDays === 0) {
      return t('taskDoneToday')
    } else if (diffInDays < 0) {
      return t('daysLate', { diffInDays: Math.abs(diffInDays) })
    } else {
      return t('daysLeft', { diffInDays: diffInDays })
    }
  }

  if (!goal) {
    throw Error('Missing goal, cannot edit goal')
  }

  return (
    <>
      <div className={`goal-info-item`}>
        <span
          className="goal-info-item_link"
          style={{ backgroundColor: bgColor }}
        >
          <div className={'items-left-and-right'}>
            <div className="goal-info-item_title">{goal.name}</div>

            <span>
              {goal.goalType === GoalType.MeasurableGoal && (
                <FontAwesomeIcon icon={faScaleUnbalancedFlip} size={'lg'} />
              )}
              {goal.goalType === GoalType.FinishAllTasks && (
                <FontAwesomeIcon icon={faListCheck} size={'lg'} />
              )}
            </span>
          </div>
          {goal.category && (
            <div className={'mt-2'}>
              {t('category')}: {goal.category}
            </div>
          )}
          {goal.endDate && (
            <div className={'mt-2'}>
              {t('endDate')}: {goal.endDate}
            </div>
          )}
          <div>{getDaysStatusText()}</div>
          <hr />

          <div className="goachy-info-item_description">
            <div
              className={'task-text'}
              dangerouslySetInnerHTML={{ __html: goal.description }}
            />
          </div>

          {goal.goalType === GoalType.MeasurableGoal && (
            <>
              <hr />

              <div className={'mt-3'}>
                {goal.targetResult !== 0 && (
                  <strong>
                    {t('desiredResult', { desiredResult: goal.targetResult })}
                  </strong>
                )}
              </div>
              <div>
                {goal.targetTaskCountPerWeek !== 0 && (
                  <strong>
                    {t('timesPerWeek', {
                      timesPerWeek: goal.targetTaskCountPerWeek,
                    })}
                  </strong>
                )}
              </div>
            </>
          )}
        </span>
      </div>
    </>
  )
}

export default MyGoalInfoBox
