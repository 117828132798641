import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { GetTasksResponse } from '../../service/TaskService'
import { useTaskService, useUserTaskService } from '../../service/ServicesHooks'
import ProgressBarHeader from '../../components/ProgreesBarHeader'
import SharedOrPublicTaskForm from './SharedOrPublicTaskForm'
import { useTranslation } from 'react-i18next'
import { GetUserTaskByUserIdAndTaskIdResponse } from '../../service/UserTaskService'
import { useUserTask } from '../../context/UserTaskContext'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { isLoggedIn } from '../../utils/UserDetailsHelper'

interface SharedOrPublicTaskPageProps {
  sharedOrPublicTask: 'SHARED' | 'PUBLIC'
}

const SharedOrPublicTaskPage = ({sharedOrPublicTask}: SharedOrPublicTaskPageProps) => {
  const { taskId } = useParams()

  const { t } = useTranslation('MyUserTaskForm')

  const taskService = useTaskService()
  const userTaskService = useUserTaskService()
  const { updateUserTaskContext } = useUserTask()
  const { userCredentialsInContext } = useUserCredentials()

  const loggedIn = isLoggedIn(userCredentialsInContext)

  // First query: Fetch task details
  const { data: taskResponse, isLoading: isTaskLoading } =
    useQuery<GetTasksResponse>({
      queryKey: ['task', { taskId: taskId }],
      queryFn: () => taskService.getTasks({ taskId: taskId }),
    })

  // Second query: Fetch user task details, enabled only when taskResponse is available
  const { data: userTasksResponse, isLoading: isUserTaskLoading } =
    useQuery<GetUserTaskByUserIdAndTaskIdResponse>({
      queryKey: ['userTasks', { taskId: taskId }],
      queryFn: () =>
        userTaskService.getUserTaskByUserIdAndTaskId({
          userId: taskResponse!.tasks?.[0].userId,
          taskId: taskId!,
        }),
      enabled: !!taskResponse && loggedIn, // Only run this query when taskResponse is available
    })

  const isLoading = isTaskLoading || (loggedIn && isUserTaskLoading)

  if (isLoading) {
    return <ProgressBarHeader title={t('taskLoading')} />
  }

  if (loggedIn && userTasksResponse) {
    updateUserTaskContext(userTasksResponse.userTask!)
  }

  const task = taskResponse?.tasks?.[0]

  return (
    <div>
      <SharedOrPublicTaskForm
        task={task!}
        isLoggedIn={loggedIn}
        userCredentials={userCredentialsInContext}
        sharedOrPublicTask={sharedOrPublicTask}
      />
    </div>
  )
}

export default SharedOrPublicTaskPage
