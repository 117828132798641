import NavigationBar from './NavigationBar'
import ProgressSpinner from './ProgressSpinner'

  
const FallbackPage = () => {
  return (
    <>
      <NavigationBar />
      <ProgressSpinner />
    </>
  )
}

export default FallbackPage
