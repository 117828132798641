import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'

const VerifyTaskInfo = () => {
  return (
    <div>
      <Container className={'mb-2'}>
        <Row className={'justify-content-center'}>
          <Col md={12}>
            <>
              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                Granska uppgiften
              </h6>
              <p>
                Granska uppgiften. Ser allt bra ut så trycker du på Klar eller
                gå tillbaka och ändra om något inte stämmer.
              </p>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default VerifyTaskInfo
