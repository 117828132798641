import { SyntheticEvent, useState } from 'react'
import {
  AuthConfirmSignUpResponse,
  AuthService,
} from '../../service/AuthService'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import ProgressSpinner from '../ProgressSpinner'
import { useTranslation } from 'react-i18next'
interface ConfirmSignUpProps {
  email: string
  authService: AuthService
}

export default function ConfirmSignupComponent(props: ConfirmSignUpProps) {
  const { t } = useTranslation('SignUpConfirmation')
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [inProgress, setInProgress] = useState(false)

  const navigate = useNavigate()

  const handleConfirmSignUp = async (event: SyntheticEvent) => {
    event.preventDefault()

    try {
      setInProgress(true)
      const response: AuthConfirmSignUpResponse =
        await props.authService.confirmSignUp(props.email, code)

      setInProgress(false)

      if (response.isSignUpComplete) {
        console.log('User confirmed sign up')
        navigate(`/login/user/${props.email}/${Date.now()}`)
      } else if (response.isWrongConfirmationCode) {
        setErrorMessage(t('confirmationCodeInvalid'))
      }
    } catch (error) {
      console.error('Error confirming sign up', error)

      if (error instanceof Error) {
        console.log('Wrong code', error.name)
      }

      if (error instanceof Error) {
        console.log('Wrong code', error.name)
      }
    }
  }

  const handleResendSignupCode = async (event: SyntheticEvent) => {
    event.preventDefault()
    try {
      await props.authService.resendSignUpCode(props.email)
      setInfoMessage(t('newCodeSentToEmail'))
    } catch (error) {
      console.error('Error resending code', error)
      setErrorMessage(t('somethingWentWrong'))
    }
  }

  if (inProgress) {
    return <ProgressSpinner />
  }

  return (
    <>
      <Container className="my-5">
        <Row className="justify-content-md-center">
          <Col md={12}>
            <h3 className="text-center mb-4">{t('title')}</h3>
            <p className={'text-center'}>
              {t('sentToEmail')}
              <strong>{props.email}</strong>
            </p>
            <Form onSubmit={handleConfirmSignUp} className="border p-4 rounded">
              {errorMessage && (
                <Alert variant="danger" style={{ fontSize: '1.1rem' }}>
                  {errorMessage}
                </Alert>
              )}
              {infoMessage && (
                <Alert variant="info" style={{ fontSize: '1.1rem' }}>
                  {infoMessage}
                </Alert>
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t('codeLabel')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t('codeFromEmail', { email: props.email })}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Group>

              <Button type="submit" variant="primary" className="mt-3 w-100">
                {t('confirmAccount')}
              </Button>

              <div className="loginSignUpSeparator ">
                <span className="textInSeparator">{t('orSeparator')}</span>
              </div>

              <Button
                onClick={handleResendSignupCode}
                variant="primary"
                className="mt-3 w-100"
              >
                {t('sendNewCode')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
