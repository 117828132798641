import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import DropdownSearchComponent, {
  DropDownItem,
} from '../../components/DropdownSearchComponent'
import InformationModal from '../../components/info/InformationModal'
import PublicTasksInfo from '../../components/info/PublicTasksInfo'
import SearchInputComponent from '../../components/inputs/SearchInputComponent'
import LeftButtonTitleRightButton from '../../components/LeftButtonTitleRightButton'
import ProgressBarHeader from '../../components/ProgreesBarHeader'
import StyledContainer from '../../components/StyledContainer'
import { useOrganizationTaskService } from '../../service/ServicesHooks'
import { removeDuplicates } from '../../utils/helperFunctions'

const PublicOrganizationTasksPage = () => {
  const { t } = useTranslation('PublicOrganizationTasksPage')

  const [searchInput, setSearchInput] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [language, setLanguage] = useState('')

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng')
    if (lang) {
      setLanguage(lang)
    }
  }, [])

  const navigate = useNavigate()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const environment = import.meta.env.VITE_ENVIRONMENT
  const organizationTaskService = useOrganizationTaskService()
  const orgId =
    environment === 'TEST'
      ? 'org_8070e4ed-753b-407d-af85-d151415433e4'
      : 'org_4129e2b9-74ad-42b7-9821-69d9a3b99c80'

  const getPublicOrganizationTasks = async () => {
    const response = await organizationTaskService.getPublicTasks({
      organizationId: orgId,
    })
    return response.orgTasks
  }

  const { data: orgPublicTasks, isLoading: isLoadingTasks } = useQuery(
    'orgPublicTasks',
    () => getPublicOrganizationTasks(),
  )

  if (isLoadingTasks && language === '') {
    return <ProgressBarHeader title={t('publicTasksLoadingText')} />
  }
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  const handleClear = () => {
    setSearchInput('')
  }

  const getCategories = (): DropDownItem[] => {
    if (!orgPublicTasks) {
      return []
    }

    const categories = removeDuplicates(
      orgPublicTasks.map((tasks) => tasks.taskCategory),
    )

    return categories.map((c) => {
      return { value: c, label: c }
    })
  }

  const handleSelectedCategory = (category: string | null) => {
    setSelectedCategory(category)
  }

  function matchTaskWithSelectedCategory(taskCategory: string): boolean {
    return selectedCategory === null || selectedCategory === taskCategory
  }

  const matchTaskNameWithSearchInput = (taskName: string) => {
    return taskName.toLowerCase().includes(searchInput.toLowerCase())
  }

  const getTitleFontSize = () => {
    return isMobile ? '1.3rem' : '2rem'
  }

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  return (
    <div>
      <div className={'mx-3 mt-2'}>
        <StyledContainer>
          <LeftButtonTitleRightButton
            title={t('publicTasksTitle')}
            titleFontSize={getTitleFontSize()}
            showLeftButton={false}
            onRightButtonClick={handleShowInfoModal}
          />
        </StyledContainer>
      </div>
      {/* <img
        className="mx-auto d-block mt-3"
        style={{ borderRadius: '30px' }}
        src="/marketing/goachy-gdd-supernova-small.png"
        alt="error"
      /> */}

      <div>
        <SearchInputComponent
          setSearchInputCb={handleInputChange}
          handleClearSearchInput={handleClear}
          searchInput={searchInput}
          placeholder={t('searchTasksText')}
        />
        <div
          className={'mx-2 mb-4'}
          style={{ position: 'relative', zIndex: 4 }}
        >
          <DropdownSearchComponent
            dropdownItems={getCategories()}
            handleSelectedItemCb={handleSelectedCategory}
          />
        </div>

        <div>
          {(!orgPublicTasks || orgPublicTasks.length == 0) && (
            <div className={'text-center mb-4'}>{t('noTasksFoundText')}</div>
          )}
        </div>

        {orgPublicTasks
          ?.filter((orgTask) => language === orgTask.language)
          ?.filter((orgTask) => matchTaskNameWithSearchInput(orgTask.taskName))
          .filter((orgTask) =>
            matchTaskWithSelectedCategory(orgTask.taskCategory),
          )
          ?.map((orgTask, idx) => (
            <div
              key={idx}
              className={'mx-3 mt-3 cursorPointer'}
              onClick={() => navigate(`/public/usertasks/${orgTask.taskId}`)}
            >
              <StyledContainer key={idx}>
                <>
                  <div>
                    <h4>{orgTask.taskName}</h4>
                    <p>Kategori: {orgTask.taskCategory}</p>
                  </div>
                </>
              </StyledContainer>
            </div>
          ))}
        <div className="my-3"></div>
      </div>
      <InformationModal
        title={t('informationAboutPublicTasks')}
        children={<PublicTasksInfo />}
        showInfoModal={showInfoModal}
        handleCloseInfoModal={handleCloseCloseInfoModal}
      />
    </div>
  )
}

export default PublicOrganizationTasksPage
