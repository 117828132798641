import { Goal, UserTask, UserTaskStatus } from '../components/model/model'
import { removeDuplicates } from './helperFunctions'

export const sortUserTasksByEndDateOldestFirst = (
  userTasks: UserTask[],
): UserTask[] => {
  return userTasks.sort((a, b) => {
    if (!a.endDate) {
      return 1
    }
    if (!b.endDate) {
      return -1
    }
    if (a.endDate && b.endDate) {
      return a.endDate.localeCompare(b.endDate)
    }
    return 0
  })
}

export const sortGoalsByEndDateOldestFirst = (
  goals: Goal[] | undefined,
): Goal[] => {
  if (!goals) {
    return []
  }
  return goals.sort((a, b) => {
    if (!a.endDate) {
      return 1
    }
    if (!b.endDate) {
      return -1
    }
    if (a.endDate && b.endDate) {
      return a.endDate.localeCompare(b.endDate)
    }
    return 0
  })
}

export const sortUserTasksByEndDateNewestFirst = (
  userTasks: UserTask[],
): UserTask[] => {
  return userTasks.sort((a, b) => {
    if (!a.endDate) {
      return 1
    }
    if (!b.endDate) {
      return -1
    }
    if (a.endDate && b.endDate) {
      return b.endDate.localeCompare(a.endDate)
    }
    return 0
  })
}

export const filterNewOrOngoingTasks = (userTasks: UserTask[]): UserTask[] => {
  return userTasks.filter((userTask) => {
    return (
      userTask.status !== UserTaskStatus.Done &&
      userTask.status !== UserTaskStatus.Deleted
    )
  })
}

export const filterDoneTasks = (userTasks: UserTask[]): UserTask[] => {
  return userTasks.filter((userTask) => userTask.status === UserTaskStatus.Done)
}

export const groupTasksByStatus = (userTasks: UserTask[] | null) => {
  if (userTasks === null) {
    return {} as Record<UserTaskStatus, UserTask[]>
  }
  return userTasks.reduce(
    (groupedTasks, task) => {
      const key = task.status
      if (!groupedTasks[key]) {
        groupedTasks[key] = []
      }
      groupedTasks[key].push(task)
      return groupedTasks
    },
    {} as Record<UserTaskStatus, UserTask[]>,
  )
}

export const filterAndSortUserTaskCategories = (
  userTasks: UserTask[] | null | undefined,
): string[] => {
  if (!userTasks) {
    return []
  }

  const categories = removeDuplicates(
    userTasks
      .filter((ut) => ut.taskCategory != 'none')
      .map((ut) => ut.taskCategory)
      .sort(),
  )
  return categories
}
