import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './DarkModeLightModeSwitch.css'

export interface DarkModeLightModeSwitchProps {
  currentTheme: string
  switchThemeCb: (mode: string) => void
}
const DarkModeLightModeSwitch = ({
  currentTheme: currentMode,
  switchThemeCb,
}: DarkModeLightModeSwitchProps) => {
  return (
    <div className={'mt-2 cursorPointer'}>
      {currentMode === 'dark' ? (
        <FontAwesomeIcon
          icon={faSun}
          fontVariant={'regular'}
          size={'lg'}
          onClick={() => switchThemeCb('light')}
          className={'custom-icon-color'}
        />
      ) : (
        <FontAwesomeIcon
          icon={faMoon}
          size={'lg'}
          onClick={() => switchThemeCb('dark')}
          className={'custom-icon-color'}
        />
      )}
    </div>
  )
}

export default DarkModeLightModeSwitch
