import { ReactNode, createContext, useContext, useState } from 'react'
import { UserTask } from '../components/model/model'

interface UserTaskContextType {
  userTaskInContext: UserTask | null
  updateUserTaskContext: (newTask: UserTask | null) => void
}

const UserTaskContext = createContext<UserTaskContextType | undefined>(
  undefined,
)

// Create a custom hook to use the context
export const useUserTask = (): UserTaskContextType => {
  const context = useContext(UserTaskContext)
  if (context === undefined) {
    throw new Error('useUserTask must be used within a TaskProvider')
  }
  return context
}

interface UserTaskProviderProps {
  children: ReactNode
}

export const UserTaskProvider = ({ children }: UserTaskProviderProps) => {
  const [task, setUserTask] = useState<UserTask | null>(null)

  const updateTaskContext = (newUserTask: UserTask | null) => {
    setUserTask(newUserTask)
  }

  return (
    <UserTaskContext.Provider
      value={{
        userTaskInContext: task,
        updateUserTaskContext: updateTaskContext,
      }}
    >
      {children}
    </UserTaskContext.Provider>
  )
}
