import { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Select, {
  ActionMeta,
  GroupBase,
  SingleValue,
  StylesConfig,
} from 'react-select'

export interface DropDownItem {
  value: string
  label: string
}

export interface DropdownSearchProps {
  dropdownItems: DropDownItem[]
  handleSelectedItemCb: (item: string | null) => void
  preselectedItem?: string | null
}

export const mapStringsToDropDownItems = (items: string[]): DropDownItem[] => {
  return items.map((c) => {
    return { value: c, label: c }
  })
}

const DropdownSearchComponent = ({
  dropdownItems,
  handleSelectedItemCb,
  preselectedItem,
}: DropdownSearchProps) => {
  const preSelectedDropDownItem = preselectedItem
    ? { value: preselectedItem, label: preselectedItem }
    : null
  const { t } = useTranslation('DropdownSearchComponent')
  const [selectedItem, setSelectedItem] = useState<DropDownItem | null>(preSelectedDropDownItem)
  const handleChange = (
    selectedOption: SingleValue<DropDownItem>,
    actionMeta: ActionMeta<DropDownItem>,
  ) => {
    console.log(selectedOption, actionMeta)
    setSelectedItem(selectedOption)
    handleSelectedItemCb(selectedOption ? selectedOption.value : null)
  }

  const customStyles: StylesConfig<
    DropDownItem,
    false,
    GroupBase<DropDownItem>
  > = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (base: any, state: { isFocused: boolean }) => ({
      ...base,
      borderColor: state.isFocused ? '#4D90FE' : '#ccc', // Blue when focused, grey otherwise
      '&:hover': {
        borderColor: state.isFocused ? '#4D90FE' : '#aaa', // Darker on hover
      },
      borderWidth: '3px',
      borderRadius: '15px',
      boxShadow: state.isFocused ? '0 0 0 1px rgba(77, 144, 254, 0.3)' : 'none',
      transition: 'border-color .2s ease, box-shadow .2s ease', // Smooth transition for focus and hover
    }),
    option: (base) => ({
      //Dropdown color, always black to be visible for dark and light mode
      ...base,
      color: 'black',
    }),
  }

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={12}>
          <Select<DropDownItem>
            value={selectedItem}
            onChange={handleChange}
            options={dropdownItems}
            placeholder={t('dropdownPlaceholder')}
            styles={customStyles}
            isClearable={true}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default DropdownSearchComponent
