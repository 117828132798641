import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

export interface SearchInputComponentProps {
  setSearchInputCb: (searchInput: ChangeEvent<HTMLInputElement>) => void
  handleClearSearchInput: () => void
  searchInput: string,
  placeholder?: string
}

const SearchInputComponent = ({
  setSearchInputCb,
  handleClearSearchInput,
  searchInput,
  placeholder: placeHolder = 'Search ...',
}: SearchInputComponentProps) => {
  return (
    <InputGroup size="lg" className="mt-4">
      <Form.Control
        type="text"
        placeholder={placeHolder}
        value={searchInput}
        onChange={setSearchInputCb}
        className={'mx-3 mb-4'}
        style={{ border: '3px solid #007BFF', borderRadius: '15px' }}
      />
      {searchInput && (
        <Button
          className={'me-3 mb-4'}
          variant="outline-secondary"
          onClick={() => handleClearSearchInput()}
          style={{ border: '3px solid #007BFF', borderRadius: '15px' }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
      )}
    </InputGroup>
  )
}

export default SearchInputComponent
