import { SyntheticEvent } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUserCredentials } from '../../context/UserCredentialsContext'
import { useAuthService } from '../../service/ServicesHooks'
import StyledContainer from '../StyledContainer'
import ChangeLanguageComponent from '../ChangeLanguageComponent'

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (message: string) => void
    }
  }
}

interface SignOutComponentProps {
  setIsSignedInCallback: (isSignedIn: boolean) => void
}

export default function SignOutComponent({
  setIsSignedInCallback,
}: SignOutComponentProps) {
  const { t } = useTranslation('SignOutComponent')
  const navigate = useNavigate()
  const authService = useAuthService()

  const { userCredentialsInContext } = useUserCredentials()

  const userId = userCredentialsInContext?.userInfo?.userId

  const handleSignOut = async (event: SyntheticEvent) => {
    event.preventDefault()
    try {
      await authService.signOut()
      setIsSignedInCallback(false)
      navigate('/')
    } catch (error) {
      console.error('Error signing out user', error)
    }
  }
  return (
    <>
      <div className={'mt-3'} />

      <div className={'mx-3 mb-3'}>
        <StyledContainer>
          <h3 className="text-center mb-4">{t('profileHeading')}</h3>
          <Container className="mt-2 mb-4">
            <Row>
              <Col md={12}>
                <ChangeLanguageComponent />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className={'text-center'}>{t('loggedInAs')}</div>
                <div className={'text-center'}>
                  {userCredentialsInContext?.userInfo?.email}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={handleSignOut}
                    variant="primary"
                    className="mt-3 w-50"
                  >
                    {t('signOutButton')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mt-5 mb-4">
            <Row>
              <Col md={12}>
                <div className="d-flex justify-content-center">
                  <p>{t('deleteAccountInfo')}</p>
                </div>
                <div className="d-flex justify-content-center">
                  <a href={`/myaccount/${userId}`}>{t('moreAccountInfo')}</a>
                </div>
              </Col>
            </Row>
          </Container>
        </StyledContainer>
      </div>
    </>
  )
}
