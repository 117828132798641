import { Button, Modal } from 'react-bootstrap'
import './CustomModal.css'

export interface CustomModalProps {
  show: boolean
  handleClose: () => void
  handleConfirm?: () => void
  title: string
  message: string
  type: 'ERROR' | 'VERIFY' | 'INFORMATION'
}

export const CustomModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  title,
  type,
}: CustomModalProps) => {
  const getStyleClasses = (type: string): string => {
    const classNames =
      type === 'ERROR'
        ? 'error-modal-background'
        : type === 'VERIFY'
          ? 'verify-modal-background'
          : 'information-modal-background'

    return classNames
  }

  return (
    // <Modal show={show} onHide={handleClose} className={getStyleClasses(type)}>
    <Modal show={show} onHide={handleClose} className={getStyleClasses(type)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Stäng
        </Button>
        {handleConfirm && (
          <Button variant="primary" onClick={handleConfirm}>
            Bekräfta
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
