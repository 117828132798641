import React, { useState, useRef } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'

import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebouceEffect'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import StyledContainer from '../../StyledContainer'
import { useMediaQuery } from 'react-responsive'


function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function App() {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)
  const [imageWidth, setImageWidth] = useState<number | null>(null)
  const [imageHeight, setImageHeight] = useState<number | null>(null)
  const [croppedImageWidth, setCroppedImageWidth] = useState<number | null>(
    null,
  )
  const [croppedImageHeight, setCroppedImageHeight] = useState<number | null>(
    null,
  )

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const subHeaderClass = isMobile ? 'main-small-subheader' : 'main-subheader'

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
      setImageWidth(width)
      setImageHeight(height)
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    if (hiddenAnchorRef.current) {
      hiddenAnchorRef.current.href = blobUrlRef.current
      hiddenAnchorRef.current.click()
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        setCroppedImageWidth(completedCrop?.width)
        setCroppedImageHeight(completedCrop?.height)
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else {
      setAspect(16 / 9)

      if (imgRef.current) {
        const { width, height } = imgRef.current
        const newCrop = centerAspectCrop(width, height, 16 / 9)
        setCrop(newCrop)
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height))
      }
    }
  }

  const onScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(e.target.value)
    setScale(newScale)
    if (imgRef.current) {
      setImageWidth(imgRef.current.naturalWidth * newScale)
      setImageHeight(imgRef.current.naturalHeight * newScale)
    }
  }

  const onCropChange = (newCrop: Crop) => {
    const roundedCrop = {
      ...newCrop,
      width: newCrop.width ? roundToEven(newCrop.width) : newCrop.width,
      height: newCrop.height ? roundToEven(newCrop.height) : newCrop.height,
    }

    setCrop(roundedCrop)
  }

  const roundToEven = (num: number) => {
    const even = Math.round(num / 2) * 2
    console.log('num', even)
    return even
  }

  return (
    <div className={'my-3'}>
      <StyledContainer>
        <div className={`${subHeaderClass} text-center my-2`}>
          <span>Image Tool</span>
        </div>
        <p className={'text-center'}>
          A simple tool to resize and crop images.
        </p>
      </StyledContainer>
      <Container>
        <Row className="my-4">
          <Col>
            <Form.Group controlId="formFile">
              <Form.Label>Select an image file</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={onSelectFile}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <Form.Group controlId="formScale">
              <Form.Label>Scale</Form.Label>
              <Form.Range
                value={scale}
                onChange={onScaleChange}
                step="0.1"
                min="0.1"
                max="3"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Text>Rotate</InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="Enter rotation (degrees)"
                value={rotate}
                onChange={(e) =>
                  setRotate(
                    Math.min(180, Math.max(-180, Number(e.target.value))),
                  )
                }
                min={0} // Minimum size
                max={360} // Maximum size
                disabled={!imgSrc}
              />
            </InputGroup>
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <div>
              <Button onClick={handleToggleAspectClick}>
                Toggle aspect {aspect ? 'off' : 'on'}
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            {imageWidth && imageHeight && (
              <div>
                <p>
                  Image Dimensions: {imageWidth.toFixed(2)} x{' '}
                  {imageHeight.toFixed(2)}
                </p>
              </div>
            )}
            {croppedImageWidth && croppedImageHeight && (
              <div>
                <p>
                  Cropped Image Dimensions: {completedCrop?.width.toFixed(2)} x{' '}
                  {completedCrop?.height.toFixed(2)}
                </p>
              </div>
            )}
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => onCropChange(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                minWidth={50}
                minHeight={50}
                // circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: '1px solid black',
                      objectFit: 'contain',
                      width: completedCrop.width,
                      height: completedCrop.height,
                    }}
                  />
                </div>
                <div>
                  <Button onClick={onDownloadCropClick}>Download Crop</Button>
                  <div style={{ fontSize: 12, color: '#666' }}>
                    If you get a security error when downloading try opening the
                    Preview in a new tab (icon near top right).
                  </div>
                  <a
                    href="#hidden"
                    ref={hiddenAnchorRef}
                    download
                    style={{
                      position: 'absolute',
                      top: '-200vh',
                      visibility: 'hidden',
                    }}
                  >
                    Hidden download
                  </a>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
