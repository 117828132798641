export interface LogoComponentProps {
  organizationName: string
}

const getLogoFileName = (organization: string) => {
  if (!organization) {
    return ''
  }

  switch (organization.toLowerCase()) {
    case 'goachy':
      return ''
    case 'christinaskolan':
      return '/christinaskolan-logo-black.png'

    case 'ljungblads':
      return '/ljungblads-logo.png'
    default:
      return ''
  }
}

const getGoachyLogoForOrg = (organization: string) => {
  switch (organization.toLowerCase()) {
    case 'goachy':
      return '/goachy-logo-trans.png'
    case 'christinaskolan':
      return '/goachy-logo-trans.png'
    case 'studybuddy':
      return '/temp/studybuddy-logo.webp'
    case 'admentum':
      return '/temp/admentum.webp'
    default:
      return '/goachy-logo-trans.png'
  }
}

const LogoComponent = ({ organizationName }: LogoComponentProps) => {
  const logoPath = `${getLogoFileName(organizationName)}`

  return (
    <>
      {logoPath !== '' && (
        <img src={logoPath} className={'logo-img'} alt="Goachy" />
      )}
      <img
        style={{ width: '140px' }}
        src={getGoachyLogoForOrg(organizationName)}
        className={'logo-goachy-img'}
        alt="Goachy"
      />
    </>
  )
}

export default LogoComponent
