import { ReactNode, createContext, useContext, useState } from 'react'
import { Goal } from '../components/model/model'

interface GoalContextType {
  goalInContext: Goal | null
  updateGoalContext: (newGoal: Goal | null) => void
}

const GoalContext = createContext<GoalContextType | undefined>(undefined)

// Create a custom hook to use the context
export const useGoal = (): GoalContextType => {
  const context = useContext(GoalContext)
  if (context === undefined) {
    throw new Error('useGoal must be used within a TaskProvider')
  }
  return context
}

// Create a Provider component with props type
interface GoalProviderProps {
  children: ReactNode
}

export const GoalProvider = ({ children }: GoalProviderProps) => {
  const [goal, setGoal] = useState<Goal | null>(null)

  const updateGoalContext = (newGoal: Goal | null) => {
    setGoal(newGoal)
  }

  return (
    <GoalContext.Provider
      value={{ goalInContext: goal, updateGoalContext: updateGoalContext }}
    >
      {children}
    </GoalContext.Provider>
  )
}
