import { useUserCredentials } from '../context/UserCredentialsContext'
import HomeLoggedIn from './HomeLoggedIn'
import HomeLoggedOut from './HomeLoggedOut'

const HomePage = () => {
  const { userCredentialsInContext } = useUserCredentials()
  return (
    <>
      {!userCredentialsInContext?.isSignedIn && (
        <>
          <HomeLoggedOut />
        </>
      )}
      {userCredentialsInContext?.isSignedIn && (
        <>
          <HomeLoggedIn />
        </>
      )}
    </>
  )
}
export default HomePage
