import Spinner from 'react-bootstrap/Spinner'
import { Container } from 'react-bootstrap'
import { useMediaQuery } from 'react-responsive'

function ProgressSpinner() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: '100vh' }}
    >
      <Spinner
        animation="grow"
        variant="dark"
        className={isTabletOrMobile ? 'small-spinner' : 'large-spinner'}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  )
}

export default ProgressSpinner
