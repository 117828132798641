const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <div className={'mt-4'} />
      <h2>Welcome to Goachy</h2>
      These terms and conditions outline the rules and regulations for the use
      of Goachy's Website and App, located at goachy.com. By accessing this
      website/app we assume you accept these terms and conditions. Do not
      continue to use goachy.com if you do not agree to take all of the terms
      and conditions stated on this page.
      <h3 className={'mt-4'}>Creating an Account</h3>
      <ul>
        To use certain features of the website/app, you must register for an
        account. When you create an account, you must provide us with accurate
        and complete information.
      </ul>
      <ul>
        Each login may only be used by one individual. You are responsible for
        maintaining the confidentiality of your account and password.
      </ul>
      <h3>Content</h3>
      <ul>
        You can create tasks and goals as part of your account. All tasks and
        goals are private by default and can only be viewed by you unless you
        choose to share them with other users.
      </ul>
      <ul>
        By generating content and making it public, you grant Goachy a shared
        ownership right, which includes the right for Goachy to modify, adapt,
        and repurpose this content for broader organizational use or promotional
        purposes.
      </ul>
      <ul>
        By generating content, you grant Goachy a limited, non-exclusive,
        revocable license solely to enable us to display, use, and operate the
        service. We do not claim any ownership rights to your content and will
        not use it for commercial purposes without your permission.
      </ul>
      <ul>
        Goachy is committed to maintaining your privacy. We do not access or
        review the content you store in the application unless required to do so
        by law or in accordance with our Privacy Policy to resolve technical
        issues at your request.
      </ul>
      <ul>
        If you share content with other users, it may not be revoked, and the
        content may remain accessible to those it has been shared with even if
        your account is deleted.
      </ul>
      <h3>Subscription Services</h3>
      <ul>
        Some parts of the Service are billed on a subscription basis
        ("Subscription(s)"). You will be billed in advance on a recurring and
        periodic basis ("Billing Cycle"). Billing cycles are set either on a
        monthly or annual basis, depending on the type of subscription plan you
        select when purchasing a Subscription.
      </ul>
      <h3>Cancellation and Termination</h3>
      <ul>
        You are free to terminate your account at any time. Upon termination,
        your account will be deactivated, and all your content will be deleted,
        subject to the sharing terms outlined above.
      </ul>
      <ul>
        Goachy reserves the right to terminate or suspend your account
        immediately, without prior notice or liability, under our sole
        discretion, for any reason whatsoever and without limitation, including
        but not limited to a breach of the Terms.
      </ul>
      <h3>Modifications</h3>
      <ul>
        Goachy reserves the right, at our sole discretion, to modify or replace
        these Terms at any time. If a revision is material, we will make
        reasonable efforts to provide at least 30 days' notice prior to any new
        terms taking effect. What constitutes a material change will be
        determined at our sole discretion.
      </ul>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us at
        info@goachy.com
      </p>
    </div>
  )
}

export default TermsAndConditions
