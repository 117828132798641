import { SyntheticEvent, useState } from 'react'
import { AuthService } from '../../service/AuthService'
import { useNavigate } from 'react-router-dom'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
interface ResetConfirmationProps {
  email: string
  authService: AuthService
  setShowResetConfirmationCb: (showResetConfirmation: boolean) => void
  setShowPasswordResetCb: (showPasswordReset: boolean) => void
}

const ResetConfirmationComponent = (props: ResetConfirmationProps) => {
  const { t } = useTranslation('ResetPasswordComponent')
  const [verificationCode, setVerificationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const navigate = useNavigate()

  const handleResetPassword = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()

    try {
      if (newPassword !== confirmNewPassword) {
        setErrorMessage(t('passwordMismatch'))
        return
      }

      const response = await props.authService.confirmResetPassword(
        props.email,
        verificationCode,
        newPassword,
      )

      if (!response.isPasswordResetConfirmed && response.isInvalidEmail) {
        console.log('Invalid password')
        setErrorMessage(t('invalidPasswordText'))
        return
      }

      props.setShowResetConfirmationCb(false)
      props.setShowPasswordResetCb(false)
      navigate(`/login/user/${props.email}/${Date.now()}`)
    } catch (error) {
      console.error(error)

      setErrorMessage(t('somethingWentWrong'))
    }
  }

  return (
    <>
      <Container className="mt-2 mb-4">
        <Row className="justify-content-md-center">
          <Col md={12}>
            <h3 className="text-center mb-4">{t('title')}</h3>
            <Form onSubmit={handleResetPassword} className="border p-4 rounded">
              {errorMessage && (
                <Alert variant="danger" style={{ fontSize: '1.1rem' }}>
                  {errorMessage}
                </Alert>
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{'Email'}</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  placeholder={'Email'}
                  value={props.email}
                  autoComplete="username"
                  readOnly
                />
              </Form.Group>

              <Form.Group className={'my-4'} controlId="formConfirmationEmail">
                <Form.Label>{t('codeLabel')}</Form.Label>
                <Form.Control
                  type="text"
                  name="verification_code"
                  placeholder={t('codeFromEmail')}
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  autoComplete="one-time-code"
                />
              </Form.Group>

              <Form.Group className={'my-4'} controlId="formResetPassword">
                <Form.Label>{t('newPassword')}</Form.Label>
                <Form.Control
                  type="password"
                  name="new_password"
                  placeholder={t('newPassword')}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Form.Group>

              <Form.Group
                className={'my-4'}
                controlId="formConfirmResetPassword"
              >
                <Form.Label>{t('confirmPassword')}</Form.Label>
                <Form.Control
                  type="password"
                  name="confirm_new_password"
                  placeholder={t('confirmPassword')}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  autoComplete="new-password"
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="mt-3 w-100">
                {t('resetPassword')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ResetConfirmationComponent
