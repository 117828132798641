import {
  faCircle,
  faListCheck,
  faScaleUnbalancedFlip,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const AddGoalInfo = () => {
  const { t } = useTranslation('AddGoalInfo')
  return (
    <div>
      <Container className={'mb-2'}>
        <Row className={'justify-content-center'}>
          <Col md={12}>
            <>
              <h5>{t('goalTypes.header')}</h5>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faListCheck}
                  size="lg"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.completeTasks.title')}
              </h6>
              <p>{t('goalTypes.completeTasks.description')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faScaleUnbalancedFlip}
                  size="lg"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.measurableGoal.title')}
              </h6>
              <p>{t('goalTypes.measurableGoal.description')}</p>

              <hr />
              <p> {t('goalTypes.moreInfo')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.name')}
              </h6>
              <p> {t('goalTypes.nameInfo')}</p>
              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.describeGoal')}
              </h6>
              <p> {t('goalTypes.describeGoalInfo')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.timesPerWeek.title')}
              </h6>
              <p>{t('goalTypes.timesPerWeek.description')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.desiredResult.title')}
              </h6>
              <p>{t('goalTypes.desiredResult.description')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.complete')}
              </h6>
              <p>{t('goalTypes.completeInfo')}</p>

              <hr />

              <h5>{t('goalTypes.measurableGoalExample.header')}</h5>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.measurableGoalExample.name')}
              </h6>
              <p>{t('goalTypes.measurableGoalExample.nameInfo')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.measurableGoalExample.describeGoal')}
              </h6>
              <p>{t('goalTypes.measurableGoalExample.describeGoalInfo')}</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.measurableGoalExample.timesPerWeekName')}
              </h6>
              <p>1</p>

              <h6 className={'mt-4'}>
                <FontAwesomeIcon
                  icon={faCircle}
                  size="1x"
                  className={'me-2 fa-xs'}
                />
                {t('goalTypes.measurableGoalExample.desiredResultName')}
              </h6>
              <p>100</p>
            </>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AddGoalInfo
