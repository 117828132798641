import { SyntheticEvent, useState } from 'react'
import { AuthService } from '../../service/AuthService'
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface ResetPasswordProps {
  authService: AuthService
  setShowResetPasswordCb: (showResetPassword: boolean) => void
  setEmailReceipientCb: (email: string) => void
}

const ResetPasswordComponent = (props: ResetPasswordProps) => {
  const { t } = useTranslation('PasswordReset')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')

  const handleResetPassword = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()

    try {
      const emailLowerCase = email.toLocaleLowerCase()

      await props.authService.resetUserPassword(emailLowerCase)
      props.setShowResetPasswordCb(true)
      props.setEmailReceipientCb(emailLowerCase)
      setInfoMessage(t('codeSentToEmail'))
    } catch (error) {
      console.error('Error resetting password', error)
      setErrorMessage(t('somethingWentWrong'))
    }
  }

  return (
    <>
      <Container className="mt-2 mb-4">
        <Row className="justify-content-md-center">
          <Col md={12}>
            <h3 className="text-center mb-4">{t('resetPassword')}</h3>
            <Form onSubmit={handleResetPassword} className="border p-4 rounded">
              {errorMessage && (
                <Alert variant="danger" style={{ fontSize: '1.1rem' }}>
                  {errorMessage}
                </Alert>
              )}
              {infoMessage && (
                <Alert variant="info" style={{ fontSize: '1.1rem' }}>
                  {infoMessage}
                </Alert>
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label>{t('editEmail')}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Button type="submit" variant="primary" className="mt-3 w-100">
                {t('sendCode')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ResetPasswordComponent
