import { Col, Container, Row } from 'react-bootstrap'

import i18n from '../i18n'

const ChangeLanguageComponent = () => {
  return (
    <Container className={'mb-3'}>
      <Row className="justify-content-center cursorPointer">
        <Col xs={'auto'}>
          <img
            onClick={() => i18n.changeLanguage('en')}
            width="40px"
            src="/gbFlag.png"
            className="me-2"
          />
        </Col>
        <Col xs={'auto'}>
          <img
            onClick={() => i18n.changeLanguage('sv')}
            width="39px"
            src="/swedishFlag.png"
            className="me-2"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ChangeLanguageComponent
