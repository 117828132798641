import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv' // Import the Swedish locale
import en from 'date-fns/locale/en-GB'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

export interface GoachyDatePickerProps {
  selectedDate: Date | null
  onChange: (date: Date) => void
  inputPlaceholder?: string
}

const CalenderComponent = ({
  selectedDate,
  onChange,
  inputPlaceholder,
}: GoachyDatePickerProps) => {
  const { t } = useTranslation('CalendarComponent')

  const currentLocale = t('currentLocale')

  useEffect(() => {
    switch (currentLocale) {
      case 'en-GB':
        registerLocale('en-GB', en)
        break
      case 'sv-SE':
        registerLocale('sv-SE', sv)
        break
      default:
        console.error('Missing locale, cannot register locale for datepicker')
    }
  }, [currentLocale])

  const handleDateChange: ReactDatePickerProps['onChange'] = (
    date: Date | null,
  ) => {
    console.log('date', date)
    onChange(date!)
  }

  const getPlaceholderText = () => {
    return inputPlaceholder ? inputPlaceholder : t('datePlaceholder')
  }

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      dateFormat="yyyy-MM-dd"
      locale={t('currentLocale')}
      className="custom-date-picker"
      isClearable
      placeholderText={getPlaceholderText()}
    />
  )
}
export default CalenderComponent
