import { UserInfo } from '../components/UserSelectComponent'
import {
  Task,
  TaskDataType,
  TypeOfRelation,
  UserTask,
  UserTaskStatus,
} from '../components/model/model'
import { getEnvApiUrl } from '../envConfig'
import { AuthService } from './AuthService'

const goachyApiUrl = getEnvApiUrl()

export type UpdateUserTaskRelations = {
  usersToAssign: UserInfo[]
  assignedBy: string
  task: Task
  typeOfRelation: TypeOfRelation
}

export type UpdateUserTaskRequest = {
  userId: string
  taskId: string
  organizationId: string
  name?: string
  category?: string
  endDate?: string
  status?: UserTaskStatus
  typeOfRelation?: TypeOfRelation
  dataType?: TaskDataType
  assignedBy?: string
  assignedTo?: string
  sharedBy?: string
  taskOwner?: string
}

export type UpdateUserTaskResponse = {
  status: 'SUCCESS' | 'FAILURE'
  message?: string
}

export type GetUserTaskByUserIdAndTaskIdRequest = {
  userId: string
  taskId: string
}

export type GetUserTaskByUserIdAndTaskIdResponse = {
  status: 'SUCCESS' | 'FAILURE' | 'NOT_FOUND'
  message?: string
  userTask?: UserTask
}

export type GetUserTaskByTaskIdRequest = {
  taskId: string
}

export type GetUserTaskByTaskIdResponse = {
  status: 'SUCCESS' | 'FAILURE' | 'NOT_FOUND'
  message?: string
  userTasks?: UserTask[]
}

export type DeleteUserTaskRequest = {
  userId: string
  taskId: string
}

export class UserTaskService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  public async updateUserTaskRelations({
    usersToAssign: users,
    assignedBy,
    task,
    typeOfRelation,
  }: UpdateUserTaskRelations) {
    for (const user of users) {
      const status =
        typeOfRelation == TypeOfRelation.Assigned
          ? UserTaskStatus.New
          : undefined

      const request: UpdateUserTaskRequest = {
        userId: user.userId,
        taskId: task.id,
        organizationId: task.organizationId,
        typeOfRelation: typeOfRelation,
        name: task.name,
        category: task.category,
        endDate: task.endDate,
        status: status,
        assignedBy: assignedBy,
        assignedTo: user.email,
      }
      await this.updateOrCreateUserTask(request)
    }
  }

  public async updateOrCreateUserTask(
    request: UpdateUserTaskRequest,
  ): Promise<UpdateUserTaskResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      await fetch(`${goachyApiUrl}users/${request.userId}/usertasks`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(request),
      })
      return {
        status: 'SUCCESS',
        message: `UserTask updated, taskId: ${request.userId}, userId: ${request.userId}`,
      }
    } catch (error) {
      console.error('Failed to update task, error:', error)
      return {
        status: 'FAILURE',
        message: `Failed to update task, error: ${error}`,
      }
    }
  }

  public async getUserTaskByUserIdAndTaskId({
    userId,
    taskId,
  }: GetUserTaskByUserIdAndTaskIdRequest): Promise<GetUserTaskByUserIdAndTaskIdResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(
        `${goachyApiUrl}users/${userId}/usertasks/${taskId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )

      if (response.status === 404) {
        return {
          userTask: {
            id: userId,
            taskId: taskId,
            taskName: '',
            status: UserTaskStatus.New,
            typeOfRelation: TypeOfRelation.Assigned,
            assignedBy: '',
            assignedTo: '',
            taskCategory: '',
            language: '',
            timesDone: 0,
            eventCount: 0,
            dataType: TaskDataType.OneTimeTaskDataType,
          },
          status: 'NOT_FOUND',
        }
      }

      if (response.status === 200) {
        const userTask = (await response.json()) as UserTask
        return {
          status: 'SUCCESS',
          userTask: userTask,
        }
      } else {
        return {
          status: 'FAILURE',
          message: `Failed to get user task, status: ${response.status}`,
        }
      }
    } catch (error) {
      console.error('Failed to get user task, error:', error)
      throw new Error('Failed to get user task')
    }
  }

  public async getUserTasksByTaskId({
    taskId,
  }: GetUserTaskByTaskIdRequest): Promise<GetUserTaskByTaskIdResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const response = await fetch(`${goachyApiUrl}tasks/${taskId}/usertasks`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      })

      if (response.status === 404) {
        return {
          userTasks: [],
          status: 'NOT_FOUND',
        }
      }

      if (response.status === 200) {
        const userTask = (await response.json()) as UserTask[]
        return {
          status: 'SUCCESS',
          userTasks: userTask,
        }
      } else {
        return {
          status: 'FAILURE',
          message: `Failed to get user task, status: ${response.status}`,
        }
      }
    } catch (error) {
      console.error('Failed to get user task, error:', error)
      throw new Error('Failed to get user task')
    }
  }

  public async deleteUserTask(request: DeleteUserTaskRequest): Promise<void> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      await fetch(
        `${goachyApiUrl}users/${request.userId}/usertasks/${request.taskId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      )
    } catch (error) {
      console.error('Failed to delete user task, error:', error)
      throw new Error('Failed to delete user task')
    }
  }
}
