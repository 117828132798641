import LeftButtonTitleRightButton from '../../components/LeftButtonTitleRightButton'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import InformationModal from '../../components/info/InformationModal'
import ToolsInfo from '../../components/info/ToolsInfo'
import StyledContainer from '../../components/StyledContainer'
import GoachyInfoItem from '../GoachyInfoItem'

const ToolsPage = () => {
  const [showInfoModal, setShowInfoModal] = useState(false)

  const { t } = useTranslation('ToolsPage')
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const navigate = useNavigate()

  const getTitleFontSize = () => {
    return isMobile ? '1.3rem' : '2rem'
  }

  const handleShowInfoModal = () => setShowInfoModal(true)
  const handleCloseCloseInfoModal = () => setShowInfoModal(false)

  return (
    <>
      <div className={'mt-3'} />

      <div className={'mx-3'}>
        <StyledContainer>
          <LeftButtonTitleRightButton
            title={t('title')}
            titleFontSize={getTitleFontSize()}
            showLeftButton={false}
            onRightButtonClick={handleShowInfoModal}
          />

          <div
            className={'cursorPointer'}
            onClick={() => navigate('/tools/qrgenerator')}
          >
            <GoachyInfoItem
              title={t('qrGeneratorTitle')}
              text={t('qrGeneratorDescription')}
            />
          </div>

          <div
            className={'cursorPointer'}
            onClick={() => navigate('/tools/image-resizer')}
          >
            <GoachyInfoItem
              title={t('imageResizerTitle')}
              text={t('imageResizerDescription')}
            />
          </div>
        </StyledContainer>
        <div className={'mb-4'} />
      </div>

      <InformationModal
        title={t('informationAboutTools')}
        children={<ToolsInfo />}
        showInfoModal={showInfoModal}
        handleCloseInfoModal={handleCloseCloseInfoModal}
      />

      {/* <h2>Color Picker</h2>
<h2>Image Resizer</h2>
<h2>Timer</h2>
<h2>Counter</h2> */}
    </>
  )
}

export default ToolsPage
